import React, { useMemo } from "react";
import BaseCheckbox from "@reusables/BaseCheckboxLegacy";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import { useTranslation } from "react-i18next";
import { calculateOrderPrice, formatPriceWithCurrency } from "@helpers/utils";
import _ from "lodash";
import { ViewLayoutProps } from "./index";
import { Can, useAbility } from "@/casl.config";
import { useAppSelector } from "@redux/hooks";
import BaseTableV2 from "@reusables/BaseTableV2";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    useReactTable
} from "@tanstack/react-table";
import { SaleOrder } from "@/types/general";
import DetailsLinesRenderer from "./DetailsLinesRenderer";

export default function OrderDetails({ saleOrder }: ViewLayoutProps) {
    const { t } = useTranslation("", { keyPrefix: "sales.orders.general" });

    const baseCurrency = useAppSelector(state => state.auth.user?.company.currency);

    const ability = useAbility();

    // Calculating orders summary
    const linesSummary = useMemo(() => {
        let subtotal = 0;
        let taxes = 0;

        saleOrder.lines.forEach(line => {
            const linesToRead = line.type === "group" ? line.data.lines.map(groupLine => groupLine.data) : [line.data];

            linesToRead.forEach(line => {
                const linePrice = calculateOrderPrice(line.quantity, line.unit_price, line.discount ?? 0);
                subtotal += linePrice;
                taxes += linePrice * ((line.tax?.rate ?? 0) / 100);
            });
        });

        const totalWithTaxes = subtotal + taxes;

        return {
            subtotal,
            taxes,
            totalWithTaxes
        };
    }, [saleOrder.lines]);

    const blocks = useMemo<Record<"accent" | "references" | "address", InfoItem[]>>(() => ({
        accent: [
            {
                title: t("dropdowns.customer.label"),
                data: () => saleOrder?.customer.name ?? ""
            },
            {
                title: t("accentFields.orderDate.label"),
                data: () => saleOrder?.order_date.format("DD.MM.YYYY") ?? "-"
            },
            {
                title: t("accentFields.deliveryStatus.label"),
                data: () => <div>
                    <div>
                        {
                            t("accentFields.deliveryStatus.variants." + saleOrder.shipment_state)
                        }
                    </div>
                    {
                        !!saleOrder.delivery_date &&
                        <div>
                            ({saleOrder.delivery_date.format("DD.MM.YYYY")})
                        </div>
                    }
                </div>
            }
        ],
        references: [
            {
                title: t("dropdowns.ourReference.label"),
                data: () => saleOrder?.our_reference?.name ?? "-"
            },
            {
                title: t("dropdowns.theirReference.label"),
                data: () => saleOrder?.their_reference?.name ?? "-"
            },
            {
                title: t("dropdowns.paymentTerms.label"),
                data: () => saleOrder?.payment_terms?.name ?? "-"
            },
            {
                title: t("dropdowns.deliveryTerms.label"),
                data: () => saleOrder?.delivery_terms?.name ?? "-"
            },
            {
                title: t("dropdowns.documentLanguage.label"),
                data: () => saleOrder?.language?.name ?? "-"
            }
        ],
        address: [
            {
                title: t("fields.billingAddress.label"),
                data: () => (<>
                    <div
                        className="mb-[24px]">{_.join([saleOrder.billing_address?.street, saleOrder.billing_address?.city, saleOrder.billing_address?.country?.name].filter(x => !_.isEmpty(x)), ", ")}</div>
                    <BaseCheckbox
                        label={`${t("fields.billingAddress.checkboxes.useForDelivery")}`}
                        checked={saleOrder.is_billing_for_delivery}
                        disabled
                    />
                </>)
            }
        ]
    }), [saleOrder, t]);


    const columnHelper = createColumnHelper<SaleOrder.Components.Lines>();

    const table = useReactTable({
        data: [],
        columns: [
            columnHelper.display({
                header: t("orders.columns.0") + "",
                id: "product_code",
                maxSize: 150
            }),

            columnHelper.display({
                header: t("orders.columns.1") + "",
                id: "product_name"
            }),
            columnHelper.display({
                header: t("orders.columns.2") + "",
                id: "quantity",
                maxSize: 120
            }),
            columnHelper.display({
                header: t("orders.columns.3") + "",
                id: "unit_price",
                maxSize: 120,
            }),
            columnHelper.display({
                header: t("orders.columns.4") + "",
                id: "discount",
                maxSize: 120
            }),
            columnHelper.display({
                header: t("orders.columns.5") + "",
                id: "tax",
                maxSize: 120
            }),
            columnHelper.display({
                id: "total",
                header: t("orders.columns.6") + "",
                maxSize: 150
            }),
        ],
        initialState: {
            columnVisibility: {
                "unit_price": ability.can("price.view", "sale_order"),
                "total": ability.can("price.view", "sale_order"),
            },
        },
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel()
    });

    return (
        <div className="space-y-[40px]">
            <div className="p-[16px] rounded-[8px] bg-informationAccentBlock text-accent">
                <BaseInputsGrid cols={3}>
                    {
                        blocks.accent.map((item, index) => (
                            <div key={index}>
                                <div className="mb-[16px]">{item.title}</div>
                                <div className="space-x-[24px] font-semibold">{item.data()}</div>
                            </div>
                        ))
                    }
                </BaseInputsGrid>
            </div>
            <BaseInputsGrid title="References">
                {
                    blocks.references.map((item, index) => (
                        <div key={index}>
                            <div className="mb-[16px] font-semibold">{item.title}</div>
                            <div className="space-x-[24px] text-accent">{item.data()}</div>
                        </div>
                    ))
                }
            </BaseInputsGrid>
            <BaseInputsGrid title="Address">
                {
                    blocks.address.map((item, index) => (
                        <div key={index}>
                            <div className="mb-[16px] font-semibold">{item.title}</div>
                            <div className="text-accent">{item.data()}</div>
                        </div>
                    ))
                }
                {
                    !saleOrder?.is_billing_for_delivery &&
                    <div>
                        <div className="mb-[16px] font-semibold">{t("dropdowns.deliveryAddress.label")}</div>
                        <div className="space-x-[24px] text-accent">
                            {_.join([saleOrder.delivery_address?.street, saleOrder.delivery_address?.city, saleOrder.delivery_address?.country?.name].filter(x => !_.isEmpty(x)), ", ")}
                        </div>
                    </div>
                }
            </BaseInputsGrid>

            <div className="border border-solid border-gray-300 rounded-[8px] p-[16px]">
                <div className="mb-[26px] space-y-[16px]">
                    <div className="flex items-center space-x-[8px]">
                        <div className="text-xl font-thin text-gray-600 grow">
                            {t("orders.heading")}
                        </div>
                    </div>
                </div>
                {
                    saleOrder?.lines.length
                        ?
                        <>

                            <BaseTableV2>
                                {
                                    table.getHeaderGroups().map(headerGroup => (
                                        <BaseTableV2.Header key={headerGroup.id} className="mb-3">
                                            <BaseTableV2.Row>
                                                {headerGroup.headers.map(header => (
                                                    <BaseTableV2.Cell
                                                        key={header.id}
                                                        {...header.column.columnDef}
                                                    >
                                                        {header.isPlaceholder
                                                            ? null
                                                            : flexRender(header.column.columnDef.header, header.getContext())}
                                                    </BaseTableV2.Cell>
                                                ))}
                                            </BaseTableV2.Row>
                                        </BaseTableV2.Header>
                                    ))}
                                <DetailsLinesRenderer lines={saleOrder.lines} />
                            </BaseTableV2>


                            <Can I="price.view" a="sale_order">
                                <div className="flex justify-end mt-[48px] font-thin">
                                    <div className="w-1/2 grid grid-cols-2 gap-y-[16px]">
                                        <div className="text-right">
                                            {t("orders.summary.subtotal")}
                                        </div>
                                        <div className="text-right pr-[16px]">
                                            {
                                                formatPriceWithCurrency(linesSummary.subtotal, baseCurrency)
                                            }
                                        </div>
                                        <div className="text-right">
                                            {t("orders.summary.taxes")}
                                        </div>
                                        <div className="text-right pr-[16px]">
                                            {
                                                formatPriceWithCurrency(linesSummary.taxes, baseCurrency)
                                            }
                                        </div>
                                        <div className="text-right font-semibold text-accent">
                                            {t("orders.summary.total")}
                                        </div>
                                        <div className="text-right pr-[16px] font-semibold text-accent">
                                            {
                                                formatPriceWithCurrency(linesSummary.totalWithTaxes, baseCurrency)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Can>
                        </>
                    :
                    <>
                    <div className="text-xl text-center text-gray-300 font-thin">
                {t("orders.noProducts")}
            </div>
        </>
}
</div>
</div>
)
    ;
}

type InfoItem = {
    title: string;
    data: () => JSX.Element | string;
}
