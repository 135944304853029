import React, { PropsWithChildren } from "react";
import Header from "./parts/header";
import Cell from "./parts/cell";
import Row from "./parts/row";
import Body from "./parts/body";

function BaseTable(props: PropsWithChildren<unknown>) {
    return (
        <div className={"flex flex-col w-full"}>
            {props.children}
        </div>
    );
}

export default Object.assign(BaseTable, {
    Header: Header,
    Row: Row,
    Cell: Cell,
    Body: Body,
});