import React from "react";
import { useAppDispatch } from "@redux/hooks";
import { useExportProductsMutation } from "@redux/features/products/productsApi";
import BaseExportModal from "@reusables/Modals/BaseExportModal";
import { adaptToExportRequest, BaseExportModalPropsProvider } from "@reusables/Modals/BaseExportModal/utils";
import { useGetExportableColumnsQuery } from "@redux/features/dfs/dfsApi";
import { performExport } from "@redux/features/dfs/thunks";

type ProductsExportModalProperties = BaseExportModalPropsProvider;

export default function ProductsExportModal({ isOpen, onClose, ...props }: ProductsExportModalProperties) {
    const dispatch = useAppDispatch();
    const [exportProducts] = useExportProductsMutation();

    const { data: columns = [], isLoading: isColumnsLoading } = useGetExportableColumnsQuery({ module: "product" });

    return (
        <BaseExportModal
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={(data) => {
                dispatch(
                    performExport("product", () => {
                        return exportProducts(adaptToExportRequest(props, data));
                    })
                );
            }}
        >
            <BaseExportModal.FiltersInheritanceWarning />
            <BaseExportModal.Email />
            <BaseExportModal.ColumnsSelector columns={columns} isLoading={isColumnsLoading} />
            <BaseExportModal.ConcurrencyWarning />
            <BaseExportModal.Confirm />
        </BaseExportModal>
    );
}