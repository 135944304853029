
import React from 'react'
import { useTranslation } from 'react-i18next';
import BaseButton from '@reusables/BaseButton';
import BaseModal from '@reusables/Modals/BaseModal';
import { toastError } from '@helpers/utils';
import { Product } from '@/types/general';
import { toast } from 'react-toastify';
import { useDeleteProductLocationMutation } from '@redux/features/products/productsApi';

interface DeletionModalProperties {
    isOpen: boolean;
    onClose: () => void;
    data: {location: Product.Augmentations.Locations.Root, product_id: number}; 
}

export default function DeletionModal({isOpen, onClose, data}: DeletionModalProperties ) {
    const { t } = useTranslation("", { keyPrefix: "products.viewEditProduct.tabs.locations.modals.deletion" });

    const [deleteProductLocation, { isLoading: isDeleteProductLocationLoading }] = useDeleteProductLocationMutation();

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={onClose}
            width={700} 
            padding="56px" 
            isLoading={isDeleteProductLocationLoading}
        >
            <div className="space-y-[32px]">
                <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
                <div className="text-center text-accent">
                    {t("subHeading")} <span className="bold-highlight">{data.location?.store.name}?</span>
                </div>
                <div className="grid grid-cols-2 gap-[16px]">
                    <BaseButton text={t("buttons.cancel")} size="md" primaryOutlined onClick={onClose} />
                    <BaseButton
                        text={t("buttons.delete")}
                        size="md"
                        onClick={() => {
                            if (data.location?.store) {
                                console.log(data);
                                deleteProductLocation({ 
                                    product_id: data.product_id,
                                    location_id: data.location.store.id,
                                    ...(!!data.location.section && {section_id: data.location.section.id})
                                })
                                    .unwrap()
                                    .then(() => {
                                        toast.success(t("responses.success"));
                                        onClose();
                                    })
                                    .catch((e) => {
                                        toastError(e);
                                    });
                            }
                        }}
                    />
                </div>
            </div>
        </BaseModal>
    )
}
