import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./features/auth/authSlice";
import themeReducer from "./slices/themeReducer";
import companiesReducer from "./features/companies/companiesSlice";
import productsReducer from "./features/products/productsSlice";
import purchasesReducer from "./features/purchases/purchasesSlice";
import dfsReducer from "./features/dfs/dfsSlice";
import importInfoReducer from "@redux/features/importInfo/importInfoSlice";
import { internalApiSlice } from "./api/internalApiSlice";
import { externalApiSlice } from "./api/externalApiSlice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { stockCountSlice } from "@redux/features/stockCount/stockCountSlice";

const persistedAuthReducer = persistReducer(
    {
        key: "auth",
        storage
    },
    authSlice.reducer
);

const combinedReducers = combineReducers({
    theme: themeReducer,
    [authSlice.name]: persistedAuthReducer,
    [internalApiSlice.reducerPath]: internalApiSlice.reducer,
    [externalApiSlice.reducerPath]: externalApiSlice.reducer,
    companies: companiesReducer,
    products: productsReducer,
    purchases: purchasesReducer,
    dfs: dfsReducer,
    importInfo: importInfoReducer,
    stockCount: stockCountSlice.reducer
});

const rootReducer = (state: any, action: any) => {
    if (action.type === "auth/logout") {
        state = {
            auth: state.auth
        };
    }

    return combinedReducers(state, action);
};

export const globalStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
        .concat(internalApiSlice.middleware)
        .concat(externalApiSlice.middleware)
});

export const persistor = persistStore(globalStore);

export type GlobalStoreState = ReturnType<typeof globalStore.getState>;