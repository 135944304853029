import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { FileUp, ScanText, XCircle } from "lucide-react";
import { useTranslation } from "react-i18next";
import BaseModal from "@reusables/Modals/BaseModal";
import { ReactComponent as SuccessSVG } from "@assets/icons/ic_success.svg";
import { BaseCircularProgressWithContent } from "@reusables/BaseCircularProgressWithContent";
import { resetOperation, selectOperation } from "@redux/features/dfs/dfsSlice";
import { isDataTransferStateActive, isDataTransferStateFinished } from "@redux/features/dfs/types";

const operation = "stock_count_approval";

export default function StockCountIndicator(): JSX.Element {
    const { t } = useTranslation("", { keyPrefix: "inventory.stockCount.indicator" });

    const dispatch = useAppDispatch();

    const state = useAppSelector(selectOperation(operation));

    const [isResultModalOpen, setIsResultModalOpen] = useState(false);

    return (
        <>
            {
                // While ACTIVE, show a progress indicator
                isDataTransferStateActive(state) && (
                    <BaseCircularProgressWithContent
                        size={26}
                        tooltip={t("states.inProgress")}
                    >
                        <ScanText size={12} />
                    </BaseCircularProgressWithContent>
                )
            }
            {
                // When FINISHED, show a final state (100% progress) and allow opening a modal for result
                isDataTransferStateFinished(state) && (
                    <>
                        <BaseCircularProgressWithContent
                            size={26}
                            variant="determinate"
                            value={100}
                            sx={{
                                color: state.has_errors ? "#E25C5C" : "#A6DD4C"
                            }}
                            onClick={() => setIsResultModalOpen(true)}
                            tooltip={state.has_errors
                                ? t("states.finishedWithErrors")
                                : t("states.finishedSuccessfully")}
                        >
                            <FileUp size={12} />
                        </BaseCircularProgressWithContent>

                        <BaseModal
                            isOpen={isResultModalOpen}
                            onClose={() => {
                                dispatch(resetOperation({ operation }));
                                setIsResultModalOpen(false);
                            }}
                            padding="40px"
                            position="center"
                        >
                            {
                                state.has_errors ? (
                                    <div className="flex items-center gap-4">
                                        <XCircle width={40} height={40} fill="#FF0000" color="#FFF" />
                                        <div className="flex flex-col items-start space-y-4">
                                            <div className="text-accent font-bold text-xl text-center">
                                                {t("states.error.title")}
                                            </div>
                                            <div className="text-accent text-lg">
                                                {t("states.error.message")}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex items-center gap-4">
                                        <SuccessSVG width={40} height={40} />
                                        <div className="text-accent font-bold text-xl text-center">
                                            {t("states.applied")}
                                        </div>
                                    </div>
                                )
                            }
                        </BaseModal>
                    </>
                )
            }
        </>
    );
}
