import { DropResult } from "@hello-pangea/dnd";
import {
    isGroup,
    isLine,
    LineGroupUnion
} from "@components/Dashboard/pages/Sales/Orders/components/MutationLayout/types";
import _ from "lodash";

function moveLine<T>(
    lines: T[],
    sourceAccessor: string,
    sourceIndex: number,
    destinationAccessor: string,
    destinationIndex: number
) {

    // Clone the state to avoid reference issues
    const newState = { lines: _.cloneDeep(lines) };

    // Get the arrays using lodash
    const sourceArr = _.get(newState, sourceAccessor);
    const destinationArr = _.get(newState, destinationAccessor);

    if (sourceAccessor === destinationAccessor) {
        const [movedItem] = sourceArr.splice(sourceIndex, 1);
        destinationArr.splice(destinationIndex, 0, movedItem);
    } else {
        destinationArr.splice(destinationIndex, 0, sourceArr[sourceIndex]);
        sourceArr.splice(sourceIndex, 1);
    }

    return newState;
}

export function handleDragEnd<T>(result: DropResult, lines: T[], onUpdate: (lines: T[]) => void) {
    const sourceIndex = result.source.index;
    const sourceAccessor = result.source.droppableId;

    console.log("[onDragEnd] Pangea's result:", result);
    console.log("[onDragEnd] Initial lines state:", lines);

    // TODO: There is a HUGE problem with Pangea determining whether the event is "combine" or "destination". Therefore I faced a ton of problems and added a myriad of checks, that frankly should not be in an ideal code.
    // Thus, we will need to rework this or find out a way we can improve this. It works for now, but, again, looks like a fucking mess (I lost my mind in the process).
    // RIP Nekear 08.02.2025
    if (result.destination) {
        const destinationAccessor = result.destination.droppableId;
        const destinationIndex = result.destination.index;

        // WARNING: there are cases where Pangea treats combinations as destinations. Idk now whether it is me fucking up with indexes in the LinesRenderer component (check getRowId function) or Pangea's code.
        // Nevertheless, I needed to add this check to not face a fucking blank page each time I drag an empty group over another group.
        if (`${destinationAccessor}.${destinationIndex}`.length > `${sourceAccessor}.${sourceIndex}`.length) {
            console.warn("[moveLine] Warning: seems like you tried to move a line to itself or at a lower level (should be 'combine' instead). This is not allowed.");
            return { lines: lines };
        }

        const newState = moveLine(
            lines,
            sourceAccessor,
            sourceIndex,
            destinationAccessor,
            destinationIndex
        );
        console.log("[onDragEnd] Final lines state:", newState);

        onUpdate(newState["lines"]);
    } else if (result.combine) {
        console.log("[onDragEnd] Treating as combination");

        // First, get the target using the combine.draggableId
        const state = { lines };
        const combineWithUnparsedPath = result.combine.draggableId;
        // Replace any custom separator (">") with dot-notation
        const combineWithCorrectPath = combineWithUnparsedPath.replace(">", ".");
        const dropToArr = _.get(state, combineWithCorrectPath) as LineGroupUnion;

        // Get the dragged item from its source
        const sourceItem = _.get(state, result.source.droppableId)[result.source.index];
        if (!sourceItem) return;
        // Only combine if the dragged item is a line
        if (!isLine(sourceItem)) return;

        // ==> Dropping on a group
        if (isGroup(dropToArr)) {
            const newState = moveLine(
                lines,
                result.source.droppableId,
                result.source.index,
                combineWithCorrectPath + ".lines",
                0
            );

            onUpdate(newState["lines"]);

            console.log("[onDragEnd] Final lines state:", newState);
        } else
            // ==> Dropping on a line
        if (isLine(dropToArr)) {
            // The desired behavior here is when dropping over a line the system should create a new "unnamed" group
            const destinationAccessor = result.combine.droppableId;

            // If destination is inside a group, we should not allow grouping
            if(destinationAccessor.split(".").length > 1) {
                return;
            }

            // Cloning state to manipulate.
            const newState = { lines: _.cloneDeep(lines) };
            let destinationIndex = parseInt(combineWithUnparsedPath.split(">").pop() ?? "-1");
            if (destinationIndex === -1) return;

            // If the dragged item comes from the same parent and was positioned before the target,
            // adjust destination index accounting for following splice
            const sourceIndex = result.source.index;
            if (sourceAccessor === destinationAccessor && sourceIndex < destinationIndex) {
                destinationIndex -= 1;
            }

            const destinationArr = _.get(newState, destinationAccessor);
            const sourceArr = _.get(newState, result.source.droppableId);

            const [sourceLine] = sourceArr.splice(sourceIndex, 1);
            const [destinationLine] = destinationArr.splice(destinationIndex, 1);

            // Creating a new group with an empty name and a default root_module (here "custom")
            const groupName = destinationLine.product.code + " & " + sourceLine.product.code;
            const newGroup = {
                name: groupName.substring(0, Math.min(groupName.length, 50)),
                module: "custom",
                lines: [destinationLine, sourceLine]
            };

            // Inserting the new group at the position of the target line
            destinationArr.splice(destinationIndex, 0, newGroup);

            console.log("[onDragEnd] Final lines state:", newState);

            onUpdate(newState["lines"]);
        }
    }
}