import React, { useEffect, useMemo } from "react";
import BaseModal from "@reusables/Modals/BaseModal";
import { useGetModulePackagesQuery } from "@redux/features/packaging/packagingApi";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";

import BaseButton from "@reusables/BaseButton";
import BaseInput from "@reusables/BaseInput";
import BaseMaterialIconButton from "@reusables/BaseMaterialIconButton";
import { Plus, Ruler, Timer } from "lucide-react";
import { stopPropagate } from "@helpers/utils";
import {
    getPackageName,
    isPackageTemporary,
    PackageConfig,
    packageConfig,
    PickingMutationFormTyping
} from "../../MutationLayout/types";
import { ReactComponent as DeleteSVG } from "@assets/icons/ic_trash.svg";
import { useTranslation } from "react-i18next";
import PackageCreationModal from "../../MutationLayout/modals/PackageCreationModal";

import BaseBadge from "@reusables/BaseBadge";
import { Collapse, Typography } from "@mui/material";


interface PackagesModalProps {
    isOpen: boolean;
    onClose: () => void;
    packages: PickingMutationFormTyping["packages"];
    onSave: (data: FormSchemeTyping) => void;
}

const formScheme = z.object({
    packages: z.array(
        z.object({
            package: packageConfig,
            weight: z.coerce.number().nullish()
        })
    )
});

type FormSchemeTyping = z.infer<typeof formScheme>;

export default function PackagesModal(props: PackagesModalProps) {
    const { t } = useTranslation("", { keyPrefix: "sales.picking.modals.packaging" });

    const { data: loadedPackages, isLoading: isLoadedPackagesLoading } = useGetModulePackagesQuery("picking");

    const [temporaryPackages, setTemporaryPackages] = React.useState<PackageConfig[]>([]);

    const packagesOptions = useMemo(() => {
        return [...(loadedPackages ?? []), ...temporaryPackages];
    }, [loadedPackages, temporaryPackages]);

    const { control, handleSubmit, setValue, getValues, reset } = useForm<FormSchemeTyping>({
        resolver: zodResolver(formScheme),
        defaultValues: {
            packages: [{}]
        }
    });

    const { fields: packages, append, remove, update } = useFieldArray({
        control,
        name: "packages"
    });

    const onSubmit = handleSubmit((data) => {
        props.onSave?.(data);
    }, console.error);

    useEffect(() => {
        if (props.packages?.length) {
            setValue("packages", props.packages);
        } else {
            reset({
                packages: [{}]
            });
        }
    }, [props.isOpen, reset, props.packages]);

    const [isPackageCreationModalOpen, setIsPackageCreationModalOpen] = React.useState(false);

    const [packageOfCreationInitiationId, setPackageOfCreationInitiationId] = React.useState<number>();

    return (
        <>
            <BaseModal
                isOpen={props.isOpen}
                onClose={props.onClose}
                padding="56px"
                width={836}
                useCloseIcon
            >
                <form onSubmit={stopPropagate(onSubmit)} className="space-y-8">
                    <h3 className="text-2xl text-center text-accent font-semibold">{t("heading")}</h3>
                    {packages.map((field, index) => (
                            <div key={field.id}>
                                <div key={field.id} className="flex gap-6">
                                    <div className="flex-grow">
                                        <div className={"flex flex-row items-center space-x-4"}>
                                            <div className={"w-full"}>
                                                <Controller
                                                    name={`packages.${index}.package`}
                                                    control={control}
                                                    render={({ field, fieldState }) => (
                                                        <div>
                                                            <BaseDropdown
                                                                {...fieldState}
                                                                {...field}

                                                                value={packagesOptions?.find(p => p.id === field.value?.id)}

                                                                label={t("dropdowns.shippingBox.label") + ` #${index + 1}`}
                                                                placeholder={t("dropdowns.shippingBox.placeholder")}
                                                                options={packagesOptions}
                                                                getter={{
                                                                    label: (opt) => getPackageName(opt),
                                                                    key: (opt) => opt.id,
                                                                    caption: (opt) => "code" in opt ? opt.code : "",
                                                                    renderOption: (opt, icon) => (
                                                                        <div className="flex items-center justify-between">
                                                                            <div className="flex items-center space-x-2">
                                                                                <div>{getPackageName(opt)}</div>
                                                                                {
                                                                                    isPackageTemporary(opt) &&
                                                                                    <BaseBadge
                                                                                        className={"px-2 py-0 bg-gray-200"}>
                                                                                        <Timer size={12} />
                                                                                    </BaseBadge>
                                                                                }
                                                                            </div>
                                                                            <div className="flex items-center space-x-2 text-lightGreen-500 font-thin">
                                                                                <span>{"code" in opt ? opt.code : ""}</span>
                                                                                {opt.id == field.value?.id && icon}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }}
                                                                isLoading={isLoadedPackagesLoading}

                                                                action={{
                                                                    title: t("dropdowns.shippingBox.actionButton"),
                                                                    onClick: () => {
                                                                        setIsPackageCreationModalOpen(true);

                                                                        setPackageOfCreationInitiationId(index);
                                                                    }
                                                                }}

                                                                autocomplete
                                                            />
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                            <div className={"w-[150px]"}>
                                                <Controller
                                                    name={`packages.${index}.weight`}
                                                    control={control}
                                                    render={({ field, fieldState }) =>
                                                        <BaseInput
                                                            {...field}
                                                            error={fieldState?.error}
                                                            type="number"
                                                            label={t("fields.weight.label")}
                                                            placeholder={"0"}

                                                            hideArrows

                                                            icon={{
                                                                right: {
                                                                    el: <span
                                                                        className={"text-accent"}>{t("fields.weight.units")}</span>,
                                                                    offset: 16
                                                                }
                                                            }}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex mt-8 gap-2 items-center min-w-[68px]">
                                        {index === packages.length - 1 &&
                                            <BaseMaterialIconButton
                                                color="neutral"
                                                icon={<Plus size={24} color={"#686868"} />}
                                                onClick={() => append({} as any)}
                                            />
                                        }
                                        <DeleteSVG
                                            onClick={() => (index === 0 ? update(index, [{}] as any) : remove(index))}
                                            className="text-tables-secondaryIcon hover:text-tables-highlightedIcon cursor-pointer"
                                        />
                                    </div>
                                </div>

                                <div className={"mt-2"}>
                                    <Controller
                                        name={`packages.${index}.package`}
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <Collapse in={!!field.value}>
                                                <div>
                                                    {
                                                        !!field.value &&
                                                        <Typography
                                                            variant="caption"
                                                            display="block"
                                                            sx={{ opacity: 0.5 }}
                                                            className="flex items-center space-x-2"
                                                        >

                                                            <Ruler size={12} />
                                                            <span>
                                                                    {
                                                                        field.value.width.toFixed(2) + " x " + field.value.height.toFixed(2) + " x " + field.value.length.toFixed(2)
                                                                    }
                                                                    </span>
                                                        </Typography>
                                                    }
                                                </div>
                                            </Collapse>
                                        )}
                                    />
                                </div>
                            </div>
                        )
                    )}

                    <BaseButton
                        type="submit"
                        size="large"
                        text={t("buttons.save")}
                        buttonWidth="100%"
                    />
                </form>
            </BaseModal>
            <PackageCreationModal
                isOpen={isPackageCreationModalOpen}
                onClose={() => setIsPackageCreationModalOpen(false)}
                onCreate={(data) => {
                    // TODO: [fix] RTKQ (non temporary) creation *automatically* updates the *cache* with the new package
                    // making non temporary packages appear in the middle of the list, not in the end
                    if (isPackageTemporary(data)) {
                        setTemporaryPackages([...temporaryPackages, data]);
                    }

                    if (packageOfCreationInitiationId != undefined) {
                        setValue(`packages.${packageOfCreationInitiationId}.package`, data);
                    } else {
                        console.warn("Unable to determine the package of creation initiation... As the result, the package will be added to the end of the list");
                    }

                    setIsPackageCreationModalOpen(false);
                }}
            />
        </>
    );
}
