import React from "react";
import { Box, Stack } from "@mui/material";
import { Control, Controller, useWatch } from "react-hook-form";
import BaseInput from "@reusables/BaseInput";
import { PickingMutationFormTyping } from "@components/Dashboard/pages/Sales/Picking/components/MutationLayout/types";

type QuantityCellProps = {
    index: number;
    control: Control<PickingMutationFormTyping>;
}
export default function QuantityCell({control, index}: QuantityCellProps) {
    const row = useWatch({
        control,
        name: `lines.${index}.data`
    });

    if (!row)
        return null;

    if (row?.product?.is_service) {
        return (
            <Box width={80}>
                <Controller
                    name={`lines.${index}.data.picked_quantity`}
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            hideErrors
                            type="number"

                            sx={{
                                ".MuiInput-input": {
                                    padding: "9.5px"
                                }
                            }}
                        />
                    )}
                />
            </Box>
        )
    } else {
        return (
            <Stack
                spacing={"16px"}
            >
                {
                    row.picks?.map((pick, pickIndex) => (
                        <Stack
                            key={pickIndex}
                            direction={"row"}
                            spacing={1}
                            alignItems="center"
                        >
                            <Box width={80}>
                                <Controller
                                    name={`lines.${index}.data.picks.${pickIndex}.picked_quantity`}
                                    control={control}
                                    render={({field, fieldState}) => (
                                        <BaseInput
                                            {...field}
                                            error={fieldState.error}

                                            hideErrors
                                            type="number"

                                            sx={{
                                                ".MuiInput-input": {
                                                    padding: "9.5px"
                                                }
                                            }}

                                            disabled={row.quantity === row.already_picked_quantity}
                                        />
                                    )}
                                />
                            </Box>
                            <span>
                                                {pick.outgoing_location && <>of <span
                                                    className="font-semibold">{pick.outgoing_location.in_stock}</span></>}
                                                </span>
                        </Stack>
                    ))
                }
            </Stack>
        )
    }
}