import React, { useEffect } from "react";
import { SaleOrder } from "@/types/general";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import GeneralSection from "./parts/GeneralSection";
import ReferencesSection from "./parts/ReferencesSection";
import BaseButton from "@reusables/BaseButton";
import AddressSection from "./parts/AddressSection";
import ProductSection from "./parts/ProductSection";
import dayjs from "dayjs";
import { LineGroupUnion, SaleOrdersFormTyping, saleOrdersScheme } from "./types";
import LanguageSection from "./parts/LanguageSection";
import { cn, normalizePrice } from "@helpers/utils";

interface MutationLayoutProperties {
    saleOrder: SaleOrder.Extended | undefined;
    onSubmit: (args: SaleOrdersFormTyping) => void;
    disabled?: boolean;
}

export default function MutationLayout(props: MutationLayoutProperties) {
    const form = useForm<SaleOrdersFormTyping>({
        resolver: zodResolver(saleOrdersScheme),
        defaultValues: {
            mode: "creation",
            is_editable: true,
            is_billing_for_delivery: false,
            order_date: dayjs(),
            lines: [],
            is_invoiced: false,
            deleted_lines: []
        }
    });

    useEffect(() => {
        if (props.saleOrder) {
            form.setValue("mode", "editing");
            form.setValue("is_editable", props.saleOrder.is_editable);

            form.setValue("customer", props.saleOrder.customer);
            form.setValue("order_date", props.saleOrder.order_date);
            form.setValue("preferred_delivery_date", props.saleOrder.preferred_delivery_date);
            form.setValue("our_reference", props.saleOrder.our_reference);
            form.setValue("their_reference", props.saleOrder.their_reference);
            form.setValue("payment_terms", props.saleOrder.payment_terms);
            form.setValue("delivery_terms", props.saleOrder.delivery_terms);
            form.setValue("language", props.saleOrder.language);
            if (props.saleOrder.billing_address)
                form.setValue("billing_address", {
                    name: props.saleOrder.billing_address.name,
                    street: props.saleOrder.billing_address.street,
                    city: props.saleOrder.billing_address.city,
                    country: props.saleOrder.billing_address.country
                });
            form.setValue("delivery_address", props.saleOrder?.delivery_address);

            form.setValue("is_billing_for_delivery", props.saleOrder.is_billing_for_delivery);

            const combinedLines: LineGroupUnion[] = props.saleOrder.lines.map((line, index) => {
                if (line.type === "line") {
                    return {
                        ...line.data,
                        unit_price: normalizePrice(line.data.unit_price),
                    };
                } else {
                    return {
                        id: line.data.id,
                        name: line.data.name,
                        comment: line.data.comment,
                        module: line.data.module,
                        module_entity_id: line.data.module_entity_id,
                        lines: line.data.lines.map(groupLine => ({
                            ...groupLine.data,
                            unit_price: normalizePrice(groupLine.data.unit_price)
                        }))
                    };
                }
            });

            form.setValue("lines", combinedLines);

            form.setValue("deleted_lines", []);
        }
    }, [props.saleOrder]);

    const onSubmit = form.handleSubmit((data: SaleOrdersFormTyping) => {
        // Unused, because we forbid editing SO if it's invoiced
        // if (props.saleOrder?.is_invoiced) {
        //     for (let i = 0; i < form.getValues("lines").length; i++) {
        //         const originalQuantity = props.saleOrder?.lines[i].data?.quantity;
        //         const newQuantity = form.getValues("lines")[i].data!.quantity;

        //         // If the new quantity is less than the original, show error and stop the mutation
        //         if (newQuantity < originalQuantity) {
        //             toast.error(`Quantity for product ${form.getValues("lines")[i].data?.product.name} cannot be less than the original quantity.`);
        //             return;
        //         }
        //     }
        // }

        props.onSubmit(data);
    }, console.error);

    return (
        <FormProvider {...form}>
            <div className={cn("space-y-[40px]", props.disabled && "hidden")}>
                <GeneralSection />
                <ReferencesSection />
                <AddressSection />
                <LanguageSection />
                <ProductSection />
                <div className="flex justify-center">
                    <BaseButton text={"Save"} size={"md"} buttonWidth={"200px"} onClick={() => onSubmit()} />
                </div>
            </div>
        </FormProvider>
    );
}