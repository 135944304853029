import { internalApiSlice, transformResponse } from "@redux/api/internalApiSlice";
import { Packaging } from "@/types/general";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@redux/hooks";

export const packagingApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getPackages: builder.query<Packaging.Root[], void>({
            query: () => ({
                url: `packages`
            }),
            providesTags: [{type: "Packaging", id: "LIST"}],
            transformResponse
        }),

        getModulePackages: builder.query<Packaging.Root[], string>({
            query: (module) => ({
                url: `${module}/packages`
            }),
            providesTags: [{type: "Packaging", id: "LIST"}],
            transformResponse
        }),

        createPackage: builder.mutation<Packaging.Root, Packaging.DTO.CreateUpdate>({
            query: (body) => ({
                url: `packages`,
                method: "POST",
                data: body
            }),
            transformResponse,
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    const { data: createdPackage } = await queryFulfilled;
                    const createResult = dispatch(packagingApi.util.updateQueryData("getPackages", undefined, (draft) => {
                        draft.push({
                            id: createdPackage.id,
                            name: createdPackage.name,
                            code: createdPackage.code,
                            width: createdPackage.width,
                            height: createdPackage.height,
                            length: createdPackage.length,                        
                        })
                    }));
                } catch (err) {
                    console.error(err);
                }
            },
            invalidatesTags: [{type: "Packaging", id: "LIST"}]
        }),

        updatePackage: builder.mutation<Packaging.Root, Packaging.DTO.CreateUpdate & {id: number}>({
            query: ({id, ...body}) => ({
                url: `packages/${id}`,
                method: "PUT",
                data: body
            }),
            transformResponse,
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedPackage } = await queryFulfilled;
                    const updateResult = dispatch(packagingApi.util.updateQueryData("getPackages", undefined, (draft) => {
                        const index = draft.findIndex(item => item.id === updatedPackage.id);
                        if (index !== -1) {
                            draft[index] = updatedPackage;
                        }
                    }));
                } catch (err) {
                    console.error(err);
                }
            }
        }),

        deletePackage: builder.mutation<void, number>({
            query: (id) => ({
                url: `packages/${id}`,
                method: "DELETE"
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    const deleteResult = dispatch(packagingApi.util.updateQueryData("getPackages", undefined, (draft) => {
                        const index = draft.findIndex(item => item.id === id);
                        if (index !== -1) {
                            draft.splice(index, 1);
                        }
                    }));
                } catch (err) {
                    console.error(err);
                }
            }
        })
    })
});

const selectPackagesResult = packagingApi.endpoints.getPackages.select();

const selectAllPackages = createSelector(
    selectPackagesResult,
    (packages) => packages?.data ?? []
);

export const selectPackgageById = createSelector(
    selectAllPackages,
    (state: RootState, p_id: number | undefined) => p_id,
    (packages, p_id) => p_id != undefined ? packages.find(p => p.id === p_id) : undefined
);

export const selectPackageByCode = createSelector(
    selectAllPackages,
    (state: RootState, code: string | undefined) => code,
    (packages, code) => packages.find(pack => pack.code === code)
)

export const {
    useGetPackagesQuery,
    useGetModulePackagesQuery,
    useCreatePackageMutation,
    useUpdatePackageMutation,
    useDeletePackageMutation
} = packagingApi;