import React from "react";
import { useTranslation } from "react-i18next";
import { Alert, Collapse } from "@mui/material";
import {
    isGroup,
    isLine,
    SaleOrdersFormTyping
} from "@components/Dashboard/pages/Sales/Orders/components/MutationLayout/types";
import { useFormContext, useWatch } from "react-hook-form";
import _ from "lodash";

export default function LowStockWarning() {
    const { t } = useTranslation("", { keyPrefix: "sales.orders.general.orders" });
    const { control } = useFormContext<SaleOrdersFormTyping>();

    // Watch the full "lines" array
    const lines = useWatch({
        control,
        name: "lines"
    });

    // Flatten the array so that groups yield their contained lines.
    const flattenLines = (linesArray: SaleOrdersFormTyping["lines"] = []) =>
        linesArray.reduce((acc, item) => {
            if (isLine(item)) {
                acc.push(item);
            } else if (isGroup(item)) {
                acc.push(...item.lines);
            }
            return acc;
        }, [] as Exclude<SaleOrdersFormTyping["lines"][number], { name: string }>[]);


    const allLines = flattenLines(lines);

    console.log("Flattened lines", allLines, "from lines", lines);

    // Group lines by product id
    const groupedLines = _.groupBy(allLines, line => line.product.id);

    // Determine products with ordered quantity exceeding available stock.
    const lowStockProducts = Object.values(groupedLines)
        .filter(group => {
            const product = group[0].product;
            if (!product || product.is_service) return false;
            const totalQuantity = _.sumBy(group, line => line.quantity || 0);
            return totalQuantity > (product.in_stock ?? 0);
        })
        .map(group => {
            const product = group[0].product;
            const totalQuantity = _.sumBy(group, line => line.quantity || 0);
            return {
                ...product,
                totalQuantity,
                inStock: product.in_stock ?? 0
            };
        });

    const isLowStock = lowStockProducts.length > 0;

    return (
        <Collapse in={isLowStock}>
            <Alert severity="warning">
                <div className="space-y-2">
                    <div>{t("lowStockWarning")}</div>
                    <div className="space-y-1">
                        {lowStockProducts.map(product => (
                            <div key={product.id}>
                                {product.code} {product.name} - {product.totalQuantity} / {product.inStock}
                            </div>
                        ))}
                    </div>
                </div>
            </Alert>
        </Collapse>
    );
}
