import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import BaseCheckbox from "@reusables/BaseCheckbox";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import BaseTable from "@reusables/BaseTable";
import { selectTaxById, useGetTaxesFullQuery } from "@redux/features/taxes/taxesApi";
import { useAppSelector } from "@redux/hooks";
import MutationModal from "./modals/MutationModal";
import DeletionModal from "./modals/DeletionModal";

import { ReactComponent as EditSVG } from "@assets/icons/ic_edit.svg";
import { ReactComponent as DeleteSVG } from "@assets/icons/ic_trash.svg";

import { AbilityContext, Can } from "@/casl.config";
import { useAbility } from "@casl/react";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";

export default function TaxRatesPage() {
    const { t } = useTranslation("", { keyPrefix: "settings.general.taxRates" });

    // Getting taxes data using RTK Query
    const { data: taxRates = [], isLoading: isLoadingTaxRates } = useGetTaxesFullQuery();

    const ability = useAbility(AbilityContext);

    // Open states determine visibility of modals
    const [isMutationModalOpen, setIsMutationModalOpen] = useState(false);
    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);

    // Ids and corresponding selected cache entries allows modals to work with data
    const [selectedTaxRatesId, setSelectedTaxRatesId] = useState<number>();
    const [deletableTaxRatesId, setDeletableTaxRatesId] = useState<number>();

    const selectedTaxRate = useAppSelector(state => selectTaxById(state, selectedTaxRatesId));
    const deletableTaxRates = useAppSelector(state => selectTaxById(state, deletableTaxRatesId));

    return (
        <>
            <div className="levitation-extended">
                <div className="flex flex-row justify-between items-center mb-[44px]">
                    <div className="font-bold text-2xl text-accent">{t("heading")}</div>
                    <Can I="tax_rates.edit" a="general">
                        <BaseMaterialButton fontWeight={500} onClick={() => {
                            setSelectedTaxRatesId(undefined);
                            setIsMutationModalOpen(true);
                        }}>
                            {t("table.buttons.add")}
                        </BaseMaterialButton>
                    </Can>
                </div>

                <Can not I="tax_rates.view" a="general">
                    <NoPermissionBanner />
                </Can>

                <Can I="tax_rates.view" a="general">
                    <BaseTable data={taxRates} columns={[
                        {
                            header: t("table.columns.0"),
                            getter: (row) => <div>
                                <div className="bold-highlight">{row.name}</div>
                                <div>{row.code ?? "-"}</div>
                            </div>
                        },
                        {
                            header: t("table.columns.1"),
                            getter: (row) => row.rate + "%"
                        },
                        {
                            header: t("table.columns.2"),
                            getter: (row) => <BaseCheckbox disabled value={row.is_sales_tax} />
                        },
                        {
                            header: t("table.columns.3"),
                            getter: (row) => <BaseCheckbox disabled value={row.is_purchase_tax} />
                        },
                        {
                            visible: ability.can("tax_rates.edit", "general"),
                            header: () => "",
                            getter: (row) => (
                                <div className="text-tables-secondaryIcon">
                                    <Can I="tax_rates.edit" a="general">
                                        <EditSVG
                                            className="mr-[8px] cursor-pointer hover:text-tables-highlightedIcon"
                                            onClick={() => {
                                                setSelectedTaxRatesId(row.id);
                                                setIsMutationModalOpen(true);
                                            }}
                                        />
                                    </Can>
                                    <Can I="tax_rates.edit" a="general">
                                        <DeleteSVG
                                            className="cursor-pointer hover:text-tables-highlightedIcon"
                                            onClick={() => {
                                                setDeletableTaxRatesId(row.id);
                                                setIsDeletionModalOpen(true);
                                            }}
                                        />
                                    </Can>
                                </div>
                            ),
                            sx: {
                                textAlign: "right"
                            }
                        }]}
                               isDataLoading={isLoadingTaxRates}

                               headerSx={{
                                   backgroundColor: "#F9F9F9"
                               }}

                               sx={{
                                   borderCollapse: "separate",
                                   borderSpacing: "0 15px"
                               }}

                               size={"small"}
                    />
                </Can>
            </div>

            <MutationModal
                isOpen={isMutationModalOpen}
                onClose={() => setIsMutationModalOpen(false)}
                tax={selectedTaxRate}
            />

            <DeletionModal
                isOpen={isDeletionModalOpen}
                onClose={() => setIsDeletionModalOpen(false)}
                tax={deletableTaxRates}
            />
        </>
    );
}
