import React from "react";
import BaseButton from "@reusables/BaseButton";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@redux/hooks";
import { ExportModalFormTyping } from "@reusables/Modals/BaseExportModal";
import { useFormContext, useWatch } from "react-hook-form";
import { selectOperation } from "@redux/features/dfs/dfsSlice";
import { isDataTransferStateActive } from "@redux/features/dfs/types";

export default function ConfirmButton() {
    const { t } = useTranslation("", { keyPrefix: "general.modals.export.buttons" });

    const isExportInProgress = useAppSelector(state => isDataTransferStateActive(selectOperation("export")(state)));

    const { control } = useFormContext<ExportModalFormTyping>();

    const amountSelector = useWatch({
        control,
        name: "amount_selector"
    });

    const buttonText = t("export") + (amountSelector.selected ? ` (${amountSelector.selected})` : "");

    return (
        <div>
            <BaseButton
                buttonWidth="100%"
                text={buttonText}
                size="md"
                loading={isExportInProgress}
            />
        </div>
    );
}