import React from "react";
import { useAppDispatch } from "@redux/hooks";
import BaseExportModal from "@reusables/Modals/BaseExportModal";
import { useExportSaleOrdersMutation } from "@redux/features/sales/orders/salesOrdersApi";
import { adaptToExportRequest, BaseExportModalPropsProvider } from "@reusables/Modals/BaseExportModal/utils";
import { useGetExportableColumnsQuery } from "@redux/features/dfs/dfsApi";
import { performExport } from "@redux/features/dfs/thunks";

type SaleOrdersExportModalProps = BaseExportModalPropsProvider;

export default function SaleOrdersExportModal(props: SaleOrdersExportModalProps) {
    const dispatch = useAppDispatch();

    const [exportQuery] = useExportSaleOrdersMutation();

    const {
        data: columns = [],
        isLoading: isColumnsLoading
    } = useGetExportableColumnsQuery({ module: "sale_order" });

    return (
        <BaseExportModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            onSubmit={(data) => {
                dispatch(
                    performExport("sale_order", () => {
                        return exportQuery(adaptToExportRequest(props, data)).unwrap();
                    })
                );
            }}
        >
            <BaseExportModal.FiltersInheritanceWarning />
            <BaseExportModal.Email />
            <BaseExportModal.ColumnsSelector columns={columns} isLoading={isColumnsLoading} />
            <BaseExportModal.ConcurrencyWarning />
            <BaseExportModal.Confirm />
        </BaseExportModal>
    );
}