import React, { useEffect, useState } from "react";
import { Product } from "@/types/general";
import BaseTable from "@reusables/BaseTable";
import { useTranslation } from "react-i18next";
import BaseInput from "@reusables/BaseInput";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import { toast } from "react-toastify";
import { useUpdateProductLocationsMutation } from "@redux/features/products/productsApi";
import { z } from "zod";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { isErrorWithMessage } from "@redux/api/query";
import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import { Can, useAbility } from "@/casl.config";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";
import DeletionModal from "./modals/DeletionModal";
import { ReactComponent as TrashSVG } from "@assets/icons/ic_trash.svg";
import BasePermissionBlocker from "@reusables/BasePermissionBlocker";
import BaseTooltip from "@reusables/BaseTooltip";
import { cn } from "@helpers/utils";

interface LocationsTabProperties {
    product?: Product.Extended;
    preview: boolean;
}

const locationsScheme = z.object({
    locations: z.array(z.object({
        store: z.object({
            id: z.number(),
            name: z.string(),
        }).readonly(),
        section: z.object({
            id: z.number(),
            name: z.string(),
        }).readonly().optional(),
        inventory: z.object({
            in_stock: z.number().readonly(),
            min_inventory_quantity: z.coerce.number().min(0).nullish(),
        }),
    }))
});

type LocationsTyping = z.infer<typeof locationsScheme>;

export default function LocationsTab(props: LocationsTabProperties) {
    const {t} = useTranslation("", {keyPrefix: "products.viewEditProduct.tabs.locations"});
    const ability = useAbility();

    const {control, setValue, handleSubmit} = useForm<LocationsTyping>({
        resolver: zodResolver(locationsScheme),
    });

    const {fields: locations} = useFieldArray({
        control,
        name: "locations",
    })

    // Getting product locations update mutation hook from RTK Query
    const [updateProductLocations, updateResult] = useUpdateProductLocationsMutation();

    const [selectedLocation, setSelectedLocation] = useState<Product.Augmentations.Locations.Root>();


    useEffect(() => {
            if (props.product?.locations) {
                const locations = props.product.locations.map(location => ({
                    store: {
                        id: location.location_id,
                        name: location.location_name,
                    },
                    ...(location.sub_location_id && {
                        section: {
                            id: location.sub_location_id,
                            name: location.section_name as string,
                        },
                    }),
                    inventory: {
                        in_stock: location.in_stock ?? 0,
                        min_inventory_quantity: location.min_inventory_quantity,
                    }
                }));

                setValue("locations", locations);
            }
        },
        [props.product]
    )


    const onSubmit = handleSubmit(data => {
        if (props.product) {
            updateProductLocations({
                id: props.product.id,
                locations: data.locations.map(location => ({
                    store: location.store.id,
                    section: location.section?.id,
                    inventory: {
                        min_inventory_quantity: location.inventory.min_inventory_quantity,
                    }
                }))
            }).unwrap().then(() => {
                toast.success(t("responses.save.success"));
            }).catch(e => {
                if (isErrorWithMessage(e)) {
                    toast.error(e.message);
                } else {
                    toast.error(t("responses.save.error"));
                }
            })
        }
    }, console.error)

    return (
        <BaseLoadingBlocker active={updateResult.isLoading}>
            <Can not I="locations.view" a="product">
                <NoPermissionBanner/>
            </Can>

            <Can I="locations.view" a="product">
                <form onSubmit={onSubmit}>
                    <BaseTable
                        data={locations}
                        immutable
                        columns={[
                            {
                                header: t("table.columns.0"),
                                getter: row => <>
                                <span
                                    className="bold-highlight">{row.store.name}</span>{row.section ? ` - ${row.section.name}` : null}
                                </>
                            },
                            {
                                header: t("table.columns.1"),
                                getter: row => row.inventory.in_stock ?? "-"
                            },
                            {
                                header: t("table.columns.2"),
                                getter: (row, index) => {
                                    if (props.preview)
                                        return row.inventory.min_inventory_quantity ?? "-";

                                    return <Controller
                                        name={`locations.${index}.inventory.min_inventory_quantity`}
                                        control={control}
                                        render={({field, fieldState}) => (
                                            <BaseInput
                                                {...field}
                                                error={fieldState.error}
                                                type="number"

                                                sx={{
                                                    ".MuiInput-input": {
                                                        padding: "8px",
                                                    }
                                                }}

                                                hideErrors
                                                disabled={ability.cannot("locations.edit", "product")}
                                            />
                                        )}
                                    />;
                                }
                            },
                            {
                                header: t("table.columns.5"),
                                getter: (row) => {
                                    const isProductInStock = row.inventory.in_stock > 0;

                                    return (
                                        <BasePermissionBlocker action="locations.edit" module="product">
                                            <BaseTooltip active={isProductInStock} title={t("tooltips.cannotDeleteWithProducts") as string}>
                                                <TrashSVG
                                                    onClick={() => {
                                                        !isProductInStock ? setSelectedLocation(row as Product.Augmentations.Locations.Root) : null;
                                                    }}
                                                    className={cn(
                                                        "text-tables-secondaryIcon", 
                                                        !isProductInStock ? "hover:text-tables-highlightedIcon cursor-pointer" : "cursor-default opacity-50"
                                                    )}
                                                />
                                            </BaseTooltip>
                                        </BasePermissionBlocker>
                                    )
                                }
                            }
                        ]}
                    />

                    {
                        !props.preview &&
                        <div className="mt-[32px] text-right">
                            <BaseMaterialButton
                                fontWeight={500}
                            >
                                {t("buttons.save")}
                            </BaseMaterialButton>
                        </div>
                    }
                </form>

                <DeletionModal
                    isOpen={!!selectedLocation}
                    onClose={() => setSelectedLocation(undefined)}
                    data={{
                        location: selectedLocation as Product.Augmentations.Locations.Root, 
                        product_id: props.product?.id as number
                    }}
                />
            </Can>
        </BaseLoadingBlocker>
    );
}