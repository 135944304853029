import React from "react";
import { useTranslation } from "react-i18next";
import { useCreatePickingMutation } from "@redux/features/picking/pickingApi";
import { useHistory } from "react-router-dom";
import MutationLayout from "../components/MutationLayout";
import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import { toast } from "react-toastify";
import { downloadBlobExport } from "@helpers/utils";
import { isErrorWithMessage } from "@redux/api/query";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";
import { Can } from "@/casl.config";
import { isPackageTemporary } from "@components/Dashboard/pages/Sales/Picking/components/MutationLayout/types";

export default function PickingCreationPage() {
    const { t } = useTranslation("", { keyPrefix: "sales.picking.mutation" });

    const history = useHistory();

    const [createPicking, { isLoading: isCreationLoading }] = useCreatePickingMutation();

    return (
        <>
            <Can not I="create" a="picking">
                <div className="levitation-extended">
                    <NoPermissionBanner />
                </div>
            </Can>

            <Can I="create" a="picking">
                <BaseLoadingBlocker active={isCreationLoading}>
                    <MutationLayout
                        title={t("heading")}
                        onMutation={data => {
                            createPicking({
                                sale_order_id: data.sale_order.id,
                                date: data.picking_date.date.format("YYYY-MM-DD"),
                                lines: data.lines
                                    .filter(x => !!x.data)
                                    .filter(x => {
                                        if (x.data?.product.is_service) {
                                            return x.data.picked_quantity && x.data.picked_quantity > 0;
                                        } else {
                                            return x.data?.picks.some(y => (y.picked_quantity ?? 0) > 0 && y.outgoing_location);
                                        }
                                    })
                                    .map(x => ({
                                        sale_order_line_id: x.data?.id,
                                        product_id: x.data?.product.id,
                                        ...(!x.data?.product.is_service && {
                                            locations: x.data?.picks.map(y => ({
                                                location_id: y.outgoing_location?.location_id as number,
                                                ...(y.outgoing_location?.sub_location_id && { sub_location_id: y.outgoing_location.sub_location_id }),
                                                picked_quantity: y.picked_quantity as number
                                            }))
                                        }),
                                        ...(x.data?.product.is_service && {
                                            picked_quantity: x.data.picked_quantity
                                        }),
                                        ...(x.data?.product.is_serial_numbers && {
                                            serial_numbers: x.data?.serial_numbers
                                        }),
                                        ...(x.data?.product.is_batch_numbers && {
                                            batch_numbers: x.data?.batch_numbers.map(({
                                                                                          batch_number,
                                                                                          expiration_date
                                                                                      }) => ({
                                                batch_number,
                                                expiration_date: expiration_date?.format("YYYY-MM-DD")
                                            }))
                                        })
                                    })),

                                ...(data.packages?.length && {
                                    packages: data.packages.map(x => {
                                        if (isPackageTemporary(x.package)) {
                                            return {
                                                package: {
                                                    width: x.package.width,
                                                    length: x.package.length,
                                                    height: x.package.height
                                                },
                                                weight: x.weight
                                            };
                                        }

                                        return {
                                            package: {
                                                id: x.package.id
                                            },
                                            weight: x.weight
                                        };
                                    })
                                }),

                                ...(data.shipmondo?.enabled && {
                                    shipmondo: {
                                        outgoing_country: data.shipmondo.country?.code ?? "unable to retrieve",
                                        fallback_destination_country: data.order_destination_country?.code ?? "unable to retrieve",
                                        carrier_code: data.shipmondo.carrier?.code,
                                        product_code: data.shipmondo.product?.code,
                                        ...(data.shipmondo.services?.length && { service_codes: data.shipmondo.services?.map(x => x.code).join(",") })
                                    }
                                })
                            }).unwrap()
                                .then(resp => {
                                    toast.success(t("responses.success"));
                                    downloadBlobExport(() => resp, `delivery_note`, "pdf");
                                    history.push("/dashboard/picking");
                                })
                                .catch(e => {
                                    if (isErrorWithMessage(e)) {
                                        toast.error(e.message);
                                    } else {
                                        toast.error(t("responses.error"));
                                    }
                                });
                        }}
                    />
                </BaseLoadingBlocker>
            </Can>
        </>
    );
}
