import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { z } from "zod";
import BaseModal from "@reusables/Modals/BaseModal";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import BaseInput from "@reusables/BaseInput";
import BaseButton from "@reusables/BaseButton";
import { GroupSchema } from "@components/Dashboard/pages/Sales/Orders/components/MutationLayout/types";

const formScheme = z.object({
    name: z.string().max(30).min(1).trim(),
    comment: z.string().max(100).nullish()
});

export type FormTyping = z.infer<typeof formScheme>;

export interface AddGroupModalProps {
    isOpen: boolean;
    onClose: () => void;
    group?: GroupSchema;
    onMutation: (data: FormTyping) => void;
}

export default function AddGroupModal(props: AddGroupModalProps): JSX.Element {
    const { t } = useTranslation("", {
        keyPrefix: "sales.orders.general.orders.modals.editGroup"
    });

    const { control, reset, ...form } = useForm<FormTyping>({
        resolver: zodResolver(formScheme),
        defaultValues: {
            name: "",
            comment: null
        }
    });

    useEffect(() => {
        if (props.isOpen && props.group) {
            reset({
                name: props.group.name,
                comment: props.group.comment
            });
        } else {
            reset({ name: "", comment: null });
        }
    }, [props.isOpen, props.group, reset]);

    const onSubmit = form.handleSubmit(props.onMutation, console.error);

    return (
        <>
            <BaseModal
                isOpen={props.isOpen}
                onClose={props.onClose}
                width={764}
                padding="56px"
            >
                <form className="space-y-[32px]" onSubmit={onSubmit}>
                    <div className="modal-title">{t("heading")}</div>
                    <BaseInputsGrid cols={1}>
                        <Controller
                            name="name"
                            control={control}
                            render={({ field, fieldState }) => (
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    label={t("fields.name.label")}
                                    placeholder={t("fields.name.placeholder")}
                                />
                            )}
                        />

                        <Controller
                            name="comment"
                            control={control}
                            render={({ field, fieldState }) => (
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    label={t("fields.comment.label")}
                                    placeholder={t("fields.comment.placeholder")}
                                    type={"textarea"}
                                />
                            )}
                        />
                    </BaseInputsGrid>
                    <BaseButton text={t("buttons.add")} size="md" className="w-full" />
                </form>
            </BaseModal>
        </>
    );
}
