import dayjs from "dayjs";
import { Nullish, PartialBy } from "@helpers/utils";

// ====== /registration ===== //
export namespace Registration {
    export interface Root {
        id: number;
        first_name: string;
        last_name: string;
        email: string;
        phone_number: string;
        company_name: string;
        city: string;
        country: Country.Root;
        zipcode: string;
        street: string;
        currency: Currency.Root;
        street_2: string;
    }

    export namespace DTO {
        export interface Create {
            first_name: string,
            last_name: string,
            email: string,
            password: string,
            registration_number: string,
            company_name: string,
            phone: string,
            city: string,
            country: number,
            zipcode: string,
            street: string,
            currency_id: number,
            street_2?: string
        }

        export interface ResendVerificationEmail {
            email: string;
        }
    }
}

// ====== /login ===== //
export namespace Login {
    export interface Root {
        token: string;
        refresh_token: string;
    }

    export namespace DTO {
        export interface Auth {
            email: string;
            password: string;
            remember_me: boolean;
        }
    }
}

// ====== /me ===== //
export namespace Me {
    export interface Root {
        id: number;
        first_name: string;
        last_name: string;
        email: string;
        phone?: string;
        country: Country.Root;
        language?: Language.Root;
        company: {
            id: number;
            name: string;
        };
        role: {
            id: number;
            name: string;
            description?: string | null;
            permissions: Components.Permission[];
        };
    }

    export namespace Components {
        export interface Permission {
            module_key: Permission.Components.Modules;
            abilities: {
                key: string;
            }[];
        }
    }
}


// ====== /location... ======
export namespace Location {
    export interface Root {
        id: number;
        code?: string;
        store: string;
        country: string;
        city?: string;
        sections?: Section.Extended[];
        integrations: {
            poweroffice?: {
                projects: PowerOffice.Projects.Root[];
            },
            tripletex?: {
                projects: Tripletex.Projects.Root[];
            }
        };
    }

    export interface Extended extends Root {
        street?: string;
        zipcode?: string;
        contact_name?: string;
        phoneNumber?: string;
        email?: string;
    }

    export interface Slim {
        id: number;
        name: string;
        sections?: Section.Root[];
    }

    export namespace Section {
        export interface Root {
            id: number;
            name: string;
            code?: string;
        }

        export interface Extended extends Root {
            sector?: number;
            row?: number;
            shelf_height?: string;
        }
    }

    export namespace DTO {
        export type OrderBy = "id" | "country" | "name" | "city";

        export interface CreateUpdate {
            name: string;
            country?: string; // TODO: replace with object
            city?: string;
            street?: string;
            zipcode?: string;
            contact_name?: string;
            phone?: string;
            email?: string;
            sections?: PartialBy<Section.Extended, "id">[];
            integrations?: {
                poweroffice?: {
                    projects: number[]; // ids of projects
                },

                tripletex?: {
                    projects: number[];
                }
            };

            deleted_sections?: number[];
        }
    }
}

// ====== /users... ======
export namespace Users {
    export interface Root {
        id: number;
        first_name: string;
        last_name: string;
        email: string;
        status: 0 | 1; // 0 -> active, 1 -> suspended
        activity: {
            online: boolean;
            last_seen?: dayjs.Dayjs | null;
        };
        role: Pick<Role.Root, "id" | "name">;
        is_ceo: boolean;
        is_ceo_in_company: boolean;
    }

    export namespace DTO {
        export interface Filters {
            search?: string;
            roles?: number[];
            status?: 0 | 1;
        }

        export type OrderBy = "id" | "name" | "role" | "status" | "email";
    }

    export interface Extended extends Root {
        phone?: string;
        country?: Country.Root;
        language?: Language.Root;
        company?: string;
    }

    export namespace DTO {
        export interface Create {
            first_name: string;
            last_name: string;
            email: string;
            phone?: string | null;
            country?: number;
            language?: number;
            // company?: number;
            role?: number;
            password: string;
        }

        export type Update = PartialBy<Create, "email" | "password"> & { id: number };

        export interface UpdatePassword {
            current_password: string;
            new_password: string;
            confirm_password: string;
        }
    }
}


// ===== /posts... ======
export namespace News {
    export type Root = {
        id: number;
        attributes: {
            smallHeader: string;
            header: string;
            text: string;
            description: string | null;
            mainText: string;
            createdAt: string;
            updatedAt: string;
            publishedAt: string;
            locale: string;
            slug: string;
            mainImg: {
                data?: {
                    id: number;
                    attributes: {
                        name: string;
                        alternativeText: string;
                        caption: string;
                        width: number;
                        height: number;
                        formats: {
                            thumbnail: {
                                name: string;
                                hash: string;
                                ext: string;
                                mime: string;
                                width: number;
                                height: number;
                                size: number;
                                path: string | null;
                                url: string;
                            };
                            small: {
                                name: string;
                                hash: string;
                                ext: string;
                                mime: string;
                                width: number;
                                height: number;
                                size: number;
                                path: string | null;
                                url: string;
                            };
                        };
                        hash: string;
                        ext: string;
                        mime: string;
                        size: number;
                        url: string;
                        previewUrl: string | null;
                        provider: string;
                        provider_metadata: unknown | null;
                        createdAt: string;
                        updatedAt: string;
                    };
                };
            };
            author: {
                id: number;
                fullName?: string;
                date?: string;
            };
            imgPlugs: {
                data?: {
                    id: number;
                    attributes: {
                        name: string;
                        alternativeText: string;
                        caption: string;
                        width: number;
                        height: number;
                        formats: {
                            thumbnail: {
                                name: string;
                                hash: string;
                                ext: string;
                                mime: string;
                                width: number;
                                height: number;
                                size: number;
                                path: string | null;
                                url: string;
                            };
                            small: {
                                name: string;
                                hash: string;
                                ext: string;
                                mime: string;
                                width: number;
                                height: number;
                                size: number;
                                path: string | null;
                                url: string;
                            };
                        };
                        hash: string;
                        ext: string;
                        mime: string;
                        size: number;
                        url: string;
                        previewUrl: string | null;
                        provider: string;
                        provider_metadata: unknown | null;
                        createdAt: string;
                        updatedAt: string;
                    };
                }[];
            };
            authorImg: {
                data?: {
                    id: number;
                    attributes: {
                        name: string;
                        alternativeText: string;
                        caption: string;
                        width: number;
                        height: number;
                        formats: {
                            thumbnail: {
                                name: string;
                                hash: string;
                                ext: string;
                                mime: string;
                                width: number;
                                height: number;
                                size: number;
                                path: string | null;
                                url: string;
                            };
                        };
                        hash: string;
                        ext: string;
                        mime: string;
                        size: number;
                        url: string;
                        previewUrl: string | null;
                        provider: string;
                        provider_metadata: unknown | null;
                        createdAt: string;
                        updatedAt: string;
                    };
                };
            };
        }
    }
}

// ===== /translations... ======
export namespace Translation {
    export interface Root {
        id: number;
        name: string;
        description: string;
        language: {
            id: number;
            name: string;
            code: string;
        };
    }
}

// ===== /product... =====
export namespace Product {
    export interface Slim {
        id: number;
        name: string;
        code: string;
        prices: {
            purchase_price: number;
            selling_price: number;
            extra_cost?: number;
            exchange_rate?: number;
            source_currency?: {
                id: number;
                code: string;
                name: string;
                symbol: string;
            }
        };

        tax?: Tax.Slim;
        in_stock?: number;
        is_component: boolean; // should allow components creation at "bill of material"
        is_service: boolean;
        has_bom: boolean;
        supplier?: Supplier.Slim;
    }

    export type Root = Slim & CustomFields.Embedded.Read & {
        category?: Category.Root;
        in_stock?: number;

        lime_id: number | null;
        tripletex_id: number | null;
        twenty_four_seven_office_id: number | null;
        poweroffice_id: number | null;

        parent?: {
            id: number;
            name: string;
            code: string;
        } | null;

        variants?: Omit<Product.Root, "parent" | "variants">[];
    }

    export interface Extended extends Exclude<Root, "in_stock" | "selling_price"> {
        barcode?: string;
        unit?: Unit.Root;
        supplier?: Supplier.Slim;
        tax?: Tax.Slim;

        locations?: (Components.Location & {
            in_stock: number | null;
            min_inventory_quantity: number | null;
        })[];

        min_purchase_quantity?: number | null;
        min_sale_quantity?: number | null;

        template?: Template.Root<Components.DisableableTemplateField>;

        is_RFID: boolean;
        is_batch_number: boolean; // is produced by batches
        is_serial_number: boolean; // does have a serial number

        // Image can only be present, if no variants added (otherwise user won't be able to add new image)
        image_path?: string;
        image?: string;

        description?: string;

        weights_and_sizes: {
            weight?: number;
            CBM?: number;
            width?: number;
            height?: number;
            length?: number;
        };

        translations?: {
            id: number;
            language: Language.Root;
            name: string;
            description: string;
        }[];

        variants?: Omit<Product.Extended, "parent" | "variants" | "translations">[];
    }

    export namespace Components {
        export interface Image {
            id: number;
            name: string;
        }

        export interface Location {
            location_id: number;
            location_name: string;

            sub_location_id?: number;
            section_name?: string;
        }

        export interface LocationInventory {
            in_stock: number | null;
            min_inventory_quantity: number | null;
        }

        export type DisableableTemplateField =
            | "barcode"
            | "unit"
            | "category"
            | "location"
            | "supplier"
            | "tax"
            | "weight"
            | "CBM"
            | "width"
            | "height"
            | "length"
            | "purchaseQty"
            | "saleQty";
    }

    export interface PurchaseOrder {
        id: number;
        supplier: Supplier.Slim;
        quantity: number;
        receive_state: 0 | 1 | 2; // 1 -> not received, 2 -> in progress, 3 -> received
        order_date: dayjs.Dayjs;
    }

    export interface SalesOrder {
        id: number;
        customer: string;
        quantity: number;
        shipment_state: 0 | 1 | 2;
        order_date: dayjs.Dayjs;
    }

    export namespace DTO {
        export type OrderBy = "id" | "productName" | "inStock" | "sellingPrice";
        export type Filters = {
            ids?: number[];
            search?: string;
            categories?: number[];
            selling_price_range?: number[];
            quantity_range?: number[];
            components?: number; // 0 | 1
        }

        export interface UpdateInformation extends CustomFields.Embedded.Write {
            name: string;
            code?: string;
            barcode?: string;
            unit?: number;
            category?: number;

            supplier?: number;
            tax?: number;

            is_RFID: boolean;
            is_batch_number: boolean; // is produced by batches
            is_serial_number: boolean; // does have a serial number
            is_component: boolean;

            // Image might only be present, if new has been added
            image?: File;

            description?: string;
            weights_and_sizes?: {
                weight?: number;
                CBM?: number;
                width?: number;
                height?: number;
                length?: number;
            };

            prices: {
                selling_price?: number | null;
                extra_cost?: number | null;
            };

            min_purchase_quantity?: number | null;
            min_sale_quantity?: number | null;
        }

        export interface UpdateLocations {
            locations: {
                store: number;
                section?: number;
                inventory: {
                    min_inventory_quantity?: number | null;
                }
            }[];
        }

        export interface Create extends Omit<UpdateInformation, "inventory" | "prices"> {
            variant_parent_id?: number | null;
            // Will be absent, when product is service
            location?: {
                store: number;
                section?: number;
            };
            template?: number; // template id
            prices?: {
                purchase_price: number;
                selling_price: number;
                extra_cost?: number | null;
            };

            serial_numbers: {
                serial_number: string;
            }[];

            batch_numbers: {
                batch_number: string;
                expiration_date?: string;
            }[];

            inventory?: Components.LocationInventory;
        }

        export type ImportColumns = "name"
            | "product_code"
            | "is_service"
            | "cost_price"
            | "selling_price"
            | "location_code"
            | "section_code"
            | "location_quantity"
            | "min_inventory_quantity"
            | "category_code"
            | "unit_code"
            | "supplier_code"
            | "tax_code"
            | "collection_code"
            | "currency_code"
            | "barcode"
            | "has_rfid"
            | "has_batch_number"
            | "has_serial_number"
            | "is_component"
            | "has_package_unit"
            | "description"
            | "weight"
            | "CBM"
            | "width"
            | "height"
            | "length"
            | "min_purchase_quantity"
            | "min_sale_quantity"
            | "extra_cost";
    }

    export namespace Augmentations {
        // ===== /product/id/translations ==== //
        export namespace Translation {
            export interface Root {
                id: number;
                name: string;
                description: string;
                language: {
                    id: number;
                    name: string;
                    code: string;
                };
            }

            export namespace DTO {
                export interface Create {
                    language_id: number;
                    name: string;
                    description: string;
                }

                export type Update = PartialBy<Create, "language_id">;
            }
        }

        export namespace Locations {
            export interface Root {
                store: {
                    id: number;
                    name: string,
                },
                section: {
                    id: number,
                    name: string,
                },
            }
        }

        // ===== /product/id/serial-numbers ==== //
        export namespace SerialNumbers {
            export interface Root {
                id: number;
                serial_number: string;
                created_at: dayjs.Dayjs;
                receipts: {
                    id: number;
                    code: string;
                }[];
                dispatches: {
                    id: number;
                    code: string;
                }[];
            }

            export namespace DTO {
                export interface Filters {
                    search?: string;
                    dates_range?: {
                        from: string;
                        to: string;
                    };
                }

                export type OrderBy = "id" | "serial_number" | "created_at";

                export interface Update {
                    serial_number: string;
                }
            }
        }

        // ===== /product/id/batch-numbers ==== //
        export namespace BatchNumbers {
            export interface Root {
                id: number;
                batch_number: string;
                created_at: dayjs.Dayjs;
                expiration_date?: dayjs.Dayjs;
                receipts: {
                    id: number;
                    code: string;
                }[];
                dispatches: {
                    id: number;
                    code: string;
                }[];
            }

            export namespace DTO {
                export interface Filters {
                    search?: string;
                    dates_range?: {
                        from?: string;
                        to?: string;
                        expired_from?: string;
                        expired_to?: string;
                    },
                }

                export type OrderBy = "id" | "batch_number" | "created_at" | "expiration_date";

                export interface Update {
                    batch_number: string;
                    expiration_date?: string;
                }
            }
        }

        // ===== /product/id/prices ==== //
        export namespace Prices {
            export interface PurchaseRoot {
                id: number;
                price: number;
                origin: {
                    id: number | null;
                    name: "adjustment" | "purchase_order" | Integration.Components.Slug;
                };
                date: dayjs.Dayjs;
                exchange_rate?: number;
                currency?: {
                    id: number;
                    name: string;
                    code: string;
                    symbol: string;
                    rate: number; // exchange rate used for the purchase order, where prices was updated
                } | null;
                author?: {
                    id: number;
                    first_name: string;
                    last_name: string;
                    email: string;
                };
            }

            export interface SaleRoot {
                id: number;
                price: number;
                origin: {
                    id: number | null;
                    name: "adjustment" | "sale_order" | "product" | Integration.Components.Slug;
                };
                date: dayjs.Dayjs;
                author?: {
                    id: number;
                    first_name: string;
                    last_name: string;
                    email: string;
                };
            }
        }
    }

}


// ===== /unit ... =====
export namespace Unit {
    export interface Root {
        id: number;
        name: string;
        code: string;
        custom_name?: string;
    }

    export interface Public {
        id: number;
        name: string;
        code: string;
    }

    export namespace DTO {
        export type CreateAdd = {
            unit_id: number;
            custom_name: string | undefined;
        }

        export type CreateUpdate = {
            custom_name: string | undefined;
        }
    }
}

// ===== /supplier ... =====
export namespace Supplier {
    export interface Slim {
        id: number;
        name: string;
        code: string;
    }

    export interface Root extends Slim {
        tripletex_id: number | null;
        poweroffice_id: number | null;
        twenty_four_seven_office_id: number | null;

        contacts: {
            id: number;
            name: string;
            phone?: string;
            email?: string;
        }[];
    }

    export interface Extended {
        id: number;
        name: string;
        code: string;
        vat?: string;
        tax_rate?: Tax.Slim;

        currency?: Currency.Root;
        payment_terms?: PaymentTerms.Root;
        delivery_terms?: DeliveryTerms.Root;
        language?: Language.Root;

        contacts: Components.Contact[];

        billing?: Components.BillingAddress;

        returns: Components.ReturnAddress[];
    }

    export namespace Components {
        export interface Contact {
            id: number;
            name: string;
            phone: string;
            email: string;
        }

        export interface BillingAddress {
            name: string;
            street?: string;
            street_2?: string;
            zipcode?: string;
            city?: string;
            country?: Country.Root;
            phone?: string;
            email?: string;
            is_used_for_return?: boolean;
        }

        export interface ReturnAddress {
            id: number;
            name: string;
            street?: string;
            street_2?: string;
            zipcode?: string;
            city?: string;
            country?: Country.Root;
            contact_person?: string;
            phone?: string;
            email?: string;
            is_primary: boolean;
        }
    }

    export namespace DTO {
        export interface Filters {
            search?: string,
        }

        export type OrderBy = "id" | "supplier_name" | "email" | "phone" | "contact_name";

        export interface Create {
            name: string;
            code?: string | null;
            vat?: string | null;
            currency?: number | null;
            tax_rate?: number | null;
            payment_terms?: number | null;
            contacts?: {
                id?: number;
                name: string;
                phone?: string | null;
                email?: string | null;
            }[];

            billing?: Omit<Nullish<Components.BillingAddress>, "country"> & { country?: number };

            returns?: (Omit<Nullish<Components.ReturnAddress>, "country"> & { country?: number })[];
        }

        export interface Update extends Create {
            deleted_contacts: number[];

            deleted_returns: number[];
        }
    }
}

// ===== /employee ... =====
export namespace Employee {
    export interface Root {
        id: number;
        code: string;
        name: string;
        job_title?: string;
        email: string;
        phone?: string;
        company?: {
            id: number;
            name: string;
        },
        language?: {
            id: number;
            name: string;
            code: string;
        }
    }

    export namespace DTO {
        export type OrderBy = "id" | "name" | "jobTitle" | "email";

        export interface Filters {
            search?: string;
        }

        export type CreateUpdate = PartialBy<Omit<Root, "id" | "language" | "company">, "code"> & {
            language?: number,
            company?: number
        }
    }
}

// ===== /currency ===== //
export namespace Currency {
    export interface Root {
        id: number;
        name: string;
        code: string;
        symbol: string;
        rate: number;
        isFixed: boolean;
        isBase: boolean;
    }

    export namespace DTO {
        export type Update = {
            fixedRate: null | number;
        }
    }
}

// ===== /packaging ===== //
export namespace Packaging {
    export interface Root {
        id: number;
        code: string;
        name: string;
        width: number;
        length: number;
        height: number;
    }

    export namespace DTO {
        export type CreateUpdate = PartialBy<Omit<Root, "id">, "code">
    }
}

// ===== /tax ... =====
export namespace Tax {
    export interface Slim {
        id: number;
        name: string;
        code: string;
        rate: number;
    }

    export interface Root extends Slim {
        is_sales_tax: boolean;
        is_purchase_tax: boolean;
    }

    export namespace DTO {
        export type CreateUpdate = PartialBy<Omit<Root, "id">, "code">
    }
}

// ===== /price-list ===== //
export namespace PriceList {
    export interface Root {
        id: number;
        name: string;
        code: number;
        currency: Currency.Root;
    }
}

// ===== /language ===== //
export namespace Language {
    export interface Root {
        id: number;
        name: string;
        code: string;
    }

    export namespace DTO {
        export interface Create {
            language_id: number;
        }
    }
}

// ===== /stock ===== //
export namespace Stock {
    export type Root = {
        id: number;
        name: string;
        code: string;
        cost_price: number;
        in_stock: number;
        extra_cost?: number | null;
        category?: Category.Root;
        product_locations: {
            location_id: number;
            location_name: string;
            section_name?: string;
            section_sector?: number;
            section_row?: number;
            section_shelf_height?: string;
            in_stock: number;

            integrations: {
                poweroffice: {
                    projects: PowerOffice.Projects.Root[];
                },
                tripletex?: {
                    projects: Tripletex.Projects.Root[];
                }
            };
        }[]
    }

    export namespace DTO {
        export type Filters = {
            search?: string;
            location?: {
                store: number,
                section?: number
            }[];
            categories?: number[];
            purchasing_price_range?: number[];
            stock_range?: number[];
            projects?: {
                integration: Integration.Components.Slug;
                project_id: number;
            }[];
        }

        export type OrderBy =
            "productName"
            | "category"
            | "purchasePrice"
            | "inStock";

        export type ExportArguments = {
            is_all?: boolean;
            ids?: number[];
            dates_range?: {
                from: string;
                to: string;
            };
        }
    }
}

// ===== /stock-count ===== //
export namespace StockCount {
    export interface Root {
        id: number;
        code: string;
        date: dayjs.Dayjs;
        is_merge_result: boolean;
        user: Users.Root;
        reviewer?: Users.Root;
        status: 0 | 1 | 2 | 3; // 0 -> new, 1 -> cancelled, 2 -> declined, 3 -> approved
        decline_comment: string;
        cancel_comment: string;
        entries: {
            id: number;
            counted_quantity: number;
            system_quantity: number;

            product: {
                id: number;
                name: string;
                code: string;
            };

            section?: {
                id: number;
                name: string;
            };

            store: {
                id: number;
                name: string;
            };
        }[];
        has_rollback_file: boolean;
    }

    export namespace DTO {
        export interface Filters {
            search?: string,
            locations?: {
                store: number,
                section?: number
            }[],
            dates_range?: {
                from: string;
                to: string;
            },
            workers?: number[],
            status?: 0 | 1 | 2 | 3; // 0 -> new, 1 -> cancelled, 2 -> declined, 3 -> approved
        }

        export type OrderBy = "id" | "date" | "worker" | "status";

        export type ExportColumns =
            "stock_count_id"
            | "product_id"
            | "product_name"
            | "store"
            | "section"
            | "counted_quantity"
            | "system_quantity"
            | "status"
            | "date"
            | "worker_first_name"
            | "worker_last_name"
            | "cancel_comment"
            | "reviewer_first_name"
            | "reviewer_last_name"
            | "decline_comment";

        export type ExportFilters = {
            ids: number[];
        }

        export type Approve = {
            email: string;
            zero_count: boolean;
        }
    }
}

// ==== /template ==== //
export namespace Template {
    export interface Root<FIELDS = string> {
        id: number;
        name: string;
        disabled_fields: FIELDS[];
    }

    export namespace DTO {
        export interface Create {
            disabled_fields: string[];
            name: string;
        }

        export interface Update {
            disabled_fields: string[];
        }
    }
}

// ===== /transfer ===== //
export namespace Transfer {
    export interface Root {
        id: number;
        code: string;
        number: number;
        old_quantity: number;
        new_quantity: number;
        quantity: number;
        remarks: string;
        date: dayjs.Dayjs;
        product: {
            id: number;
            name: string;
            code: string;
            in_stock: string;
        };
        location_from: {
            id: number;
            store: string;
        };
        section_from?: {
            id: number;
            name: string;
            quantity: number;
        };
        location_to: {
            id: number;
            name: string;
        };
        section_to?: {
            id: number;
            name: string;
            quantity: number;
        };
        user: {
            id: number;
            name: string;
        };
    }

    export type Extended = Root;

    export namespace DTO {
        export type OrderBy = "id" | "product" | "locationFrom" | "locationTo" | "user" | "date";

        export interface Filters {
            search?: string;
            products?: number[];
            locations?: {
                store: number,
                section?: number
            }[];
            dates_range?: {
                from: string;
                to: string;
            };
        }

        export interface Create {
            product: number;
            location_from: {
                section?: number;
                store: number;
            };
            location_to: {
                section?: number;
                store: number;
            };
            quantity: number;
            date: string;
            remarks?: string;
        }
    }
}

// ===== /adjustments ===== //
export namespace Adjustments {
    export interface Root {
        id: number;
        code: string;
        product: Product.Slim;
        adjustment_type: 0 | 1;
        remarks: string;
        date: dayjs.Dayjs;
        location?: {
            store: {
                id: number;
                name: string;
            },
            section?: {
                id: number;
                name: string;
            }
        },
        user: {
            id: number;
            name: string;
        }
    }

    export interface Extended extends Root {
        quantity_old?: number,
        quantity_actual?: number,
        cost_price_old?: number,
        cost_price_actual?: number,

        updated_selling_price?: number;
    }

    export namespace DTO {

        export type OrderBy = "id" | "product" | "location" | "user" | "type" | "date";
        export type Filters = {
            search?: string; // should search through adjustment ids, product names, remarks
            product?: number[]; // product ids
            adjustment_type?: 0 | 1; // 0 -> "quantity" adjustment and 1 -> "cost price" adjustment
            location?: {
                store: number,
                section?: number // section won't be selected only if such location has no sections connected
            }[];
            user?: number[];
            dates_range?: {
                from: string;
                to: string;
            }
        }

        export interface Create {
            product_id: number;

            // Will be ommited, when adjustment type = "purchasing price"
            location?: {
                store_id: number;
                section_id?: number;
            },

            updated_selling_price?: number;

            adjustment_type: 0 | 1,
            changed_value: number, // corresponds to "quantity" if adj_type = 0 and to "purchasing price" otherwise
            remarks: string;
        }
    }
}

// ===== /defaultLocation ===== //
export namespace DefaultLocation {
    export interface Root {
        id: number;
        name: string;
        quantity: number;
        sections?: {
            id: number;
            name: string;
            quantity: number;
            row?: number;
            sector?: number;
            shelf_height?: string;
        }[];
    }
}

// ===== /customer ===== //
export namespace Customer {
    export interface Slim {
        id: number;
        name: string;
        code: string;
    }

    export interface Root extends Slim {
        is_person: boolean;
        tripletex_id: number | null;
        poweroffice_id: number | null;
        lime_id: number | null;
        twenty_four_seven_office_id: number | null;
        contacts: Components.Contact[];
    }

    export interface Extended extends Root {
        registration_number?: string;

        language?: Language.Root;
        tax_rate?: Tax.Slim;
        discount?: number;
        payment_terms?: PaymentTerms.Root;
        delivery_terms?: DeliveryTerms.Root;
        reference?: Employee.Root;

        billing?: Components.BillingAddress;
        deliveries: Components.DeliveryAddress[];

        category_discounts?: {
            id: number;
            category: {
                id: number;
                code: string;
                name: string;
            }
            min_quantity: number;
            discount: number;
            valid_from: dayjs.Dayjs;
            valid_to: dayjs.Dayjs;
        }[];

        products?: {
            product: Product.Slim;
            sale_price: number;
            discount?: number;
            min_quantity?: number;
            valid_from?: dayjs.Dayjs;
            valid_to?: dayjs.Dayjs;
        }[];
        groups?: CustomerGroups.Extended[];
    }

    export namespace Components {
        export interface Contact {
            id: number;
            name: string;
            phone?: string;
            email?: string;
        }

        export interface CategoryDiscount {
            discounts?: {
                id?: number;
                category?: number;
                min_quantity?: number;
                discount?: number;
                valid_from?: string;
                valid_to?: string
            }[];
        }

        export interface BillingAddress {
            name: string;
            street?: string;
            street_2?: string;
            zipcode?: string;
            city?: string;
            country?: Country.Root;
            phone?: string;
            email?: string;
            is_used_for_shipping?: boolean;
        }

        export interface DeliveryAddress {
            id: number;
            name: string;
            street?: string;
            street_2?: string;
            zipcode?: string;
            city?: string;
            country?: Country.Root;
            contact_person?: string;
            phone?: string;
            email?: string;
            is_primary?: boolean;
        }

        export namespace DTO {
            export type CreateBilling = Omit<Components.BillingAddress, "country"> & { country_id?: number }
            export type CreateDelivery = Omit<Components.DeliveryAddress, "id" | "country"> & { country_id?: number }
            export type CreateCategoryDiscount = Omit<Components.CategoryDiscount, "id">
        }
    }

    export namespace DTO {
        export interface Filters {
            search?: string,
        }

        export type OrderBy = "id" | "customer_name" | "email" | "phone" | "contact_name";

        export interface Create {
            name?: string;
            code?: string;
            registration_number?: string;
            group?: number;

            is_person: boolean;

            language?: number;
            tax_rate?: number;
            discount?: number | null;
            payment_terms?: number;
            delivery_terms?: number;
            reference?: number;

            contacts?: {
                id?: number;
                name?: string;
                phone?: string | null;
                email?: string | null;
            }[];

            billing?: Omit<Nullish<Components.BillingAddress>, "country"> & { country?: number };

            deliveries?: (Omit<Nullish<Components.DeliveryAddress>, "country"> & { country?: number })[];

            products?: {
                id?: number;
                sale_price?: number;
                discount?: number;
                min_quantity?: number;
                valid_from?: string;
                valid_to?: string;
            }[];
        }

        export interface Update extends Create {
            deleted_contacts: number[];

            deleted_deliveries: number[];
        }
    }
}

// ===== /customer groups ===== //
export namespace CustomerGroups {
    export interface Root {
        id: number;
        code: string;
        name: string;
        discount: number;
    }

    export interface Extended extends Root {
        customers: Customer.Slim[];
        products?: {
            product: Product.Slim;
            sale_price: number;
            valid_from: dayjs.Dayjs;
            valid_to: dayjs.Dayjs;
        }[];
    }

    export namespace DTO {
        export interface Filters {
            search?: string,
        }

        export type OrderBy = "id" | "code" | "name" | "discount";

        export interface Create {
            code?: string;
            name: string;
            discount: number;
            customers: number[];
            products?: {
                id: number;
                sale_price: number;
                valid_from?: string;
                valid_to?: string;
            }[];
        }

        export type Update = PartialBy<Create, "code">
    }
}

// ===== /payment-terms ===== //
export namespace PaymentTerms {
    export interface Root {
        id: number;
        code: string;
        name: string;
        days: number;
        type: 0 | 1; // 0 -> days after or 1 -> prepayment
    }

    export namespace DTO {
        export type CreateUpdate = PartialBy<Omit<Root, "id">, "code">
    }
}

// ===== /delivery-terms ==== //
export namespace DeliveryTerms {
    export interface Root {
        id: number;
        code: string;
        name: string;
        description?: string;
    }

    export namespace DTO {
        export type CreateUpdate = PartialBy<Omit<Root, "id">, "code">
    }
}

// ===== /category ===== //
export namespace Category {
    export interface Root {
        id: number;
        code: string;
        name: string;
        is_service: boolean;

        integrations: {
            poweroffice: {
                sales_account_id: number;
            } | null;

            twenty_four_seven_office: {
                sales_account_id: number;
            } | null;
        };

        twenty_four_seven_office_id: number | null;
    }

    export namespace DTO {
        export type CreateUpdate =
            PartialBy<Omit<Root, "id" | "integrations" | "twenty_four_seven_office_id">, "code">
            & {
            integrations?: {
                poweroffice?: {
                    sales_account_id: number;
                },

                twenty_four_seven_office?: {
                    sales_account_id: number;
                };
            }
        }
    }
}

// ===== /purchaseOrder ===== //
export namespace PurchaseOrder {
    export interface Slim {
        id: number;
        is_editable: boolean;
        code: string;
        receive_state: 1 | 2 | 3; // 1 -> not received, 2 -> in progress, 3 -> received
        status: {
            name: OrderStatus.Base;
            meta?: {
                cancel_reason?: string | null;
            }
        };
    }

    export type Root = Slim & {
        supplier: Supplier.Slim;
        purchase_date: dayjs.Dayjs;
        documents_number: number;
        currency: Currency.Root;
        exchange_rate: number;

        lines: Components.Lines;

        reference_number?: {
            id?: number;
            code: string;
        };
    }

    export type Extended = Root & CustomFields.Embedded.Read & {
        lines: Components.Lines;
        preferred_delivery_date?: dayjs.Dayjs;
        receive_date?: dayjs.Dayjs; // the last one
        our_reference?: Employee.Root;
        their_reference?: Supplier.Components.Contact;
        payment_terms?: PaymentTerms.Root;
        delivery_terms?: DeliveryTerms.Root;
        language?: Language.Root;

        billing_address?: Company.Components.BillingAddress;
        delivery_address?: Company.Components.DeliveryAddress;
        shipping_cost?: number;
        is_split_by_products: boolean;
        min_purchase_quantity: number;
        min_sale_quantity: number;

        is_billing_for_delivery: boolean;

        documents: {
            id: number;
            path: string;
            name: string;
        }[];

        receives: {
            id: number;
            code?: string | null;
            receive_date: dayjs.Dayjs;
            is_fully_received: boolean;
            user?: {
                id: number;
                first_name: string;
                last_name: string;
                email: string;
            } | null;
        }[];
    }

    export namespace Components {
        export interface LineData {
            id: number;
            product: {
                id: number;
                name: string;
                code: string;
                has_serial_number: boolean;
                has_batch_number: boolean;
                is_service: boolean;
            };
            received_quantity: number;
            quantity: number;
            unit_price: number;
            discount?: number;
            tax?: Tax.Slim;
        }


        export interface Group {
            type: "group";
            data: {
                id: number; // ID of the entity that caused groping (bom or collection)
                name: string; // name of the group generated by the backend.
                comment?: string | null;
                module: "bom" | "collection" | "custom";
                module_entity_id?: number;
                lines: Line[];
            };
        }

        export interface Line {
            type: "line";
            data: LineData;
        }

        export type Lines = (Line | Group)[];

        export interface ExtendedLineData extends LineData {
            product: {
                id: number;
                name: string;
                code: string;
                has_serial_number: boolean;
                has_batch_number: boolean;
                is_service: boolean;
            };

            received_quantity: number;
        }

        export interface ExtendedLine {
            type: "line";
            data: ExtendedLineData;
        }

        interface ExtendedGroup {
            type: "group";
            module: "bom" | "collection";
            id: number; // ID of the entity that caused groping (bom or collection)
            is_deleted: boolean; // true if a bom / collection that makes this group was deleted.
            name: string | null; // name of the group generated by the backend. 
            data: ExtendedLineData[];
        }

        export type ExtendedLines = (ExtendedLine | ExtendedGroup)[];
    }

    export namespace DTO {
        export type OrderBy = "delivery_date" | "supplier" | "id";
        export type Filters = {
            search?: string;
            suppliers?: number[];
            products?: number[];
            receive_state?: number;
            dates_range?: {
                from: string,
                to: string
            }
        }

        export type MutationLine = {
            id?: number | null;
            product?: number,
            quantity: number,
            unit_price: number,
            discount?: number;
            tax?: number;
        }

        export type MutationGroup = {
            id?: number | null;
            module: "bom" | "collection" | "custom";
            module_entity_id?: number | null;
            name: string;
            comment?: string | null;
        }


        export interface Create extends CustomFields.Embedded.Write {
            reference_number?: string | null; // supplies and id of the "parent" sale order

            supplier: number;
            purchase_date: string;
            preferred_delivery_date?: string;
            our_reference?: number;
            their_reference?: number;
            payment_terms?: number;
            delivery_terms?: number;
            currency: number;

            shipping_cost?: number;
            is_split_by_products?: boolean;

            delivery_address?: number;

            is_billing_for_delivery: boolean;

            exchange_rate: number;

            lines: ({
                    type: "line",
                    data: MutationLine
                }
                |
                {
                    type: "group",
                    data: MutationGroup & { lines: { type: "line", data: MutationLine }[] }
                })[];
        }

        // TODO: removed "deleted_orders"
        export type Update = Omit<Create, "supplier" | "currency" | "exchange_rate" | "reference_number" | "lines">;
    }
}


// ===== /sale ===== //
export namespace SaleOrder {
    export interface Root {
        id: number;
        is_editable: boolean;
        code: string;
        customer: Customer.Root;
        is_invoiced: boolean;
        shipment_state: 0 | 1 | 2; // 0 -> not shipped, 1 -> ready, 2 -> shipped
        order_date: dayjs.Dayjs;

        status: {
            name: OrderStatus.Base;
            meta?: {
                cancel_reason?: string | null;
            }
        };

        lines: Components.Lines;

        tripletex_id: number | null;
        twenty_four_seven_office_id: number | null;
        lime_id: number | null;
        poweroffice_id: number | null;
    }

    export interface Extended extends Omit<Root, "total" | "lines"> {
        lines: Components.Lines;

        preferred_delivery_date?: dayjs.Dayjs;
        delivery_date?: dayjs.Dayjs;
        our_reference?: Employee.Root;
        their_reference?: Customer.Components.Contact;
        payment_terms?: PaymentTerms.Root;
        delivery_terms?: DeliveryTerms.Root;
        language?: Language.Root;

        billing_address: Customer.Components.BillingAddress;
        delivery_address?: Customer.Components.DeliveryAddress;

        is_billing_for_delivery: boolean;

        documents: {
            id: number;
            path: string;
            name: string;
        }[];

        pickings: {
            id: number;
            code?: string | null;
            date: dayjs.Dayjs;
            invoicing: {
                poweroffice_go: number | null;
                tripletex: number | null;
            },
            delivery_status: Picking.Components.DeliveryStatus;
            shipment_change_date: dayjs.Dayjs | null;
            is_fully_picked: boolean;
            worker?: {
                id: number;
                first_name: string;
                last_name: string;
                email: string;
            } | null;
        }[];

        // LATER WE WILL ADD HERE SHIPMENTS AND RETURNS LIST
    }

    export namespace Components {
        export interface LineData {
            id: number;
            comment?: string | null;
            product: {
                id: number;
                name: string;
                code: string;
                is_serial_numbers: boolean;
                is_batch_numbers: boolean;
                is_service: boolean;
                weight?: number | null;
                has_bom: boolean;
                prices: {
                    cost_price: number;
                    selling_price: number;
                };

                locations: {
                    location_id: number;
                    sub_location_id?: number;
                    location_name: string;
                    section_name?: string;
                    in_stock: number;
                }[];

                in_stock: number;
            };

            picked_quantity: number;
            quantity: number;
            unit_price: number;
            discount?: number;
            tax?: Tax.Slim;
        }

        export interface Group {
            type: "group";
            data: {
                id: number; // ID of the entity that caused groping (bom or collection)
                name: string; // name of the group
                comment?: string | null;
                module: "bom" | "collection" | "custom";
                module_entity_id?: number;
                lines: Line[];
            };
        }

        export interface Line {
            type: "line";
            data: LineData;
        }

        export type Lines = (Line | Group)[];
    }

    export namespace DTO {
        export type Filters = {
            search?: string;
            products?: number[];
            customers?: number[];
            order_date?: {
                from: string;
                to: string;
            };
            is_cancelled?: boolean;
        }
        export type OrderBy = "id" | "date" | "customer";

        export type MutationLine = {
            id?: number | null;
            product?: number,
            comment?: string | null;
            quantity: number,
            unit_price: number,
            tax?: number,
            discount?: number;
        }

        export type MutationGroup = {
            id?: number | null;
            module: "bom" | "collection" | "custom";
            module_entity_id?: number | null;
            name: string;
            comment?: string | null;
        }

        export interface Create {
            customer: number;
            order_date: string;
            preferred_delivery_date?: string;
            our_reference?: number;
            their_reference?: number;
            payment_terms?: number;
            delivery_terms?: number;
            billing_address?: number;
            delivery_address?: number;

            is_billing_for_delivery: boolean;

            lines: ({
                    type: "line",
                    data: MutationLine
                }
                |
                {
                    type: "group",
                    data: MutationGroup & { lines: { type: "line", data: MutationLine }[] }
                })[];
        }

        // TODO: removed "deleted_orders"
        export type Update = Omit<Create, "customer" | "lines">;
    }
}

// ==== /profile ==== //
export namespace Profile {
    export interface Root {
        first_name: string;
        last_name: string;
        email: string;
        phone?: string;
        country: string;
        language?: string;
    }

    export namespace DTO {
        export interface Update extends Root {
            current_password?: string;
            new_password?: string;
        }
    }
}

// ==== /company ==== //
export namespace Company {
    export interface Root {
        id: number;
        name: string;
        is_default: boolean;
    }

    export interface Extended extends Root {
        industry?: Industry.Root;
        country: {
            id: number;
            name: string;
            code: string;
        };
        street: string;
        street_2?: string;
        city: string;
        zipcode: string;
        phone: string;
        email: string;
        website: string;
        registration_number: {
            value: string;
            count: number;
        };
        currency: Currency.Root;
        printer_email?: string | null;

        logo?: string;

        billing_address: Components.BillingAddress;
        deliveries: Components.DeliveryAddress[];

        integrations: Integration.ActiveState.Root[];
    }

    export namespace Components {

        export interface BillingAddress {
            id: number;
            name: string;
            country: Country.Root;
            street: string;
            street_2?: string;
            zipcode: string;
            city: string;
            email: string;
            phone: string;
            contact_name: string;
            is_used_for_delivery: boolean;
        }

        export interface DeliveryAddress {
            id: number;
            name: string;
            country: Country.Root;
            street: string;
            street_2?: string;
            zipcode: string;
            city: string;
            email: string;
            phone: string;
            contact_person?: string;
            is_primary?: boolean;
        }
    }

    export namespace DTO {
        export interface Create {
            name: string;
            industry?: number | null;
            country: number;
            street: string;
            street_2?: string;
            city: string;
            zipcode: string;
            phone: string;
            email: string;
            website: string;
            registration_number: string;
            printer_email?: string | null;
            currency: number;

            logo?: File | null;

            // billing_address: Omit<Components.BillingAddress, "country"> & {country?: number};
            // deliveries: (Omit<PartialBy<Components.DeliveryAddress, "id">, "country"> & {country?: number})[];

        }

        export type Update = Omit<PartialBy<Create, "registration_number">, "currency"> & { delete_logo?: 1 | 0 };

        export interface UpdateAddresses {
            billing?: Nullish<Omit<Components.BillingAddress, "country"> & { country: number }>;
            deliveries: Nullish<Omit<Components.DeliveryAddress, "country"> & { country?: number }>[];

            deleted_deliveries?: number[];
        }

        export type CreateDelivery = Partial<Omit<Components.DeliveryAddress, "id" | "country">> & { country?: number };
        export type CreateBilling = Partial<Omit<Components.BillingAddress, "id" | "country">> & { country?: number };
    }
}

// ==== /industry ==== //
export namespace Industry {
    export interface Root {
        id: number;
        name: string;
        description: string;
    }
}

// ==== /bom ==== //
export namespace BOM {
    export interface Root {
        id: number; // BOM id
        name: string; // BOM name
        produced: number; // produced quantity
        product: { // parent product data
            id: number;
            name: string;
            code: string;
            in_stock: number;
            category: {
                id: number;
                name: string;
            };
        };
        components: { // component products
            id: number;
            product: {
                id: number;
                name: string;
                code: string;
                in_stock: number;
                prices: {
                    purchase_price: number;
                    selling_price: number;
                };
                is_service: boolean;
                supplier?: Supplier.Slim;
                is_component: boolean;
            }
            quantity: number;
            locations: {
                store: {
                    id: number;
                    name: string;
                },
                section?: {
                    id: number;
                    name: string;
                },
                in_stock?: number;
            }[];
        }[];
    }

    export type Extended = Root

    export namespace Components {
        export interface Component {
            id: number;
            product: {
                id: number;
                name: string;
                code: string;
                in_stock: number;
                // unit_price: number;
                prices: {
                    purchase_price: number;
                    selling_price: number;
                }
                is_component: boolean;
            };
            quantity: number;
            tax?: {
                id: number;
                rate: number;
                name: string;
            };
        }
    }

    export namespace DTO {
        export type OrderBy = "id" | "name" | "productName" | "categoryName" | "produced";

        export type Filters = {
            search?: string;
            productId?: number;
        }

        // POST /bom
        export interface Create {
            product_id: number;
            name: string; // BOM name
            components: {
                product_id: number;
                quantity: number;
            }[];
        }

        // PUT /bom/{id}
        export interface Update {
            name?: string; // BOM name, should be absent, if not changed
            // components will be reupdated each time the bom is updated, therefore we don't need to store `deleted_components`
            components: {
                product_id: number;
                quantity: number;
            }[];
        }
    }
}

// === /BOM history === //
export namespace BOMHistory {
    export interface Root {
        id: number;
        product: {
            id: number;
            name: string;
            code: string;
        };
        bom: {
            id: number;
            code: string;
            name: string;
        };
        serial_numbers: {
            serial_number: string;
        }[];
        batch_numbers: {
            batch_number: string;
            expiration_date?: dayjs.Dayjs;
        }[];
        quantity: number;
        date: dayjs.Dayjs;
        produce_location: {
            store: {
                id: number;
                name: string;
                code: string;
            },
            section?: {
                id: number;
                name: string;
                code: string;
            }
        };
        components: {
            product: {
                id: number;
                name: string;
                code: string;
            }
            outgoing_locations: {
                quantity: number;
                store: {
                    id: number;
                    name: string;
                    code: string;
                },
                section?: {
                    id: number;
                    name: string;
                    code: string;
                }
            }[]
        }[];
    }

    export namespace DTO {
        export type OrderBy = "bomId" | "productName" | "producedQuantity" | "date" | "locationName";

        export type Filters = {
            search?: string;
            bomId?: number;
        }

        export type Create = {
            quantity: number;
            produce_location: {
                store_id: number;
                section_id?: number;
            }
            serial_numbers?: {
                serial_number: string;
            }[];
            batch_numbers?: {
                batch_number: string;
                expiration_date?: string;
            }[];
            components_picks: {
                product_id: number;
                sources: {
                    product_id: number;
                    store_id: number;
                    section_id?: number;
                    quantity: number;
                }[];
            }[];
        }
    }
}

// ==== /receive ==== //
export namespace Receive {
    export interface Root {
        id: number;
        code: string;
        purchase_order: {
            id: number;
            code: string;
        };
        receives: Components.Lines;
        supplier: Supplier.Slim;
        date: dayjs.Dayjs;
    }

    export interface Extended extends Root {
        receives: Components.Lines;
    }

    export namespace Components {
        export interface LineData {
            id: number; // receiving id
            order: {
                id: number; // purchase order line id

                product: {
                    id: number;
                    name: string;
                    code: string;
                    has_serial_number: boolean;
                    has_batch_number: boolean;
                },

                total_quantity: number;
            },

            // fields, that should be specified on receiving creation
            location: {
                store: {
                    id: number;
                    name: string;
                },
                section?: {
                    id: number;
                    name: string;
                }
            },

            received_quantity: number;

            serial_numbers?: {
                serial_number: string;
            }[]; // if serial number has been specified during receiving
            batch_numbers?: {
                batch_number: string;
                expiration_date?: dayjs.Dayjs;
            }[]; // if batch number has been specified during receiving
        }

        export interface Line {
            type: "line";
            data: LineData;
        }

        export interface GroupLine {
            type: "group";
            data: {
                id: number;
                name: string;
                module?: "bom" | "collection" | "custom";
                module_entity_id?: number;
                comment?: string | null;
                lines: Line[];
            };
        }

        export type Lines = (Line | GroupLine)[];
    }

    export namespace DTO {
        export type OrderBy = "id" | "supplier" | "date";
        export type Filters = {
            search?: string,
            suppliers?: number[],  // ids
            dates_range?: {
                from: string;
                to: string;
            }
        }

        export interface Create {
            supplier: number;
            date: string;
            purchase_order_id: number;
            receives: {
                product_order_id?: number; // id of order inside the po
                location?: { // won't be present, when the product is service
                    store: number,
                    section?: number
                },

                serial_numbers?: {
                    serial_number: string;
                }[]; // size = N received_quantity
                batch_numbers?: {
                    batch_number: string;
                    expiration_date?: string;
                }[];

                received_quantity: number;
            }[];
        }
    }
}

// ==== /picking ==== //
export namespace Picking {
    export interface Root {
        id: number;
        code: string;
        order: {
            id: number;
            code: string;
            customer: Customer.Slim;
        };
        invoicing: {
            slug: string;
            name: string;
            logo: string;
            link: string;
        }[];
        delivery_status: "not_shipped" | "in_progress" | "delivered";
        shipment_change_date: dayjs.Dayjs | null;
        has_delivery_note: boolean;
        date: dayjs.Dayjs;
        lines: Components.Lines;
        packages: {
            id: number;
            package: {
                id?: number;
                name?: string;
                code?: string;
                length: number;
                width: number;
                height: number;
            }
            weight: number;
        }[];
        shipmondo?: {
            id: number | null;
            carrier: {
                code: string;
                name: string;
            },
            product: {
                code: string;
                name: string;
            },
            services: {
                code: string;
                name: string;
            }[]
        };
    }

    export namespace Components {
        export type InvoiceProvider = "poweroffice_go" | "unimicro";
        export type DeliveryStatus = "not_shipped" | "in_progress" | "delivered";

        export interface LineData {
            id: number;
            product: {
                id: number;
                name: string;
                code: string;
                quantity: number; // ordered quantity
                is_serial_numbers: boolean;
                is_batch_numbers: boolean;
                is_service: boolean;
            };

            picks: {
                outgoing_location: {
                    store: {
                        id: number;
                        name: string;
                    },
                    section?: {
                        id: number;
                        name: string;
                    }
                },
                picked_quantity: number;
            }[];

            serial_numbers?: {
                serial_number: string;
            }[]; // if serial number has been specified during picking

            batch_numbers?: {
                batch_number: string;
                expiration_date?: dayjs.Dayjs;
            }[];
        }

        export interface Line {
            type: "line";
            data: LineData;
        }

        export interface GroupLine {
            type: "group";
            data: {
                id: number;
                name: string;
                module?: "bom" | "collection" | "custom";
                module_entity_id?: number;
                comment?: string | null;
                lines: Line[];
            };
        }

        export type Lines = (Line | GroupLine)[];

        export namespace Package {
            export interface Reference {
                id: number;
            }

            export interface Custom {
                width: number;
                length: number;
                height: number;
            }

            export type Config = {
                package: (Components.Package.Reference | Components.Package.Custom);
                weight?: number | null;
            }
        }
    }

    export type Extended = Root;

    export namespace DTO {
        export type Filters = {
            search?: string;
            customers?: number[];
            dates_range?: {
                from: string;
                to: string;
            };
            invoicing?: "not_invoiced" | "invoiced";
        }

        export type OrderBy = "id" | "customer" | "date" | "invoicing";

        export interface Create {
            sale_order_id: number;
            date: string;
            lines: {
                sale_order_line_id?: number | null; // sale order line id (product)
                product_id?: number;

                locations?: { // will be absent if product is service
                    location_id: number;
                    sub_location_id?: number;
                    picked_quantity: number;
                }[];

                picked_quantity?: number; // used for services

                serial_numbers?: {
                    serial_number: string;
                }[];

                batch_numbers?: {
                    batch_number: string;
                    expiration_date?: string;
                }[];
            }[],

            packages?: Components.Package.Config[],

            // Shipmondo
            shipmondo?: {
                outgoing_country: string;
                fallback_destination_country: string;
                carrier_code?: string;
                product_code?: string,
                service_codes?: string
            }
        }
    }
}

// ==== /restocking ==== //
export namespace Restocking {
    export interface Root {
        id: number;
        in_stock: number;
        min_inventory_quantity: number;
        min_purchase_quantity: number;
        min_sale_quantity: number;
        product: {
            id: number;
            name: string;
            code: string;
        },
        location: {
            store: {
                id: number;
                name: string;
            },
            section?: {
                id: number;
                name: string;
            }
        }
    }
}

// ==== /upcoming-shipment ==== //
export namespace UpcomingShipment {
    export interface Root {
        id: number;
        code: string;
        delivery_date: dayjs.Dayjs;
    }
}

// ==== /country ==== //
export namespace Country {
    export interface Root {
        id: number;
        name: string;
        code: string;
        dial_code: string;
    }
}

export namespace Country {
    export interface Root {
        id: number;
        name: string;
        dial_code: string;
        code: string;
    }
}

// ==== /roles ==== //
export namespace Role {
    export interface Root {
        id: number;
        name: string;
        description?: string;
        immutable: boolean;
        users: {
            id: number;
            first_name: string;
            last_name: string;
        }[],
    }

    export interface Extended extends Root {
        permissions: Permission.Root;
    }

    export namespace DTO {
        export interface Create {
            name: string;
            description?: string;
            permissions: string[];
        }

        export type Update = Create;

        export type Delete = {
            new_role_id?: number | null; // will be absent, if role is not assigned to any user
        }

        export type Copy = {
            name: string;
            description?: string;
            company_id?: number;
        }
    }
}

// ==== /permissions ==== //
export namespace Permission {
    export type Root = Record<Components.Modules, {
        label: string;
        key: string;
        permissions: Record<"view" | "create" | "edit" | "delete", Components.Ability>;
        subPermissions: Record<string, Components.Ability>;
    }>

    export namespace Components {
        export type Modules =
        // => Contacts
            | "general" // Common
            | "product" // Product
            | "customer" // Customers
            | "sale_order" // Sales orders
            | "picking" // Picking
            | "supplier" // Suppliers
            | "purchase_order" // Purchase orders
            | "receive" // Receive
            | "adjustment" // Adjustments
            | "stock" // Stock
            | "stock_count" // Stock count
            | "collection" // Collection
            | "transfer" // Transfer
            | "custom_fields" // Custom fields

        type SimplestAbility = {
            label: string;
            key: string;
            dependency?: string;
            checked?: boolean;
        }

        export type Ability = SimplestAbility & {
            options?: Record<string, SimplestAbility>
            dependency?: string;
        }
    }
}

// ==== /SocketRoleUpdated ==== //
export interface SocketRoleUpdated {
    id: number;
    name: string;
    description?: string | null;
    permissions: {
        module_key: Permission.Components.Modules;
        abilities: {
            key: string;
        }[];
    }[];
}

// === /integrations === //
export namespace Integration {
    export interface Root {
        limit: number;
        current: number;
        integrations: Components.Integration[];
    }

    export namespace Components {
        export interface Integration {
            id: number;
            integrated: boolean;
            name: Components.Name;
            slug: Components.Slug;
            imageUrl: string;
            description: string;
            integrationType: Components.Type;
            modal: {
                fields: Components.Field[];
            };
        }

        export type Name = "PowerOffice GO" | "Shipmondo" | "Lime" | "Tripletex" | "24SevenOffice";
        export type Slug = "poweroffice" | "shipmondo" | "lime" | "tripletex" | "twenty_four_seven_office";
        export type Type = "accounting" | "shipping";

        export type Field = {
            name: string;
            label: string;
            placeholder: string;
            required: boolean;
        }
    }

    export namespace ActiveState {
        export interface Root {
            id: number;
            name: string;
            slug: Components.Slug;
        }

        export interface PowerOfficeGO extends Root {
            projects_support: boolean;
        }

        export interface Tripletex extends Root {
            projects_support: boolean;
        }
    }

    export namespace DTO {
        export type Create = Record<string, string>; // supplying fields
    }
}

// ===== /collection ===== //
export namespace Collection {
    export interface Root {
        id: number;
        code: string;
        name: string;
        barcode?: string;
        totalQuantity: number;
        products: Components.Product[];
    }

    export namespace Components {
        export interface Product {
            id: number;
            code: string;
            name: string;
            quantity: number;
            is_component: boolean;
            in_stock: number;
            is_service: boolean;
            has_bom: boolean;
            prices: {
                purchase_price: number;
                selling_price: number;
                exchange_rate: number;
            };
            min_purchase_quantity: number;
            min_sale_quantity: number;
            supplier?: Supplier.Slim;
            tax: {
                id: number;
                rate: number;
            };
        }
    }

    export namespace DTO {
        export type OrderBy = "id" | "name" | "quantity";

        export type Filters = {
            search?: string;
        };

        export interface Create {
            code?: string;
            barcode?: string | null;
            products: {
                id: number;
                quantity: number;
            }[];
            name: string;
        }

        export type Update = Partial<Omit<Create, "code">>;
    }
}

// ==== /revenue ==== //
export namespace Revenue {
    export type Root = {
        name: string;
        expenses: number;
        income: number;
    }

    export namespace Components {
        export type Mode = "month" | "year";
    }

    export namespace DTO {
        export type Filters = {
            mode: Components.Mode;
        }
    }
}

// ==== /feed ==== //
export namespace Feed {
    export interface Root {
        id: number;
        date: dayjs.Dayjs;
        event: {
            module: Permission.Components.Modules;
            meta: {
                id: number;
                code: string;
            }
            // > meta will be extended in the future to provide locations for the transfers, product names, responsible people, etc. For actions we can omit invoice and deliver for now.
            action: Permission.Components.Ability;
        };
    }

    export namespace DTO {
        export interface Filters {
            search?: string;
        }
    }
}

// ==== /best-selling ==== //
export namespace BestSelling {
    export interface Root {
        name: string;
        value: number;
    }
}

// ==== /total ==== //
export namespace TotalStat {
    export interface Root {
        total_revenue: number | null;
        total_in_stock: number | null;
    }
}


// === /poweroffice === //
export namespace PowerOffice {
    export namespace SalesAccounts {
        export interface Root {
            id: number;
            code: number;
            name: string;
        }
    }

    export namespace Projects {
        export interface Root {
            id: number;
            name: string;
        }
    }

    export namespace DTO {
        export interface UpdateConfiguration {
            projects_support: boolean;
        }
    }
}

// === /tripletex === //
export namespace Tripletex {
    export namespace Components {
        export type AvailableEntities = "customer" | "supplier" | "product" | "sale_order";
    }

    export namespace Projects {
        export interface Root {
            id: number;
            name: string;
            number: string;
            isClosed: boolean;
        }
    }

    export namespace DTO {
        export interface Import {
            entities: Components.AvailableEntities[];
            default_product_location?: {
                store_id: number;
                section_id?: number;
            };
        }

        export interface UpdateConfiguration {
            projects_support: boolean;
        }
    }
}

// === /twentyFourSevenOffice === //
export namespace TwentyFourSevenOffice {
    export namespace Components {
        export type AvailableEntities = "customer" | "supplier" | "product" | "sale_order" | "category";
    }

    export namespace SalesAccounts {
        export interface Root {
            id: number;
            name: string;
            number: number;
        }
    }

    export namespace DTO {
        export interface Import {
            entities: Components.AvailableEntities[];
            default_product_location?: {
                store_id: number;
                section_id?: number;
            };
        }
    }
}

// === /import === //
export namespace Import {
    export interface Root {
        module: string;
        current: number;
        total: number;
        errors: number;
        status: string;
        fileId: string | null;
        payload: {
            hash: string;
            headers: string[];
            rows: number;
        };
    }

    export namespace DTO {
        export interface Create {
            module: string;
            formData: FormData;
            // fields: string[];
            // fileHash: string;
            // file: File;
        }
    }
}

// === /shipmondo === //
export namespace Shipmondo {
    export namespace Carriers {
        export interface Root {
            id: number;
            name: string;
            code: string;
        }
    }

    export namespace Products {
        export interface Root {
            id: number;
            name: string;
            code: string;
            available_services: {
                id: number;
                name: string;
                code: string;
            }[];
            required_services: {
                id: number;
                name: string;
                code: string;
            }[];
        }
    }
}

export namespace DFS {
    export interface DataTransferStarted {
        operation: Components.DataTransferOperations;
        module: string;
    }

    export interface DataTransferProgressUpdate {
        operation: Components.DataTransferOperations;
        module: string;
        current: number[];
        total: number[];
        errors: number;
    }

    export interface DataTransferFinished<T = never> {
        operation: Components.DataTransferOperations;
        module: string;
        success: boolean;
        data: T;
    }

    export namespace Components {
        export type DataTransferOperations = "import" | "export" | "price_reconversion" | "stock_count_approval";
    }
    
    export namespace Operations{
        export namespace Import {
            export type  Modules = "product" | "stock";
            export type  Column = {
                key: string;
                title: string;
                required: boolean;
                is_code?: boolean | null;
            }
        }
        
        export namespace Export {
           export type Modules =
               "product"
               | "transfer"
               | "stock_count"
               | "stock"
               | "sale_order"
               | "purchase_order";
           
           export type Column = {
               key: string;
               title: string;
           }
        }
    }
}

// ==== /custom-fields ==== //
export namespace CustomFields {
    export type Root = Components.Field;

    export namespace Components {
        export interface Field {
            id: number;
            label: string;
            placeholder: string;
            module: Modules;
            // disabled flag = deleted
            is_disabled: boolean;
        }

        export type Modules = Permission.Components.Modules;
    }

    export namespace DTO {
        export interface Create {
            module: Components.Modules;
            label: string;
            placeholder: string;
        }

        export type Update = PartialBy<Create, "module">;
    }

    export namespace Embedded {
        export type Read = {
            custom_fields: ({
                value: string;
            } & CustomFields.Components.Field)[];
        }
        export type Write = {
            custom_fields: {
                id: number;
                value: string;
            }[];
        }
    }
}

export namespace OrderStatus {
    export enum Base {
        new = "new",
        in_progress = "in_progress",
        completed = "completed",
        cancelled = "cancelled"
    }
}