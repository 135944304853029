import React, { memo, useEffect } from "react";
import ImportIndicator from "./ImportIndicator";
import ExportIndicator from "./ExportIndicator";
import PriceReconversionIndicator from "./PriceReconversionIndicator";
import StockCountIndicator from "./StockCountIndicator";
import { ability } from "@/casl.config";
import { useEchoEffect } from "@helpers/utils";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { useGetCurrentDataTransferProcessQuery } from "@/redux/features/dfs/dfsApi";
import { finishOperation, startOperation, updateOperationProgress } from "@/redux/features/dfs/dfsSlice";
import { DFS } from "@/types/general";
import { invalidateModuleCache } from "@components/DashboardNav/cacheUtils";

const channel = "data_transfer";

export const Indicators = memo(() => {
    const dispatch = useAppDispatch();

    const { data: currentDataTransferProcess } = useGetCurrentDataTransferProcessQuery();

    const company = useAppSelector(state => state.auth.user?.company);

    useEffect(() => {
        const module = currentDataTransferProcess?.module;

        if (module) {
            dispatch(startOperation({
                operation: currentDataTransferProcess?.operation,
                module: module
            }));
        }
    }, [currentDataTransferProcess]);

    useEchoEffect<DFS.DataTransferStarted>(
        `${channel}.${company?.id ?? -1}`,
        "DataTransfer\\DataTransferStarted",
        (startData) => {
            dispatch(startOperation({ operation: startData.operation, module: startData.module }));
        },
        undefined,
        [company]
    );

    useEchoEffect<DFS.DataTransferProgressUpdate>(
        `${channel}.${company?.id ?? -1}`,
        "DataTransfer\\DataTransferUpdated",
        (updateData) => {
            dispatch(updateOperationProgress({
                operation: updateData.operation,
                current: updateData.current,
                total: updateData.total
            }));
        },
        undefined,
        [company]
    );

    useEchoEffect<DFS.DataTransferFinished>(
        `${channel}.${company?.id ?? -1}`,
        "DataTransfer\\DataTransferFinished",
        (finishData) => {
            console.log("Finishing operation", finishData);
            dispatch(finishOperation({
                operation: finishData.operation,
                has_errors: !finishData.success,
                data: finishData.data
            }));

            if (finishData.operation in ["import", "export", "price_reconversion"]) {
                invalidateModuleCache(finishData.module, dispatch);
            }
        },
        undefined,
        [company]
    );

    return <div className={"flex items-center space-x-2"}>
        <ImportIndicator />
        <ExportIndicator />
        {ability.can("price.reconversion", "product") && (
            <PriceReconversionIndicator />
        )}
        <StockCountIndicator />
    </div>;
});
