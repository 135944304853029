import React, { forwardRef } from "react";
import { DivBasedComponentProps } from "@reusables/BaseTableV2/types";
import { cn } from "@helpers/utils";

type CellProps = DivBasedComponentProps & {
    maxSize?: number;
    minSize?: number;
    size?: number;
};

const Cell = forwardRef<HTMLDivElement, CellProps>(function Cell({ children, ...props }, ref) {
    return (
        <div {...props} className={cn("flex-1 basis-0 px-[16px] py-[6px]", props.className)} ref={ref} style={{
            minWidth: props.minSize ? `${props.minSize}px` : "unset",
            maxWidth: props.maxSize ? `${props.maxSize}px` : "unset",
            width: props.size ? `${props.size}px !important` : "unset"
        }}>
            {children}
        </div>
    );
});

export default Cell;