import BaseIntegrationInject from "@reusables/BaseIntegrationInject";
import React, { CSSProperties, FC } from "react";
import Select, {
    MultiValueGenericProps,
    OnChangeValue,
    OptionsOrGroups,
    StylesConfig
} from "react-select";
import { GroupedOption, isGroupedOption, ProjectOption } from "./utils";
import { CustomOption } from "./parts/CustomOption";
import {ReactComponent as DropdownIcon} from "@assets/icons/ic_expand_down.svg";
import { components } from "react-select";
import { ReactComponent as CrossSVG } from "@assets/icons/ic_cross.svg";
import BaseTooltip from "@reusables/BaseTooltip";
import { useTranslation } from "react-i18next";

const TruncatedMultiValueLabel: React.FC<MultiValueGenericProps<ProjectOption, true, GroupedOption>> = (props) => {
    const { label } = props.data;
    return (
        <components.MultiValueLabel {...props}>
            <BaseTooltip title={label} placement="bottom">
                <div
                    style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: 240,
                      }}
                >
                    {label}
                </div>
            </BaseTooltip>
        </components.MultiValueLabel>
    );
};

const MultiValueRemove = (props: any) => (
    <components.MultiValueRemove {...props}>
        <CrossSVG />
    </components.MultiValueRemove>
);

const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <DropdownIcon className="dropdown-icon" />
        </components.DropdownIndicator>
    );
};

const customStyles: StylesConfig<ProjectOption, true, GroupedOption> = {
    control: (provided, state) => ({
        ...provided,
        border: "1px solid",
        backgroundColor: "#fff",
        borderColor: state.isFocused ? "#7556FA !important" : "rgb(217 218 253 / 1) !important",
        borderRadius: 8,
        padding: "12px 16px",
        minHeight: "58px",
        boxShadow: "none",
        cursor: "pointer",
        outline: "none",
    }),
  
    valueContainer: (provided) => ({
        ...provided,
        display: "flex",
        flexWrap: "wrap",
        padding: 0,
        gap: 0,
    }),
  
    input: (provided) => ({
        ...provided,
        margin: 2,
        padding: 0,
        color: "#3C3769",
        fontWeight: 400,
        height: "28px",
        border: 0,
        boxShadow: "none",
        "&:focus": {
            outline: "none",
        },
    }),
  
    placeholder: (provided) => ({
        ...provided,
        color: "#D9D6DA",
        fontWeight: 100,
    }),
  
    dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: 0,
        color: "#7556FA",
        transform: state.isFocused ? "rotate(180deg)" : "none",
        transition: ".25s",
        "& > svg": {
            width: 16,
            height: 16,
        },
    }),
  
    indicatorSeparator: () => ({
        display: "none",
    }),
  
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: "#F3F2EF",
        padding: "0 8px !important",
        color: "#3C3769",
        borderRadius: 4,
        height: "28px !important",
        alignItems: "center",
        margin: 2,
    }),
  
    multiValueLabel: (provided) => ({
        ...provided,
        color: "#3C3769",
        padding: "0 !important"
    }),
  
    multiValueRemove: (provided, state) => ({
        ...provided,
        display: "flex",
        justifyContent: "flex-end",
        cursor: "pointer",
        border: "none",
        outline: "none",
        padding: "0 !important",
        marginLeft: "4px !important",
        "& svg": {
            display: "flex",
        justifyContent: "flex-end",
            height: "15px",
            width: "15px",
            transform: "scale(.7)",
            marginTop: "-1px",
            color: "#3C3769",
        },
        "&:hover": {
            backgroundColor: "#F3F2EF",
        },
    }),
  
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
        marginTop: 0,
        borderRadius: 10,
        boxShadow: "-6px 5px 33px 0px rgba(59, 65, 208, 0.11)",
        overflow: "hidden",
    }),
  
    menuList: (provided) => ({
        ...provided,
        padding: 14,
        maxHeight: 250,
        }),
  
    option: (provided, state) => ({
        ...provided,
        padding: "10px 16px",
        color: "#3C3769",
        borderRadius: 8,
        backgroundColor: state.isFocused
            ? "rgb(243, 242, 239)"
            : "#fff",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        "& .selected-svg": {
            marginLeft: "auto",
            color: "#7556FA",
            opacity: state.isSelected ? 1 : 0,
        },
    }),
  };


interface ProjectsDropdownProps {
    /**
     * The array of grouped project options passed to the dropdown.
     */
    groupedOptions: OptionsOrGroups<ProjectOption, GroupedOption>;

    /**
     * Currently selected projects in the dropdown.
     */
    selectedProjects: ProjectOption[];

    /**
     * A setter function for updating the selectedProjects state in the parent.
     */
    setSelectedProjects: React.Dispatch<React.SetStateAction<ProjectOption[]>>;

    /**
     * Optional label text for the dropdown.
     * @default "Projects"
     */
    label?: string;

    /**
     * Placeholder text to show when no option is selected.
     * @default "Select projects"
     */
    placeholder?: string;
    /**
     * Whether the dropdown is in a loading state.
     * @default false
     */
        isLoading?: boolean;
    /**
     * Whether the label should be bright or dim.
     * @default false
     */
    brightLabel?: boolean;
}

/**
 * Styles for grouping label wrapper.
 */
const groupStyles: CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 16,
    textTransform: "capitalize"
};

/**
 * Styles for group badge label count (number of items in group).
 */
const groupBadgeStyles: CSSProperties = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center"
};

/**
 * Formats the group label for the select component.
 *
 * @param data GroupedOption object containing a label and nested project options.
 */
const formatGroupLabel = (data: GroupedOption) => (
    <div style={groupStyles}>
        <div className="flex items-center space-x-2">
            {data.options[0].integration === "tripletex" ? (
                <BaseIntegrationInject integration={"tripletex"}>
                    {
                        (integration, isLoading) =>
                            !isLoading ?
                                <img src={integration?.imageUrl} width={20} height={20}
                                        alt={integration?.name} /> : null
                    }
                </BaseIntegrationInject>
            ) : (
                <BaseIntegrationInject integration={"poweroffice"}>
                    {
                        (integration, isLoading) =>
                            !isLoading ?
                                <img src={integration?.imageUrl} width={20} height={20}
                                        alt={integration?.name} /> : null
                    }
                </BaseIntegrationInject>
            )}

            <span className="text-accent">{data.label}</span>
        </div>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);

const ProjectsDropdown: FC<ProjectsDropdownProps> = ({
    groupedOptions,
    selectedProjects,
    setSelectedProjects,
    label,
    placeholder,
    isLoading,
    ...props
}) => {
    const { t } = useTranslation("", { keyPrefix: "inventory.stock.modals.filter.fields.projects" });

    const handleChange = (
        selected: OnChangeValue<ProjectOption, true>
    ) => {
        setSelectedProjects(selected as ProjectOption[]);
    };

    /**
     * Process groupedOptions: for any group with an empty options array,
     * inject a dummy disabled option to display a "No projects found" message.
     */
    const processedGroupedOptions = groupedOptions.map((item) => {
        if (isGroupedOption(item)) {
          return {
            ...item,
            options:
              item.options && item.options.length > 0
                ? item.options
                : ([
                    {
                        label: t("noProjectsFound"),
                        id: -1,
                        // Derive integration based on the group label.
                        integration:
                            item.label.toLowerCase() === "tripletex"
                            ? "tripletex"
                            : "poweroffice",
                        isDisabled: true,
                    } as ProjectOption,
                  ] as ProjectOption[]),
          };
        }
        return item;
    });

    return (
        <div>
            <div className={`mb-2 font-semibold${props.brightLabel ? " text-inputs-label-bright" : " text-inputs-label-dim"}`}>{label}</div>
            <Select
                styles={customStyles}
                options={processedGroupedOptions}
                getOptionValue={(option) => `${option['id']}`}
                isMulti
                placeholder={placeholder}
                formatGroupLabel={formatGroupLabel}
                onChange={handleChange}
                value={selectedProjects}
                menuPosition="absolute"
                isLoading={isLoading}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                    Option: CustomOption,
                    DropdownIndicator,
                    MultiValueLabel: TruncatedMultiValueLabel,
                    MultiValueRemove,
                    ClearIndicator: () => null,
                }}
                noOptionsMessage={() => t("noProjectsFound")}
            />
        </div>
    );
};

export default ProjectsDropdown;