import React, { useEffect } from 'react'
import { useAppDispatch } from '@redux/hooks';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { quickPurchaseOrderScheme, QuickPurchaseOrderSchemeTyping } from './types';
import { Controller, FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetBOMQuery } from '@redux/features/bom/bomApi';
import BaseModal from '@reusables/Modals/BaseModal';
import BaseInputsGrid from '@reusables/BaseInputsGrid';
import BaseInput from '@reusables/BaseInput';
import BaseButton from '@reusables/BaseButton';
import BOMComponents from './parts/BOMComponents';
import { addFromBOM } from '@redux/features/purchases/purchasesSlice';
import { BaseLoadingBlocker } from '@/components/reusables/blockers/BaseLoadingBlocker';

interface QuickPurchaseOrderModalProps {
    isOpen: boolean;
    onClose: () => void;
    selectedBOM?: number;
}

export default function QuickPurchaseOrderModal({ isOpen, onClose, selectedBOM }: QuickPurchaseOrderModalProps) {
    const { t } = useTranslation("", { keyPrefix: "bom.createPOModal" });
    const history = useHistory();
    const dispatch = useAppDispatch();

    const { data: BOM, isLoading: isBOMLoading } = useGetBOMQuery(selectedBOM as number, {skip: !selectedBOM});

    const form = useForm<QuickPurchaseOrderSchemeTyping>({
        resolver: zodResolver(quickPurchaseOrderScheme),
        defaultValues: {
            selected_bom: {
                quantity: 1,
                components: []
            }
        }
    });

    const { fields: components, replace } = useFieldArray({
        control: form.control,
        name: "selected_bom.components",
        keyName: "loop_id"
    });

    useEffect(() => {
        if (BOM) {
            const mappedComponents = BOM.components.map((component) => ({
                product: {
                    id: component.product.id,
                    name: component.product.name,
                    code: component.product.code,
                    unit_price: component.product.prices.purchase_price,
                    in_stock: component.product.in_stock
                },
                quantity: component.quantity,
                selected: true
            }));

            form.reset({
                selected_bom: {
                    id: BOM.id,
                    name: BOM.name,
                    quantity: undefined,
                    components: mappedComponents
                }
            });

            replace(mappedComponents);
        }
    }, [BOM]);

    const BOMQuantity = useWatch({
        control: form.control,
        name: "selected_bom.quantity"
    });

    useEffect(() => {
        if (BOMQuantity) {
            components.forEach((_, index) => {
                const quantity = (BOM?.components[index]?.quantity ?? 0) * BOMQuantity;
                form.setValue(`selected_bom.components.${index}.quantity`, quantity);
            });
        }
    }, [BOMQuantity]);

    const onSubmit = form.handleSubmit((data) => {
        const selectedComponents = data.selected_bom.components.filter((component) => component.selected);

        const components = selectedComponents.map((component) => ({
            product: component.product,
            quantity: component.quantity
        }));

        const payload = {
            bom_id: BOM?.id,
            name: BOM?.name,
            quantity: BOMQuantity,
            components
        }

        dispatch(
            addFromBOM({
                bom: {
                    id: payload.bom_id as number,
                    name: payload.name as string,
                },
                components: payload.components.map((component) => ({
                    product: {
                        id: component.product.id,
                        name: component.product.name,
                        code: component.product.code
                    },
                    quantity: component.quantity,
                    unit_price: component.product.unit_price
                }))
            })
        );

        history.push("/dashboard/purchasing/new");
    })

    return (
        <BaseLoadingBlocker active={isBOMLoading}>
            <BaseModal
                isOpen={isOpen}
                onClose={onClose}
                padding="56px"
                width={800}
                useCloseIcon
            >
                <FormProvider {...form}>
                    <form className="space-y-8" onSubmit={onSubmit}>
                        <div className="modal-title">{t("title")}</div>
                        <BaseInputsGrid cols={2}>
                            <Controller
                                control={form.control}
                                name="selected_bom.name"
                                render={({ field }) => (
                                    <BaseInput
                                        label={t("fields.bomName")}
                                        {...field}
                                        disabled
                                    />
                                )}
                            />

                            <Controller
                                control={form.control}
                                name="selected_bom.quantity"
                                render={({ field, fieldState }) => (
                                    <BaseInput
                                        label={t("fields.quantity")}
                                        {...field}
                                        error={fieldState.error}
                                        type='number'
                                    />
                                )}
                            />
                        </BaseInputsGrid>

                        <div className="space-y-4">
                            <div className="text-accent font-thin">{t("components")}</div>

                            {components.map((component, index) => (
                                <BOMComponents key={component.loop_id} index={index} />
                            ))}
                        </div>

                        <BaseButton
                            type="submit"
                            className="w-full"
                            text={t("buttons.continue")}
                            size="md"
                        />
                    </form>
                </FormProvider>
            </BaseModal>
        </BaseLoadingBlocker>
    )
}
