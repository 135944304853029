import React, { useEffect } from "react";
import { PurchaseOrder } from "@/types/general";
import { FormProvider, useForm } from "react-hook-form";
import { LineGroupUnion, PurchaseOrdersFormTyping, purchaseOrdersScheme } from "./types";
import { zodResolver } from "@hookform/resolvers/zod";
import GeneralSection from "./parts/GeneralSection";
import ReferencesSection from "./parts/ReferencesSection";
import CurrencySection from "./parts/CurrencySection";
import BaseButton from "@reusables/BaseButton";
import AddressSection from "./parts/AddressSection";
import ProductSection from "./parts/ProductSection";
import dayjs from "dayjs";
import { useGetModuleCurrenciesQuery } from "@redux/features/currencies/currenciesApi";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { emptyFromBOM, emptyFromSO, emptyQPO } from "@redux/features/purchases/purchasesSlice";
import { normalizePrice } from "@helpers/utils";
import OrderReference from "./parts/OrderReference";
import LanguageSection from "./parts/LanguageSection";
import BaseCustomFieldsSection from "@reusables/BaseCustomFieldsSection";
import { isGroupOrder } from "../../types";

interface MutationLayoutProperties {
    purchaseOrder: PurchaseOrder.Extended | undefined;
    onSubmit: (args: PurchaseOrdersFormTyping) => void;
}

export default function MutationLayout(props: MutationLayoutProperties) {
    const {
        data: currenciesOptions = [],
        isLoading: isCompanyCurrenciesOptionsLoading
    } = useGetModuleCurrenciesQuery("purchase-order");
    const baseCurrency = currenciesOptions.find(c => c.isBase);

    const dispatch = useAppDispatch();
    const transitOrders = useAppSelector(state => state.purchases.QPO);
    const fromSOOrders = useAppSelector(state => state.purchases.fromSO);
    const fromBOMOrders = useAppSelector(state => state.purchases.fromBOM);

    const form = useForm<PurchaseOrdersFormTyping>({
        resolver: zodResolver(purchaseOrdersScheme),
        defaultValues: {
            mode: "creation",
            is_editable: true,
            is_billing_for_delivery: false,
            purchase_date: dayjs(),
            lines: [],
            is_split_by_products: false,
            deleted_lines: [],
            reference_number: {}
        }
    });

    useEffect(() => {
        if (props.purchaseOrder) {
            const order = props.purchaseOrder;

            form.setValue("mode", "editing");
            form.setValue("is_editable", order.is_editable);

            form.setValue("supplier", order.supplier);
            form.setValue("purchase_date", order.purchase_date);
            form.setValue("preferred_delivery_date", order.preferred_delivery_date);
            form.setValue("our_reference", order.our_reference);
            form.setValue("their_reference", order.their_reference);
            form.setValue("payment_terms", order.payment_terms);
            form.setValue("delivery_terms", order.delivery_terms);
            form.setValue("language", order.language);
            if (order.billing_address)
                form.setValue("billing_address", {
                    name: order.billing_address.name,
                    street: order.billing_address.street,
                    city: order.billing_address.city,
                    country: order.billing_address.country
                });
            form.setValue("delivery_address", order?.delivery_address);

            form.setValue("is_billing_for_delivery", order.is_billing_for_delivery);
            form.setValue("currency", order.currency);
            form.setValue("exchange_rate", order.exchange_rate);
            form.setValue("shipping_cost", order.shipping_cost);
            form.setValue("is_split_by_products", order.is_split_by_products);

            form.setValue("custom_fields", order.custom_fields ?? []);

            const combinedLines: LineGroupUnion[] = props.purchaseOrder.lines.map((line, index) => {
                if (line.type === "line") {
                    return {
                        ...line.data,
                        unit_price: {
                            converted: normalizePrice(line.data.unit_price),
                            original: normalizePrice(line.data.unit_price / order.exchange_rate)
                        }
                    };
                } else {
                    return {
                        id: line.data.id,
                        name: line.data.name,
                        comment: line.data.comment,
                        module: line.data.module,
                        module_entity_id: line.data.module_entity_id,
                        lines: line.data.lines.map(groupLine => ({
                            ...groupLine.data,
                            unit_price: {
                                converted: normalizePrice(groupLine.data.unit_price),
                                original: normalizePrice(groupLine.data.unit_price / order.exchange_rate)
                            }
                        }))
                    };
                }
            });

            form.setValue("lines", combinedLines as PurchaseOrdersFormTyping["lines"]);

            !!order.reference_number &&
            form.setValue("reference_number", order.reference_number);

            form.setValue("deleted_lines", []);
        }
    }, [props.purchaseOrder]);

    // // Needed for QUICK PUCHASE ORDER feature, used, for example, on restocking
    useEffect(() => {
        if (transitOrders.orders.length) {
            form.setValue("lines", transitOrders.orders.map(line => ({
                ...line,
                unit_price: {
                    converted: normalizePrice(line.unit_price ?? 0),
                    original: normalizePrice(line.unit_price ?? 0)
                }
            })));

            if (transitOrders.meta.supplier)
                form.setValue("supplier", transitOrders.meta.supplier);

            dispatch(emptyQPO());
        }
    }, [transitOrders]);

    // // Needed for "PO FROM SO" feature, used, when sold products are low on stock
    useEffect(() => {
        if (fromSOOrders?.lines.length && fromSOOrders?.order) {
            form.setValue("lines", fromSOOrders.lines.map(line => {
                if (isGroupOrder(line)) {
                    return {
                        ...line,
                        lines: line.lines.map(groupLine => ({
                            ...groupLine,
                            unit_price: {
                                converted: normalizePrice(groupLine.unit_price ?? 0),
                                original: normalizePrice(groupLine.unit_price ?? 0)
                            }
                        })
                    )};
                } else {
                    return {
                        ...line,
                        unit_price: {
                            converted: normalizePrice(line.unit_price ?? 0),
                            original: normalizePrice(line.unit_price ?? 0)
                        }
                    };
                }
            }));

            form.setValue("reference_number", {
                id: fromSOOrders.order.id,
                code: fromSOOrders.order.code
            });

            if (fromSOOrders.order.preferred_delivery_date)
                form.setValue("preferred_delivery_date", fromSOOrders.order.preferred_delivery_date.subtract(1, "day"));

            if (fromSOOrders.order.our_reference)
                form.setValue("our_reference", fromSOOrders.order.our_reference);

            dispatch(emptyFromSO());
        }
    }, [fromSOOrders]);

    // Needed for "PO FROM BOM" feature, used, when BOM is selected
    useEffect(() => {
        if (fromBOMOrders) {
            const { bom, components } = fromBOMOrders;

            const groupLine = [{
                module_entity_id: bom.id,
                name: bom.name,
                module: "bom",
                lines: components.map(component => ({
                    ...component,
                    unit_price: {
                        converted: normalizePrice(component.unit_price),
                        original: normalizePrice(component.unit_price)
                    }
                }))
            }] as PurchaseOrdersFormTyping["lines"];

            form.setValue("lines", groupLine as PurchaseOrdersFormTyping["lines"]);

            dispatch(emptyFromBOM());
        }
    }, [fromBOMOrders]);
    useEffect(() => {
        if (!props.purchaseOrder && baseCurrency) {
            form.setValue("currency", baseCurrency);
        }
    }, [baseCurrency]);

    const onSubmit = form.handleSubmit((data: PurchaseOrdersFormTyping) => {
        props.onSubmit(data);
    }, console.error);

    return (
        <FormProvider {...form}>
            <OrderReference />
            <div className="space-y-[40px]">
                <GeneralSection />
                <ReferencesSection />
                <CurrencySection />
                <AddressSection />
                <LanguageSection />
                <BaseCustomFieldsSection.Mutation
                    mode={props.purchaseOrder?.is_editable ? "editing" : "creation"}
                    module={"purchase_order"}
                />
                <ProductSection />
                <div className="flex justify-center">
                    <BaseButton
                        text={"Save"}
                        size={"md"}
                        buttonWidth={"200px"}
                        onClick={() => onSubmit()}
                    />
                </div>
            </div>
        </FormProvider>
    );
}
