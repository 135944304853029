import React from "react";
import { calculateOrderPrice, formatPriceWithCurrency } from "@helpers/utils";
import { LineSchema, PurchaseOrdersFormTyping } from "../../types";
import { useFormContext, useWatch } from "react-hook-form";

export default function TotalCell({ line }: { line: LineSchema }) {
    const { control } = useFormContext<PurchaseOrdersFormTyping>();

    const currency = useWatch({ control, name: "currency" });

    return <>
        {
            formatPriceWithCurrency(calculateOrderPrice(line.quantity ?? 0, line.unit_price.converted ?? 0, line.discount ?? 0), currency)
        }
    </>;
}
