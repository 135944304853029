import React, { memo } from "react";
import { isGroup, isLine, LineGroupUnion, PurchaseOrdersFormTyping } from "../../../types";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    useReactTable
} from "@tanstack/react-table";
import { ArrayElementType, calculateOrderPrice, cn, normalizePrice } from "@helpers/utils";
import { ExternalLink, GripVertical } from "lucide-react";
import BaseBadge from "@reusables/BaseBadge";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ReactComponent as CollectionsIcon } from "@assets/icons/sidebar/ic_folders.svg";
import { ReactComponent as EditSVG } from "@assets/icons/ic_edit.svg";
import { ReactComponent as TrashIcon } from "@assets/icons/ic_trash.svg";
import BaseTableV2 from "@reusables/BaseTableV2";
import { Draggable, Droppable } from "@hello-pangea/dnd";
import TotalCell from "../TotalCell";
import PriceCell from "../PriceCell";


type LinesRendererProps = {
    accessor: "lines" | `lines.${number}.lines`;
    lines: LineGroupUnion[];
    onLineEditModalOpen?: (lineIndex: number, levelAccessor: string, line: LineGroupUnion) => void;
    combineDraggableId?: string;
}

export default memo(function LinesRenderer({
                                               accessor,
                                               lines,
                                               onLineEditModalOpen,
                                               combineDraggableId
                                           }: LinesRendererProps) {
    const { t } = useTranslation("", { keyPrefix: "purchaseOrder.general.orders" });

    const { getValues, setValue } = useFormContext<PurchaseOrdersFormTyping>();

    console.log(`LinesRenderer with accessor ${accessor} is rerendering...`);

    const columnHelper = createColumnHelper<ArrayElementType<typeof lines>>();

    const table = useReactTable({
        data: lines,
        columns: [
            columnHelper.display({
                id: "grip",

                header: t("columns.0") + "",
                cell: () => <GripVertical size={16} color={"#D9D6DA"} />,
                maxSize: 20,
                meta: {
                    forGroup: true
                }
            }),
            columnHelper.accessor(row => isGroup(row) ? row.name : row.product.code, {
                id: "product_code",
                cell: info => {
                    const row = info.row.original;
                    if (isLine(row)) {
                        return <div>{row.product.code}</div>;
                    } else {
                        return null;
                    }
                },
                maxSize: 150
            }),

            columnHelper.accessor((row) => isLine(row) ? row.product.name : "", {
                id: "product_name",
                cell: info => {
                    const row = info.row.original;

                    if (isLine(row)) return row.product.name;

                    return (
                        <div className={"flex flex-col pl-3 space-y-[8px]"} style={{ borderLeft: "3px solid #B1B2E1" }}>
                            <div className="flex items-center space-x-2">
                                <div className="font-semibold">{row.name}</div>
                                {
                                    (row.module === "collection" || row.module === "bom") &&
                                    <BaseBadge
                                        className="px-2 bg-[#CDCFFD] rounded-xl cursor-pointer hover:opacity-70"
                                        onClick={() => {
                                            if (row.module === "collection") {
                                                window.open(`/dashboard/inventory/collections/${row.module_entity_id}/edit`);
                                            } else {
                                                window.open(`/dashboard/bill-of-materials/${row.module_entity_id}/details`);
                                            }
                                        }}
                                    >
                                        <div className="flex items-center">
                                            <CollectionsIcon className="w-[12px] mr-1 bg-[#CDCFFD] text-accent" />
                                            <span>{t(`groups.types.${row.module}`)}</span>
                                            <ExternalLink size={10} className="ml-2 text-sm text-accent" />
                                        </div>
                                    </BaseBadge>
                                }
                            </div>
                            {
                                !!row.comment &&
                                <div className={"text-[#5A5587] text-sm font-thin"}>{row.comment}</div>
                            }
                        </div>
                    );
                },
                meta: {
                    forGroup: true
                }
            }),
            columnHelper.accessor(row => isLine(row) ? row.quantity : 0, {
                id: "quantity",
                cell: info => {
                    const row = info.row.original;

                    if (!isLine(row)) return "";

                    return <div>{row.quantity}</div>;
                },

                maxSize: 120
            }),
            columnHelper.accessor(row => isLine(row) ? normalizePrice(row.unit_price.converted) : "", {
                id: "unit_price",
                cell: info => {
                    const row = info.row.original;

                    if (!isLine(row)) return "";

                    return <PriceCell unit_price={row.unit_price} />;
                },
                maxSize: 120
            }),
            columnHelper.accessor(row => isLine(row) ? row.discount : 0, {
                id: "discount",
                cell: info => {
                    const row = info.row.original;
                    if (!isLine(row)) return "";

                    return <div className="flex">{row.discount ? `${row.discount}%` : "-"}</div>;
                },
                maxSize: 120
            }),
            columnHelper.accessor(row => isLine(row) ? row.tax?.rate : 0, {
                id: "tax",
                cell: info => {
                    const row = info.row.original;
                    if (!isLine(row)) return "";

                    return <div className="flex">{row.tax?.rate ? `${row.tax.rate}%` : "-"}</div>;
                },
                maxSize: 120
            }),
            columnHelper.accessor(
                row => isLine(row) && normalizePrice(calculateOrderPrice(row.quantity ?? 0, row.unit_price.converted ?? 0, row.discount ?? 0)),
                {
                    id: "total",
                    cell: info => {
                        const row = info.row.original;
                        const index = info.row.index;

                        if (!isLine(row)) return "";

                        // If row.data exists or row is a group header (without data), show TotalCell
                        return <TotalCell line={row} />;
                    },
                    maxSize: 150
                }),
            columnHelper.display(
                {
                    id: "actions",
                    cell: info => {
                        const row = info.row.original;
                        const index = info.row.index;
                        const isEditable = getValues("is_editable") === true;
                        if (!isEditable) return null;
                        return (
                            <div
                                className={`flex justify-end space-x-2 ${
                                    isGroup(row) ? "text-[#5A5587]" : "text-tables-secondaryIcon"
                                }`}
                            >
                                <EditSVG
                                    className="hover:text-tables-highlightedIcon cursor-pointer"
                                    onClick={() => onLineEditModalOpen?.(index, accessor, row)}
                                />
                                <TrashIcon
                                    className="hover:text-tables-highlightedIcon cursor-pointer"
                                    onClick={() => handleRemove(index)}
                                />
                            </div>
                        );
                    },
                    maxSize: 100,
                    meta: {
                        forGroup: true
                    }
                }
            )
        ],
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getRowCanExpand: (row) => isGroup(row.original),
        getRowId: (row, index) => accessor + ">" + index
    });

    const handleRemove = (index: number) => {
        const line = lines[index];
        const deletedLines = getValues("deleted_lines") ?? [];

        if (isGroup(line)) {
            const groupItemIds = line.lines.map(item => item.id);

            // Update deleted lines with group item IDs and remove group items
            setValue("deleted_lines", [...deletedLines, ...groupItemIds]);
        } else {
            // Add the single item's ID to deleted lines if it exists
            if (line.id) {
                setValue("deleted_lines", [...deletedLines, line.id]);
            }
        }

        // Remove the line from the lines array
        setValue(accessor, lines.filter(((_, fI) => fI !== index)));
    };

    return (
        <Droppable
            droppableId={accessor}
            isCombineEnabled
            getContainerForClone={() => document.body}
            renderClone={(provided, snapshot, rubric) => {
                const row = table.getRowModel().rows[rubric.source.index];

                const isRowGroup = row.original && isGroup(row.original);
                const cells = row.getVisibleCells().filter(cell => !isRowGroup || (cell.column.columnDef.meta as any)?.forGroup);

                return (
                    <BaseTableV2.Body
                        className="space-y-2"
                    >
                        <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            className={cn(
                                "bg-white border-2 border-solid border-[#ECEDFE] rounded-md"
                            )}
                        >
                            <BaseTableV2.Row
                                className={cn(isRowGroup && "min-h-[40px] bg-[#ECEDFE]")}
                            >
                                {
                                    cells.map((cell, index) => (
                                        <BaseTableV2.Cell
                                            key={cell.id}
                                            {...cell.column.columnDef}
                                            className={
                                                cn(
                                                    isRowGroup && row.getCanExpand() ? "self-center border-2 border-[#ECEDFE]" : ""
                                                )
                                            }
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </BaseTableV2.Cell>
                                    ))
                                }
                            </BaseTableV2.Row>
                        </div>
                    </BaseTableV2.Body>
                );
            }}
        >
            {(provided) => (
                <BaseTableV2.Body
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="space-y-2"
                >
                    {table.getRowModel().rows.map((row, index) => {
                        const draggableId = accessor + ">" + index;

                        return <Draggable key={row.id} draggableId={draggableId} index={index}>
                            {(provided, snapshot) => {
                                const isRowGroup = row.original && isGroup(row.original);
                                const cells = row.getVisibleCells().filter(cell => !isRowGroup || (cell.column.columnDef.meta as any)?.forGroup);

                                return (
                                    <>
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            className={cn(
                                                combineDraggableId === draggableId && "bg-[#ECEDFE] bg-opacity-50",
                                                isRowGroup && "border-2 border-solid border-[#ECEDFE] rounded-md"
                                            )}
                                        >
                                            <BaseTableV2.Row
                                                className={cn(isRowGroup && "min-h-[40px] bg-[#ECEDFE]")}
                                            >
                                                {cells.map(cell => (
                                                    <BaseTableV2.Cell
                                                        key={cell.id}
                                                        {...cell.column.columnDef}
                                                        {...(cell.column.columnDef.id === "grip" && ({ ...provided.dragHandleProps }))}
                                                        className={
                                                            cn(
                                                                isRowGroup && row.getCanExpand() ? "self-center border-2 border-[#ECEDFE]" : ""
                                                            )
                                                        }
                                                    >
                                                        {flexRender(
                                                            cell.column.columnDef.cell,
                                                            cell.getContext()
                                                        )}
                                                    </BaseTableV2.Cell>
                                                ))}
                                            </BaseTableV2.Row>
                                            {row.getCanExpand() && isGroup(row.original) && (
                                                <>
                                                    {
                                                        row.original.lines.length ?
                                                            <LinesRenderer
                                                                // TODO: improve validation to avoid `any`
                                                                accessor={`${accessor}.${row.index}.lines` as any}
                                                                lines={row.original.lines}
                                                                onLineEditModalOpen={onLineEditModalOpen}
                                                                combineDraggableId={combineDraggableId}
                                                            />
                                                            :
                                                            <div className="flex items-center justify-center h-[70px]">
                                                                <div
                                                                    className="text-center text-sm text-[#5A5587] opacity-50">{t("groups.emptyBanner")}</div>
                                                            </div>
                                                    }
                                                </>
                                            )}
                                        </div>
                                    </>
                                );
                            }}
                        </Draggable>;
                    })}
                    {provided.placeholder}
                </BaseTableV2.Body>
            )}
        </Droppable>
    );
});

