import { AppThunk } from "@redux/hooks";
import { resetOperation, startOperation } from "@redux/features/dfs/dfsSlice";
import { toastError } from "@helpers/utils";

// ================================================================================
// EXPORT
// ================================================================================

type ModulesWithExportSupport = "product" | "stock" | "purchase_order" | "sale_order" | "transfer" | "stock_count";

/**
 * Thunk to perform an export for a specific module. It incorporates the necessary logic to start the export process and everything else will be held by the sockets listeners. * You must create export logic ONLY here, otherwise talk to @nekear about this.
 * @param module
 * @param query
 */
export function performExport<T>(module: ModulesWithExportSupport, query: () => Promise<T>): AppThunk {
    return async (dispatch, getState) => {
        try {
            // Optional. Sockets listeners will catch the ExportStarted event and start the export process, however it will require some time and such manual start improves UX.
            // @see src/components/DashboardNav/components/ExportIndicators.tsx
            dispatch(startOperation({ operation: "export", module }));

            return await query();
        } catch (e: any) {
            toastError(e);

            console.log(`Error while performing an export for module [${module}]}:`, e);
            dispatch(resetOperation({ operation: "export" }));
            return Promise.reject(e);
        }
    };
}

// ================================================================================
// IMPORT
// ================================================================================

type ModulesWithImportSupport = "tripletex" | "product" | "poweroffice" | "twenty_four_seven_office";

/**
 * Thunk to perform an import for a specific module. It incorporates the necessary logic to start the import process and everything else will be held by the sockets listeners.
 * You must create imports logic ONLY here, otherwise talk to @nekear about this.
 * @param module
 * @param query
 */
export const performImport = <T>(module: ModulesWithImportSupport, query: () => Promise<T>): AppThunk => async (dispatch, getState) => {
    try {
        // Optional. Sockets listeners will catch the ImportStarted event and start the import process, however it will require some time and such manual start improves UX.
        // @see src/components/DashboardNav/components/ImportIndicators.tsx
        dispatch(startOperation({ operation: "import", module }));

        return await query();
    } catch (e: any) {
        toastError(e);

        console.log(`Error while performing an import for module [${module}]}:`, e);
        dispatch(resetOperation({ operation: "import" }));
        return Promise.reject(e);
    }
};


// ================================================================================
// PRICE RECONVERSION
// ================================================================================

type ModulesWithPriceReconversionSupport = "product";

/**
 * Thunk to perform an export for a specific module
 * @param module
 * @param query
 */
export function performPriceReconversion<T>(module: ModulesWithPriceReconversionSupport, query: () => Promise<T>): AppThunk {
    return async (dispatch, getState) => {
        try {
            dispatch(startOperation({ operation: "price_reconversion", module }));

            return await query();
        } catch (e: any) {
            toastError(e);

            console.log(`Error while performing a price reconversion for module [${module}]}:`, e);
            dispatch(resetOperation({ operation: "price_reconversion" }));
            return Promise.reject(e);
        }
    };
}
