import React from "react";
import { cn } from "@helpers/utils";

type Props = {
    children?: any;
    className?: string;
}

const BaseLabel = ({ children, className }: Props) => {
    return (
        <div className={cn("label mb-2 text-md font-semibold text-[#686868]", className)}>
            {children}
        </div>
    );
};

export default BaseLabel;
