import {
    ExportRequest,
    flattenExportRequest,
    flattenPaginationRequest,
    internalApiSlice,
    PaginationRequest,
    PaginationResponse,
    transformResponse
} from "../../api/internalApiSlice";
import { PurchaseOrder, Restocking, UpcomingShipment } from "@/types/general";

const POExtendedDateFields = ["purchase_date", "receive_date", "preferred_delivery_date"];

export const purchasesApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getPurchaseOrdersFull: builder.query<PaginationResponse<PurchaseOrder.Root>, PaginationRequest<PurchaseOrder.DTO.Filters, PurchaseOrder.DTO.OrderBy>>({
            query: (req) => ({
                url: "purchase-order",
                method: "GET",
                params: {
                    ...flattenPaginationRequest(req)
                },
                parseDates: POExtendedDateFields
            }),
            providesTags: (result, error, page) =>
                result
                    ? [
                        ...result.payload.map(({ id }) => ({ type: "PurchaseOrder" as const, id })),
                        { type: "PurchaseOrder", id: "PARTIAL-LIST" }
                    ]
                    : [{ type: "PurchaseOrder", id: "PARTIAL-LIST" }]
        }),

        getModulePurchaseOrdersFull: builder.query<PaginationResponse<PurchaseOrder.Root>, PaginationRequest<PurchaseOrder.DTO.Filters, PurchaseOrder.DTO.OrderBy> & {
            module: string
        }>({
            query: ({ module, ...req }) => ({
                url: `${module}/purchase-order`,
                method: "GET",
                params: {
                    ...flattenPaginationRequest(req)
                },
                parseDates: POExtendedDateFields
            }),
            providesTags: (result, error, page) =>
                result
                    ? [
                        ...result.payload.map(({ id }) => ({ type: "PurchaseOrder" as const, id })),
                        { type: "PurchaseOrder", id: "PARTIAL-LIST" }
                    ]
                    : [{ type: "PurchaseOrder", id: "PARTIAL-LIST" }]
        }),

        createPurchaseOrder: builder.mutation<PurchaseOrder.Extended, PurchaseOrder.DTO.Create>({
            query: (body) => ({
                url: `purchase-order`,
                method: "POST",
                data: body,
                parseDates: POExtendedDateFields
            }),
            invalidatesTags: (result, error, args) => {
                if (result) {
                    return [
                        { type: "PurchaseOrder" },
                        ...args.lines.flatMap(line => {
                            if (line.type == "line") {
                                return { type: "Product", id: line.data.product };
                            } else {
                                return line.data.lines.map(l => ({ type: "Product", id: l.data.product }));
                            }
                        })
                    ];
                }
                return [];
            },
            transformResponse
        }),

        updatePurchaseOrder: builder.mutation<PurchaseOrder.Extended, PurchaseOrder.DTO.Update & { id: number }>({
            query: ({ id, ...body }) => ({
                url: `purchase-order/${id}`,
                method: "PUT",
                data: body,
                parseDates: POExtendedDateFields
            }),
            invalidatesTags: (result, error, args) => result ? [{ type: "PurchaseOrder", id: args.id }] : [],
            transformResponse
        }),

        getPurchaseOrder: builder.query<PurchaseOrder.Extended, number>({
            query: (id) => ({
                url: `purchase-order/${id}`,
                parseDates: POExtendedDateFields
            }),
            transformResponse,
            providesTags: result => result ? [{ type: "PurchaseOrder", id: result.id }] : []
        }),

        getPurchaseOrdersSlim: builder.query<PurchaseOrder.Slim[], { suppliers?: number[] } | void>({
            query: (params) => {
                const filters = params ? { filters: params } : {};

                return ({
                    url: `purchase-order/slim`,
                    method: "GET",
                    params: filters
                });
            },
            transformResponse,
            providesTags: result => result ? result.map(({ id }) => ({ type: "PurchaseOrder", id })) : []
        }),

        getModulePurchaseOrders: builder.query<PurchaseOrder.Slim[], { module: string, suppliers?: number[] | void }>({
            query: ({ module, ...params }) => {
                const filters = params ? { filters: params } : {};

                return ({
                    url: `${module}/purchase-order`,
                    method: "GET",
                    params: filters
                });
            },
            transformResponse,
            providesTags: result => result ? result.map(({ id }) => ({ type: "PurchaseOrder", id })) : []
        }),

        getModulePurchaseOrder: builder.query<PurchaseOrder.Extended, { module: string, id: number }>({
            query: ({ module, id }) => ({
                url: `${module}/purchase-order/${id}`,
                parseDates: POExtendedDateFields
            }),
            transformResponse,
            providesTags: result => result ? [{ type: "PurchaseOrder", id: result.id }] : []
        }),

        deletePurchaseOrder: builder.mutation<void, number>({
            query: (id) => ({
                url: `purchase-order/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: (result, error, arg_id) => [{ type: "PurchaseOrder", id: arg_id }]
        }),

        cancelPurchaseOrder: builder.mutation<PurchaseOrder.Extended, { id: number, reason: string }>({
            query: ({ id, ...body }) => ({
                url: `purchase-order/${id}/cancel`,
                method: "PUT",
                data: body
            }),
            invalidatesTags: result => result ? [{ type: "PurchaseOrder", id: result.id }] : []
        }),

        getRestocking: builder.query<Restocking.Root[], void>({
            query: () => ({
                url: `dashboard/restocking`
            }),
            transformResponse,
            providesTags: ["PurchaseOrder"]
        }),

        getUpcomingShipments: builder.query<UpcomingShipment.Root[], void>({
            query: () => ({
                url: `purchase-order/upcoming-shipments`,
                parseDates: ["delivery_date"]
            }),
            transformResponse,
            providesTags: ["PurchaseOrder"]
        }),

        exportPurchaseOrders: builder.mutation<void, ExportRequest<undefined, PurchaseOrder.DTO.Filters>>({
            query: (args) => ({
                url: `purchaseOrder/export`,
                responseType: "blob",
                method: "POST",
                data: flattenExportRequest(args)
            })
        })
    })
});

export const {
    useGetPurchaseOrdersFullQuery,
    useGetModulePurchaseOrdersFullQuery,
    useCreatePurchaseOrderMutation,
    useUpdatePurchaseOrderMutation,
    useGetPurchaseOrderQuery,
    useGetPurchaseOrdersSlimQuery,
    useGetModulePurchaseOrdersQuery,
    useGetModulePurchaseOrderQuery,
    useDeletePurchaseOrderMutation,
    useCancelPurchaseOrderMutation,
    useGetRestockingQuery,
    useGetUpcomingShipmentsQuery,
    useExportPurchaseOrdersMutation
} = purchasesApi;