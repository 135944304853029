import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import { FormProvider, useFormContext, useWatch } from "react-hook-form";
import { GroupSchema, isGroup, isLine, LineGroupUnion, LineSchema, PurchaseOrdersFormTyping } from "../../types";
import AddProductModal from "./modals/AddProductModal";
import { ArrayElementType, normalizePrice } from "@helpers/utils";
import SummaryBlock from "./SummaryBlock";
import AddCollectionModal from "./modals/AddCollectionModal";
import { Alert, Collapse } from "@mui/material";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    useReactTable
} from "@tanstack/react-table";
import { DragDropContext, OnDragEndResponder, OnDragUpdateResponder } from "@hello-pangea/dnd";
import BaseTableV2 from "@reusables/BaseTableV2";
import LinesRenderer from "./table/LinesRenderer";
import { handleDragEnd } from "@components/Dashboard/pages/Sales/Orders/components/MutationLayout/logic";
import BaseMaterialIconButton from "@reusables/BaseMaterialIconButton";
import { FolderPlus } from "lucide-react";
import AddGroupModal from "./modals/AddGroupModal";
import _ from "lodash";


export default function ProductSection() {
    const { t } = useTranslation("", { keyPrefix: "purchaseOrder.general.orders" });

    const form = useFormContext<PurchaseOrdersFormTyping>();
    const { control, getValues, setValue, formState } = form;

    // Local states for modals
    const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
    const [isAddCollectionModalOpen, setIsAddCollectionModalOpen] = useState(false);
    const [isAddGroupModalOpen, setIsAddGroupModalOpen] = React.useState(false);

    const [selectedLine, setSelectedLine] = useState<LineSchema>();
    const [selectedLinePath, setSelectedLinePath] = useState<{ accessor: string; index: number }>();

    const [selectedGroup, setSelectedGroup] = React.useState<GroupSchema>();
    const [selectedGroupPath, setSelectedGroupPath] = React.useState<{ accessor: string, index: number }>();

    // Watchers for currency, supplier, etc.
    const exchangeRate = useWatch({ control, name: "exchange_rate" });
    const supplier = useWatch({ control, name: "supplier" });

    // Example: watch lines
    const lines = useWatch({ name: "lines", control });

    /**
     * Recalculate unit prices whenever exchange rate changes.
     */
    useEffect(() => {
        if (!lines?.length) return;
        const clonedLines = _.cloneDeep(lines);

        clonedLines.forEach(line => {
            if (isLine(line)) {
                line.unit_price.converted = normalizePrice(line.unit_price.original * (exchangeRate ?? 1));
            } else if (isGroup(line)) {
                line.lines.forEach(line => {
                    line.unit_price.converted = normalizePrice(line.unit_price.original * (exchangeRate ?? 1));
                });
            }
        });

        setValue("lines", clonedLines);
    }, [exchangeRate]);

    // Example: DnD support
    const [combineDraggableId, setCombineDraggableId] = useState<string>();
    const onDragUpdate: OnDragUpdateResponder = (result) => {
        setCombineDraggableId(result.combine?.draggableId);
    };

    const onDragEnd: OnDragEndResponder = (result) => {
        handleDragEnd(result, lines, (lines) => {
            setValue("lines", lines);
            setCombineDraggableId(undefined);
        });
    };

    const append = (data: LineGroupUnion) => {
        setValue("lines", [...lines, data]);
    };

    const update = (path: { accessor: string, index: number }, data: LineGroupUnion) => {
        setValue(path.accessor + "." + path.index as any, data);
    };

    // Create columns with react-table
    const columnHelper = createColumnHelper<ArrayElementType<typeof lines>>();

    const table = useReactTable({
        data: [],
        columns: [
            columnHelper.display({
                id: "grip",
                header: () => null,
                maxSize: 20
            }),
            columnHelper.display({
                header: t("columns.0") + "",
                id: "product_code",
                maxSize: 150
            }),

            columnHelper.display({
                header: t("columns.1") + "",
                id: "product_name"
            }),
            columnHelper.display({
                header: t("columns.2") + "",
                id: "quantity",
                maxSize: 120
            }),
            columnHelper.display({
                header: t("columns.3") + "",
                id: "unit_price",
                maxSize: 120
            }),
            columnHelper.display({
                header: t("columns.4") + "",
                id: "discount",
                maxSize: 120
            }),
            columnHelper.display({
                header: t("columns.5") + "",
                id: "tax",
                maxSize: 120
            }),
            columnHelper.display({
                id: "total",
                header: t("columns.6") + "",
                maxSize: 150
            }),
            columnHelper.display(
                {
                    id: "actions",
                    header: () => <div className="text-right">{t("columns.7")}</div>,
                    maxSize: 100
                }
            )
        ],
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel()
    });

    const onLineEditModalOpen = useCallback((index: number, accessor: string, line: LineGroupUnion) => {
        if (isLine(line)) {
            setIsAddProductModalOpen(true);
            setSelectedLine(line);
            setSelectedLinePath({ accessor, index });
        } else if (isGroup(line)) {
            setIsAddGroupModalOpen(true);
            setSelectedGroup(line);
            setSelectedGroupPath({ accessor, index });
        }
    }, []);

    return (
        <>
            <Collapse in={!!formState.errors.lines?.message}>
                <Alert severity="error">{formState.errors.lines?.message}</Alert>
            </Collapse>

            <div className="border border-solid border-gray-300 rounded-[8px] p-[16px]">
                {lines?.length ? (
                    <>
                        <div className="mb-[26px] space-y-[16px]">
                            <div className="flex items-center space-x-[8px]">
                                <div className="text-xl font-thin text-gray-600 grow">
                                    {t("heading")}
                                </div>
                                {getValues("is_editable") && (
                                    <>
                                        <BaseMaterialIconButton
                                            icon={<FolderPlus size={16} />}
                                            onClick={() => {
                                                setSelectedGroup(undefined);
                                                setSelectedGroupPath(undefined);
                                                setIsAddGroupModalOpen(true);
                                            }}
                                        />
                                        <BaseMaterialButton
                                            fontWeight={500}
                                            onClick={() => {
                                                setIsAddProductModalOpen(true);
                                                setSelectedLine(undefined);
                                                setSelectedLinePath(undefined);
                                            }}
                                        >
                                            {t("buttons.addProduct")}
                                        </BaseMaterialButton>
                                        <BaseMaterialButton
                                            fontWeight={500}
                                            onClick={() => setIsAddCollectionModalOpen(true)}
                                        >
                                            {t("buttons.addCollection")}
                                        </BaseMaterialButton>
                                    </>
                                )}
                            </div>
                        </div>

                        <FormProvider {...form}>
                            <DragDropContext onDragUpdate={onDragUpdate} onDragEnd={onDragEnd}>
                                <BaseTableV2>
                                    {table.getHeaderGroups().map(headerGroup => (
                                        <BaseTableV2.Header key={headerGroup.id} className="mb-3">
                                            <BaseTableV2.Row>
                                                {headerGroup.headers.map(header => (
                                                    <BaseTableV2.Cell
                                                        key={header.id}
                                                        {...header.column.columnDef}
                                                    >
                                                        {header.isPlaceholder
                                                            ? null
                                                            : flexRender(header.column.columnDef.header, header.getContext())}
                                                    </BaseTableV2.Cell>
                                                ))}
                                            </BaseTableV2.Row>
                                        </BaseTableV2.Header>
                                    ))}
                                    <LinesRenderer
                                        accessor="lines"
                                        lines={lines}
                                        onLineEditModalOpen={onLineEditModalOpen}
                                        combineDraggableId={combineDraggableId}
                                    />
                                </BaseTableV2>
                            </DragDropContext>
                        </FormProvider>

                        <SummaryBlock />
                    </>
                ) : (
                    <>
                        <div className="text-xl text-center text-gray-300 font-thin">
                            {t("noProducts")}
                        </div>
                        <div className="grid grid-cols-2 gap-[40px] mt-[24px]">
                            <BaseMaterialButton
                                size="large"
                                fontWeight={500}
                                onClick={() => setIsAddProductModalOpen(true)}
                            >
                                {t("buttons.addProduct")}
                            </BaseMaterialButton>
                            <BaseMaterialButton
                                size="large"
                                fontWeight={500}
                                onClick={() => setIsAddCollectionModalOpen(true)}
                            >
                                {t("buttons.addCollection")}
                            </BaseMaterialButton>
                        </div>
                    </>
                )}

                {/* Modals */}
                <AddProductModal
                    isOpen={isAddProductModalOpen}
                    onClose={() => {
                        setIsAddProductModalOpen(false);
                        if (selectedLine) {
                            setSelectedLine(undefined);
                        }
                    }}
                    additionalData={{
                        currency: getValues("currency.symbol"),
                        exchange_rate: exchangeRate,
                        supplier_id: supplier?.id
                    }}
                    onLineMutation={(product) => {
                        // BOM logic example
                        if (product.should_use_bom && product.selected_bom) {
                            append({
                                name: product.selected_bom.name,
                                module: "bom",
                                module_entity_id: product.selected_bom.id,
                                lines: product.selected_bom.components.map((component) => ({
                                    product: component.product,
                                    quantity: (component.quantity || 0) * (product.quantity || 1),
                                    unit_price: {
                                        converted: normalizePrice(component.product.prices?.purchase_price * (exchangeRate ?? 1)),
                                        original: normalizePrice(component.product.prices?.purchase_price),
                                    },
                                    discount: product.discount,
                                    tax: product.tax
                                }))
                            });

                            if (!supplier) {
                                const supplierFound = _.find(product.selected_bom.components, (component) => _.get(component, "product.supplier"));
                            
                                if (supplierFound) {
                                    const foundSupplier = _.get(supplierFound, "product.supplier");
                                    if (foundSupplier) {
                                        setValue("supplier", foundSupplier);
                                    }
                                }
                            }
                        } else {
                            // If editing existing line vs new
                            if (selectedLine && selectedLinePath) {
                                update(selectedLinePath, product);
                            } else {
                                append(product);

                                if (!supplier) {
                                    if (product.product.supplier) {
                                        setValue("supplier", product.product.supplier);
                                    }
                                }
                            }
                        }
                    }}
                    selectedLine={selectedLine}
                />

                <AddCollectionModal
                    isOpen={isAddCollectionModalOpen}
                    onClose={() => {
                        setIsAddCollectionModalOpen(false);
                    }}
                    onLineAddition={(collection) => {
                        append({
                            name: collection.collection.name,
                            module: "collection",
                            module_entity_id: collection.collection.id,
                            lines: collection.collection.products.map((prod) => ({
                                product: prod,
                                quantity: prod.quantity * collection.quantity,
                                unit_price: {
                                    converted: normalizePrice(prod.prices.purchase_price * (exchangeRate ?? 1)),
                                    original: normalizePrice(prod.prices.purchase_price),
                                },
                                tax: prod.tax
                            }))
                        });

                        if (!supplier) {
                            const supplierFound = _.find(collection.collection.products, (product) => _.get(product, "supplier"));
                        
                            if (supplierFound) {
                                const foundSupplier = _.get(supplierFound, "supplier");
                                if (foundSupplier) {
                                    setValue("supplier", foundSupplier);
                                }
                            }
                        }
                    }}
                />

                <AddGroupModal
                    isOpen={isAddGroupModalOpen}
                    onClose={() => {
                        setIsAddGroupModalOpen(false);
                    }}
                    group={selectedGroup}
                    onMutation={(data) => {
                        if (selectedGroup && selectedGroupPath !== undefined) {
                            update(selectedGroupPath, {
                                ...selectedGroup,
                                name: data.name,
                                comment: data.comment
                            });
                        } else {
                            append({
                                name: data.name,
                                comment: data.comment,
                                module: "custom",
                                lines: []
                            });
                        }

                        setSelectedGroup(undefined);
                        setSelectedGroupPath(undefined);
                        setIsAddGroupModalOpen(false);
                    }}
                />
            </div>
        </>
    );
}
