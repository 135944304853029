import React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { QuickPurchaseOrderSchemeTyping } from '../types';
import BaseCheckbox from '@reusables/BaseCheckbox';
import BaseInput from '@reusables/BaseInput';

interface BOMComponentsProps {
    index: number;
}

export default function BOMComponents({ index }: BOMComponentsProps) {
    const { control } = useFormContext<QuickPurchaseOrderSchemeTyping>();

    const component = useWatch({
        control: control,
        name: `selected_bom.components.${index}`
    });

    return (
        <div className="flex justify-between items-center">
            <div className="flex items-center">
                <Controller
                    control={control}
                    name={`selected_bom.components.${index}.selected`}
                    render={({ field }) => (
                        <BaseCheckbox
                            {...field}
                            className='mr-4 w-7 h-7'
                        />
                    )}
                />

                <span className="text-accent text-base">{component.product.name}</span>
            </div>

            <div className="w-24">
                <Controller
                    control={control}
                    name={`selected_bom.components.${index}.quantity`}
                    render={({ field, fieldState }) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            type="number"
                            disabled={!component.selected}
                        />
                    )}
                />
            </div>
        </div>
    )
}
