import BaseModal from "@/components/reusables/Modals/BaseModal";
import React, { useEffect } from "react";
import { FormProvider, useFieldArray, useForm, useWatch } from "react-hook-form";
import {
    lowStockRefillScheme,
    LowStockRefillSchemeTyping
} from "@components/Dashboard/pages/Sales/Orders/components/modals/LowStockRefillModal/types";
import { zodResolver } from "@hookform/resolvers/zod";
import RefillLine
    from "@components/Dashboard/pages/Sales/Orders/components/modals/LowStockRefillModal/parts/RefillLine";
import { useTranslation } from "react-i18next";
import RefillConfiguration
    from "@components/Dashboard/pages/Sales/Orders/components/modals/LowStockRefillModal/parts/RefillConfiguration";
import ModalActionButtons
    from "@components/Dashboard/pages/Sales/Orders/components/modals/LowStockRefillModal/parts/ModalActionButtons";
import { RefillModalData } from "@components/Dashboard/pages/Sales/Orders/types";
import _ from "lodash";
import { formPurchaseOrderLines } from "@components/Dashboard/pages/Sales/Orders/utils";
import { useAppDispatch } from "@redux/hooks";
import { addFromSO } from "@redux/features/purchases/purchasesSlice";
import { GroupLine, Line } from "@components/Dashboard/pages/PurchasingPage/PurchasesPage/types";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useGetSaleOrderQuery } from "@redux/features/sales/orders/salesOrdersApi";
import { isGroup, isLine } from "../../ViewLayout/DetailsLinesRenderer";

interface LowStockRefillModalProps {
    isOpen: boolean;
    onClose: () => void;
    refill: RefillModalData | undefined;
}

export default function LowStockRefillModal({ isOpen, onClose, ...props }: LowStockRefillModalProps) {
    const { t } = useTranslation("", { keyPrefix: "sales.orders.mutations.creation.lowStockOffer.modals.refill" });
    const history = useHistory();
    const dispatch = useAppDispatch();

    const canLowStock = Boolean(props.refill?.lines?.length);

    const { data: saleOrder, isLoading: isSaleOrderLoading } = useGetSaleOrderQuery(props.refill?.order.id as number, {skip: !props.refill});

    const form = useForm<LowStockRefillSchemeTyping>({
        resolver: zodResolver(lowStockRefillScheme),
        defaultValues: {
            lines: [],
            smart_refill: true,
            mode: canLowStock ? "lowStock" : "resupply",
        }
    });

    const { fields: lines, append, replace } = useFieldArray({
        control: form.control,
        name: "lines",
        keyName: "loop_id"
    });

    const mode = useWatch({
        control: form.control,
        name: "mode"
    });

    useEffect(() => {
        form.reset({
            ...form.getValues(),
            lines: [],
        });
    
        if (mode === "lowStock") {
            if (props.refill?.lines) {
                const { lines } = props.refill;
                lines.forEach((l) =>
                    append({
                        ...l,
                        should_be_refilled: true
                    })
                );
            }
        } else if (mode === "resupply") {
            if (!isSaleOrderLoading && Array.isArray(saleOrder?.lines)) {
                const resupplyLines: LowStockRefillSchemeTyping["lines"] = [];
    
                saleOrder?.lines.forEach((line) => {
                    if (isGroup(line) && Array.isArray(line.data?.lines)) {
                        line.data.lines.forEach((subLine) => {
                            if (!subLine.data?.product) return;
    
                            resupplyLines.push({
                                should_be_refilled: true,
                                product: {
                                    id: subLine.data.product.id,
                                    name: subLine.data.product.name,
                                    code: subLine.data.product.code,
                                    has_bom: subLine.data.product.has_bom
                                },
                                quantity: subLine.data.quantity
                            });
                        });
                    } else if (isLine(line)) {
                        if (!line.data?.product) return;
    
                        resupplyLines.push({
                            should_be_refilled: true,
                            product: {
                                id: line.data.product.id,
                                name: line.data.product.name,
                                code: line.data.product.code,
                                has_bom: line.data.product.has_bom,
                            },
                            quantity: line.data.quantity
                        });
                    }
                });
    
                replace(resupplyLines);
            }
        }
    }, [props.refill, saleOrder, isSaleOrderLoading, mode]);

    const onSubmit = form.handleSubmit(async (data) => {
        console.log("data: ", data);
        if (!props.refill) {
            console.warn("Submitting form without refill data being provided. This should not happen. `handleSubmit` won't continue!");
            return;
        }

        const lines = data.lines.filter((l) => l.should_be_refilled);

        // Extracting products from lines, marked to be refilled from the selected BOMs
        // *comparing to the `readyPOProducts`, we don't need to use `formPurchaseOrderLines` here, because BOMs already have purchase prices
        const readyBOMProducts: GroupLine[] = lines
            .filter((l) => !!l.selected_bom)
            .map((l) => {
                return {
                    name: l.selected_bom!.name,
                    module: "bom",
                    module_entity_id: l.selected_bom!.id,
                    lines: l.selected_bom!.components.map((c) => {
                        const quantity = c.quantity * (l.quantity ?? 0);
                        return {
                            id: l.selected_bom?.id,
                            name: l.selected_bom?.name,
                            product: {
                                id: c.product.id,
                                name: c.product.name,
                                code: c.product.code,
                            },
                            unit_price: c.product.prices.purchase_price,
                            quantity: data.smart_refill ? quantity - c.product.in_stock  : quantity,
                            // TODO: add tax
                        };
                    })
                }
            });
            
        try {
            // Loading more info about the products, marked to be refilled as regular products (not as BOMs)
            // *comparing to the `readyBOMProducts`, we need to use `formPurchaseOrderLines` here, because regular products don't have purchase prices
            let readyPOProducts: Line[] = [];
            if (lines.some((l) => !l.selected_bom)) {
                readyPOProducts = await formPurchaseOrderLines(
                    lines
                        .filter((l) => !l.should_use_bom)
                        .map((l) => ({
                            product_id: l.product.id,
                            quantity_to_order: l.quantity as number // will always be Non-nullable thanks to validation
                        })),
                    dispatch
                );
            }

            // Adding products to the Redux store (even tho we can simply use routing features) and redirecting to the purchase order creation page
            dispatch(
                addFromSO({
                    order: props.refill.order,
                    lines: [...readyPOProducts, ...readyBOMProducts]
                })
            );

            history.push("/dashboard/purchasing/new");
        } catch (e) {
            console.error("Error while trying to load purchase order products. Aborting `handleSubmit` of the RefillModal.", e);

            toast.warn(t("responses.unableToLoadProductsData"));
        }
    }, console.error);

    return (
        <BaseModal isOpen={isOpen} onClose={onClose} width={900} padding="56px">
            <FormProvider {...form}>
                <form className="space-y-[32px]" onSubmit={onSubmit}>
                    <div className="modal-title">{t("heading")}</div>
                    <RefillConfiguration canLowStock={canLowStock} />
                    {lines.map((line, index) => (
                        <RefillLine key={line.loop_id} index={index} />
                    ))}
                    <ModalActionButtons onClose={onClose} />
                </form>
            </FormProvider>
        </BaseModal>
    );
}
