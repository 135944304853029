import React from "react";
import { RefillModalData } from "@components/Dashboard/pages/Sales/Orders/types";
import { Trans, useTranslation } from "react-i18next";
import { HelpCircle } from "lucide-react";
import { Stack } from "@mui/material";
import BaseButton from "@reusables/BaseButton";
import { useHistory } from "react-router-dom";
import LowStockRefillModal from "src/components/Dashboard/pages/Sales/Orders/components/modals/LowStockRefillModal";

/**
 * This component is shown when user tries to create a purchase order from a sale order, where some products are low on stock
 * and won't allow to fulfill the sale order.
 * @param props
 * @constructor
 */
export default function LowStockRefillBanner(props: { refill: RefillModalData }) {
    const { t } = useTranslation("", { keyPrefix: "sales.orders.mutations.creation.lowStockOffer" });

    const history = useHistory();

    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <div className={"w-full h-full bg-white mt-0 flex items-center justify-center flex-column text-accent space-y-[24px]"}>
            <div>
                <HelpCircle size={48} />
            </div>
            <h6 className="font-normal text-accent">{t("heading")}</h6>
            <div className={"text-center"}>
                <Trans i18nKey="subheading" t={t} />
            </div>
            <Stack direction={"row"} spacing={2}>
                <BaseButton text={t("buttons.cancel")} primaryOutlined size={"md"} onClick={() => history.push("/dashboard/sales/orders")} />
                <BaseButton text={t("buttons.create")} size={"md"} onClick={() => setIsOpen(true)} />
            </Stack>

            <LowStockRefillModal isOpen={isOpen} onClose={() => setIsOpen(false)} refill={props.refill} />
        </div>
    );
}
