import React from "react";
import { Control, Controller, FieldPath, FieldPathValue, useWatch } from "react-hook-form";
import BaseLabel from "@reusables/BaseLabel";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import { declOfNum } from "@helpers/utils";
import {
    isPackageTemporary,
    PickingMutationFormTyping
} from "@components/Dashboard/pages/Sales/Picking/components/MutationLayout/types";
import { useTranslation } from "react-i18next";
import { useAbility } from "@/casl.config";
import { ReactComponent as DropdownIcon } from "@assets/icons/ic_expand_down.svg";
import PackagesModal from "@components/Dashboard/pages/Sales/Picking/components/modals/PackagesModal";
import { AlertTriangle } from "lucide-react";
import BaseTooltip from "@reusables/BaseTooltip";

type PackagesDropdownProps = {
    control: Control<PickingMutationFormTyping>;
    setValue: (
        name: FieldPath<PickingMutationFormTyping>,
        value: FieldPathValue<PickingMutationFormTyping, FieldPath<PickingMutationFormTyping>>,
        options?: {
            shouldValidate?: boolean
        }
    ) => void;
}

export default function PackagesDropdown({ control, setValue }: PackagesDropdownProps) {
    const { t } = useTranslation("", { keyPrefix: "sales.picking.general" });

    const ability = useAbility();

    const addedPackages = useWatch({
        control,
        name: "packages"
    });

    const isShipmondoEnabled = useWatch({
        control,
        name: "shipmondo.enabled"
    });

    const [isPackagesModalOpen, setIsPackagesModalOpen] = React.useState(false);

    return (
        <>
            <Controller
                name="packages"
                control={control}
                render={({ field, fieldState }) => (
                    <div className="flex flex-col">
                        <div className="flex items-center space-x-2 mb-2">
                            <BaseLabel className={"mb-0"}>{t("fields.packaging.label")}</BaseLabel>
                            {
                                isShipmondoEnabled && addedPackages?.some(packageData => !packageData.weight) &&
                                <BaseTooltip title={t("fields.packaging.noWeightWarning") + ""} placement="top">
                                    <AlertTriangle size={14} className={"text-error"} />
                                </BaseTooltip>
                            }
                        </div>
                        <div className="relative">
                            <BaseDropdown
                                placeholder={t("fields.packaging.placeholder")}

                                {...field}
                                {...fieldState}
                                onChange={(_, options) => {
                                    field.onChange(options);
                                }}
                                options={addedPackages}
                                getter={{
                                    key: (opt) => opt.package.id,
                                    label: (opt) => {
                                        let suffix = null;
                                        if (opt.weight) {
                                            suffix = `(${opt.weight} ${declOfNum(opt.weight, [
                                                t("fields.packaging.units.one"),
                                                t("fields.packaging.units.few"),
                                                t("fields.packaging.units.many")
                                            ])})`;
                                        }

                                        const packageData = opt.package;

                                        let name: string;

                                        if (isPackageTemporary(packageData)) {
                                            name = packageData.width + "x" + packageData.height + "x" + packageData.length;
                                        } else {
                                            name = packageData.name;
                                        }

                                        return `${name}` + (suffix ? ` ${suffix}` : "");
                                    },
                                    tagClassName: (packageData) => {
                                        if (isShipmondoEnabled && !packageData.weight) {
                                            return "border-[1px] !border-error border-solid";
                                        }

                                        return "";
                                    }
                                }}

                                onOpen={() => {
                                    setIsPackagesModalOpen(true);
                                }}

                                autocomplete
                                multiple

                                hideArrow
                                disabled={ability.cannot("packaging.edit", "picking")}
                                disableReason={"Access denied"}
                            />
                            <DropdownIcon
                                className={`absolute right-3 top-1/2 text-dropdown-icon`}
                                style={{ transform: "translateY(-50%) rotate(270deg)" }}
                            />
                        </div>
                    </div>
                )}
            />

            <PackagesModal
                isOpen={isPackagesModalOpen}
                onClose={() => setIsPackagesModalOpen(false)}
                packages={addedPackages}
                onSave={(packages) => {
                    setValue("packages", packages.packages, { shouldValidate: true });
                    setIsPackagesModalOpen(false);
                }}
            />
        </>
    );
}