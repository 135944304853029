import React from "react";
import { useTranslation } from "react-i18next";
import { Alert, Collapse } from "@mui/material";
import { useAppSelector } from "@redux/hooks";
import { useExportModalContext } from "@reusables/Modals/BaseExportModal/ExportModalProvider";
import { selectOperation } from "@redux/features/dfs/dfsSlice";
import { isDataTransferStateActive } from "@/redux/features/dfs/types";

export default function ConcurrencyWarning() {
    const { t } = useTranslation("", { keyPrefix: "general.modals.export" });

    const exportModalContext = useExportModalContext();

    const isExportInProgress = useAppSelector(state => isDataTransferStateActive(selectOperation("export")(state)));
    const isAllowedExportProgressIndicator = exportModalContext.isAllowedExportProgressIndicator;

    return (
        <>
            {(isExportInProgress && isAllowedExportProgressIndicator) ? (
                    <Collapse in={isExportInProgress}>
                        <Alert severity="warning">
                            {t("exportIsAlreadyInProgressAlert")}
                        </Alert>
                    </Collapse>
                )
                :
                <div className="text-center text-[16px] text-accent">
                    {t("concurrencyWarning")}
                </div>
            }
        </>
    );
}