import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import BaseTable from "@reusables/BaseTable";
import { ReactComponent as EditSVG } from "@assets/icons/ic_edit.svg";

import { useGetProductSerialNumbersFullQuery } from "@redux/features/products/productsApi";
import { removeEmpty, useDatesRangeFilter, useOrdering, usePagination } from "@helpers/utils";
import { Pagination } from "@mui/material";
import _ from "lodash";
import { DatepickerRangeContext } from "@reusables/BaseDatepicker/context";
import BaseDatepicker from "@reusables/BaseDatepicker";
import { Product } from "@/types/general";
import UpdateSerialNumberModal from "../modals/UpdateSerialNumberModal";
import { useAbility } from "@/casl.config";
import { ChevronDown } from "lucide-react";
import FilteringHeader
    from "@components/Dashboard/pages/Products/components/Tabs/items/NumbersTab/components/FilteringHeader";
import BasePermissionBlocker from "@reusables/BasePermissionBlocker";
import { Link } from "react-router-dom";

const searchDebounceCooldown = 1000;

export default function SerialNumbersTab({ product }: { product: Product.Extended }) {
    const { t } = useTranslation("", { keyPrefix: "products.viewEditProduct.tabs.numbers.serialNumbers" });

    const ability = useAbility();

    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [selectedSerialNumber, setSelectedSerialNumber] = useState<Product.Augmentations.SerialNumbers.Root>();

    const [isCollapsed, setIsCollapsed] = useState(true);

    // Searching value with debounce
    const [searchingValue, setSearchingValue] = useState<string>();
    const searchInputDebounce = useCallback(_.debounce(setSearchingValue, searchDebounceCooldown), [setSearchingValue, searchDebounceCooldown]);

    // Dates range
    const { datesRange, setDatesRange, filterAdaptedDatesRange } = useDatesRangeFilter();

    const filters = removeEmpty({
        search: searchingValue,
        dates_range: filterAdaptedDatesRange
    });

    const { orderBy, setOrderBy } = useOrdering<Product.Augmentations.SerialNumbers.DTO.OrderBy>({
        name: "id",
        type: "desc"
    });

    const pagination = usePagination({ page: 1, limit: 8 });

    const { data: serialNumbers, isFetching: isSerialNumbersLoading } = useGetProductSerialNumbersFullQuery({
        product_id: product.id,
        filters,
        orderBy,
        pagination
    });

    return (
        <>
            <div className={"space-y-[16px]"}>
                <div className="flex flex-row justify-between items-center gap-10">
                    <div className="flex gap-2 items-center flex-shrink-0 hover:cursor-pointer"
                         onClick={() => setIsCollapsed(!isCollapsed)}>
                        <ChevronDown
                            className={"text-accent cursor-pointer"}
                            style={{
                                transition: "transform 0.3s",
                                transform: isCollapsed ? "rotate(180deg)" : "rotate(0deg)"
                            }}
                        />
                        <div className="text-xl font-light text-accent">{t("heading")}</div>
                    </div>
                    <FilteringHeader
                        onSearchTyping={searchInputDebounce}
                        onSearchToggle={(state) => {
                            if (state && !isCollapsed) {
                                setIsCollapsed(true);
                            }
                        }}
                        isSearchLoading={isSerialNumbersLoading}
                        typingResetCooldown={searchDebounceCooldown}
                        filters={
                            <DatepickerRangeContext.Provider
                                value={{
                                    dates_range: { range: datesRange, setRange: setDatesRange }
                                }}
                            >
                                <BaseDatepicker
                                    label={t("filters.additionDate.label")}
                                    placeholder={t("filters.additionDate.placeholder")}
                                    rangeConfig={{ groupKey: "dates_range", role: "solo" }}
                                />
                            </DatepickerRangeContext.Provider>
                        }
                    />
                </div>

                <BaseTable
                    isDataLoading={isSerialNumbersLoading}
                    data={serialNumbers?.payload ?? []}
                    hideData={!isCollapsed}
                    columns={[
                        {
                            header: t("table.columns.number"),
                            getter: (row) => row.serial_number,
                            comparator: () => 0
                        },
                        {
                            header: t("table.columns.date"),
                            getter: (row) => <>{row.created_at.format("DD.MM.YYYY")}</>,
                            comparator: () => 0
                        },
                        {
                            header: t("table.columns.received"),
                            getter: (row) =>
                                <>
                                    {row.receipts.length ? (
                                        row.receipts.map((receipt, index) => (
                                            <React.Fragment key={index}>
                                                <Link to={`/dashboard/receive/${receipt.id}/details`}
                                                      className={"underline"}>{receipt.code}</Link>
                                                {index < row.receipts.length - 1 && ", "}
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        "Manually"
                                    )}
                                </>,
                            comparator: () => 0
                        },
                        {
                            header: t("table.columns.dispatched"),
                            getter: (row) =>
                                <>
                                    {(
                                        row.dispatches?.map((dispatch, index) => (
                                            <React.Fragment key={index}>
                                                <Link to={`/dashboard/picking/${dispatch.id}/details`}
                                                      className={"underline"}>{dispatch.code}</Link>
                                                {index < row.dispatches.length - 1 && ", "}
                                            </React.Fragment>
                                        ))
                                    )}
                                </>,
                            comparator: () => 0
                        },
                        {
                            header: t("table.columns.actions"),
                            getter: (row) => (
                                <div className="flex justify-end text-tables-secondaryIcon">
                                    <BasePermissionBlocker action="serial_number.edit" module="product">
                                        <EditSVG
                                            className={`mr-[18px] ${ability.can("serial_number.edit", "product") ? "cursor-pointer hover:text-tables-highlightedIcon" : "cursor-default"}`}
                                            onClick={() => {
                                                ability.can("serial_number.edit", "product") ? (
                                                    setSelectedSerialNumber(row),
                                                        setIsUpdateModalOpen(true)
                                                ) : null;
                                            }}
                                        />
                                    </BasePermissionBlocker>
                                </div>
                            )
                        }
                    ]}
                    manualControls={{
                        ordering: (newOrdering) => {
                            if (newOrdering) {
                                let name: Product.Augmentations.SerialNumbers.DTO.OrderBy | undefined;

                                switch (newOrdering.index) {
                                    case 0:
                                        name = "id";
                                        break;
                                    case 1:
                                        name = "created_at";
                                        break;
                                    default:
                                        name = undefined;
                                }

                                if (name) {
                                    setOrderBy({
                                        name,
                                        type: newOrdering.order
                                    });
                                } else {
                                    setOrderBy(undefined);
                                }
                            }
                        }
                    }}
                    headerSx={{
                        backgroundColor: "#F9F9F9",
                        "& th": {
                            "&:not(:last-child)": {
                                width: "200px"
                            },
                            "&:first-child": {
                                width: "200px",
                                borderTopLeftRadius: "8px",
                                borderBottomLeftRadius: "8px"
                            },
                            "&:last-child": {
                                textAlign: "end",
                                borderTopRightRadius: "8px",
                                borderBottomRightRadius: "8px"
                            }
                        }
                    }}
                />

                {isCollapsed && <Pagination className="mt-[32px]" {...pagination.adapt(serialNumbers)} />}
            </div>

            <UpdateSerialNumberModal isOpen={isUpdateModalOpen} onClose={() => setIsUpdateModalOpen(false)}
                                     serialNumber={selectedSerialNumber} product={product} />
        </>
    );
}
