import { Integration } from "@/types/general";

type AllowedIntegrationSlug = "tripletex" | "poweroffice";

export const tripletexIntegration: Integration.Components.Integration = {
    name: "Tripletex",
    slug: "tripletex",
} as Integration.Components.Integration;

export const powerOfficeIntegration: Integration.Components.Integration = {
    name: "PowerOffice GO",
    slug: "poweroffice",
} as Integration.Components.Integration;

interface Project {
    id: number;
    name: string;
}

export interface ProjectOption {
    label: string;
    id: number;
    integration: AllowedIntegrationSlug;
    isDisabled?: boolean;
}

export interface GroupedOption {
    readonly label: string;
    readonly options: readonly ProjectOption[];
}

/**
 * Helper function to create a grouped option dynamically using integration details.
 *
 * @param integration - The integration object containing dynamic values.
 * @param projects - The list of projects for the given integration.
 * @returns A GroupedOption with a dynamic label and mapped project options.
 */
export function createGroupedOption(
    integration: Integration.Components.Integration,
    projects: Project[] | undefined
): GroupedOption {
  return {
    label: integration.name, // use dynamic integration name
    options:
        projects?.map((project) => ({
            label: project.name,
            id: project.id,
            integration: integration.slug as AllowedIntegrationSlug
        })) ?? [],
  };
}

/**
 * Type guard to determine if the item is a grouped option.
 */
export function isGroupedOption(
    item: ProjectOption | GroupedOption
): item is GroupedOption {
    return (item as GroupedOption).options !== undefined;
}