import React from "react";
import { FileDown } from "lucide-react";
import { useTranslation } from "react-i18next";
import { BaseCircularProgressWithContent } from "@reusables/BaseCircularProgressWithContent";
import { isDataTransferStateActive } from "@redux/features/dfs/types";
import { useAppSelector } from "@redux/hooks";
import { selectOperation } from "@redux/features/dfs/dfsSlice";

export default function ExportIndicator() {
    const { t } = useTranslation();

    const state = useAppSelector(selectOperation("export"));

    return (
        <>
            {
                isDataTransferStateActive(state) && (
                    <BaseCircularProgressWithContent
                        size={26}
                        variant={"indeterminate"}

                        tooltip={t(`exports.modules.${state.module}.states.inProgress`)}
                    >
                        <FileDown size={12} />
                    </BaseCircularProgressWithContent>
                )
            }
        </>
    );
}
