import React from "react";
import BaseModal from "@reusables/Modals/BaseModal";
import { stopPropagate, toastError } from "@helpers/utils";
import { Controller, useForm, useWatch } from "react-hook-form";
import BaseInput from "@reusables/BaseInput";
import BaseButton from "@reusables/BaseButton";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import BaseMaterialCheckbox from "@reusables/BaseMaterialCheckbox";
import { PackageConfig } from "../../types";
import { useCreatePackageMutation } from "@redux/features/packaging/packagingApi";

import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import BaseInputsGrid from "@reusables/BaseInputsGrid";

type PackageCreationModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onCreate: (data: PackageConfig) => void;
}

const packageCreationSchema = z.object({
    name: z.string().optional(),
    width: z.coerce.number().positive(),
    height: z.coerce.number().positive(),
    length: z.coerce.number().positive(),
    shouldPersist: z.boolean()
}).superRefine((scheme, ctx) => {
    if (scheme.shouldPersist && !scheme.name) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            params: {
                i18n: "general.validation.global.required"
            },
            path: ["name"]
        });
    }
});

type PackageCreationSchemaTyping = z.infer<typeof packageCreationSchema>;

export default function PackageCreationModal(props: PackageCreationModalProps) {
    const { t } = useTranslation("", { keyPrefix: "sales.picking.modals.packagingCreation" });

    const form = useForm<PackageCreationSchemaTyping>({
        resolver: zodResolver(packageCreationSchema),
        defaultValues: {
            shouldPersist: false
        }
    });

    const [createPackage, { isLoading: isPackageCreationLoading }] = useCreatePackageMutation();

    const onSubmit = form.handleSubmit((data) => {
        if (data.shouldPersist) {
            createPackage({
                name: data.name as string,
                width: data.width,
                height: data.height,
                length: data.length
            })
                .unwrap()
                .then(pkg => {
                    props.onCreate(pkg);
                    toast.success(t("responses.success"));
                    form.reset();
                })
                .catch(err => {
                    console.error(err);
                    toastError(err);
                });
        } else {
            props.onCreate({
                id: uuidv4(),
                width: data.width,
                height: data.height,
                length: data.length
            });

            form.reset();
        }

    }, console.error);

    const dimensionLeading = {
        right: {
            el: <span className={"text-accent"}>{t("dimensionUnit")}</span>,
            offset: 16
        }
    };

    const shouldPersist = useWatch({
        control: form.control,
        name: "shouldPersist"
    });

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            padding="56px"
            width={836}
            useCloseIcon
        >
            <form
                onSubmit={stopPropagate(onSubmit)}
                className="space-y-8"
            >
                <h3 className="text-2xl text-center text-accent font-semibold">{t("heading")}</h3>

                <div className="mt-4">
                    <Controller
                        name="name"
                        control={form.control}
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                {...fieldState}
                                label={t("fields.name.label") + (!shouldPersist ? "" : " *")}
                                placeholder={t("fields.name.placeholder")}
                            />
                        )}
                    />
                </div>

                <BaseInputsGrid>

                    <Controller
                        name="width"
                        control={form.control}
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                {...fieldState}
                                type="number"
                                label={t("fields.width.label") + " *"}
                                placeholder={t("fields.width.placeholder")}
                                icon={dimensionLeading}
                                hideArrows
                            />
                        )}
                    />

                    <Controller
                        name="height"
                        control={form.control}
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                {...fieldState}
                                type="number"
                                label={t("fields.height.label") + " *"}
                                placeholder={t("fields.height.placeholder")}
                                icon={dimensionLeading}
                                hideArrows
                            />
                        )}
                    />

                    <Controller
                        name="length"
                        control={form.control}
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                {...fieldState}
                                type="number"
                                label={t("fields.length.label") + " *"}
                                placeholder={t("fields.length.placeholder")}
                                icon={dimensionLeading}
                                hideArrows
                            />
                        )}
                    />

                </BaseInputsGrid>

                <Controller
                    name="shouldPersist"
                    control={form.control}
                    render={({ field }) => (
                        <div
                            className="flex items-center text-accent space-x-2 user-select-none cursor-pointer"
                            onClick={() => {
                                field.onChange(!field.value);
                            }}
                        >
                            <BaseMaterialCheckbox checked={field.value} />
                            <span>
                                {t("fields.shouldPersist.label")}
                            </span>
                        </div>
                    )}
                />


                <BaseButton
                    type="submit"
                    size="large"
                    text={shouldPersist ? t("buttons.saveAndAdd") : t("buttons.add")}
                    buttonWidth="100%"
                    loading={isPackageCreationLoading}
                />

            </form>
        </BaseModal>
    );
}