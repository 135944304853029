import React from "react";
import { useTranslation } from "react-i18next";
import CreationEditingLayout from "../components/CreationEditingLayout";
import { useHistory, useParams } from "react-router-dom";
import ProductStepper from "../components/Tabs/ProductStepper";
import { useGetProductQuery } from "@redux/features/products/productsApi";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import BaseBadge from "@reusables/BaseBadge";
import { ExternalLink, Forklift } from "lucide-react";
import { Can, useAbility } from "@/casl.config";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";
import BaseSyncedFromSourcesBadge from "@reusables/BaseSyncedFromSourcesBadge";

export default function ProductEditingPage() {
    const history = useHistory();

    const ability = useAbility();

    const { t } = useTranslation("", { keyPrefix: "products.viewEditProduct" });

    const { id } = useParams<{ id: string }>();

    const { data: product, isLoading: productLoading } = useGetProductQuery(parseInt(id), {
        skip: ability.cannot("edit", "product")
    });

    return (
        <>
            <Can not I="edit" a="product">
                <div className="levitation-extended">
                    <NoPermissionBanner />
                </div>
            </Can>
            <Can I="edit" a="product">
                <BaseRecordRenderer loading={productLoading} record={product} dontShowNotFound>
                    <div className={"space-y-[40px]"}>
                        <div className="flex mb-[40px]">
                            <div className={"flex flex-row items-center grow space-x-[8px]"}>
                                <div className="flex space-x-2 items-center">
                                    <div
                                        className="flex items-center text-2xl font-bold text-accent">{product?.name}</div>
                                    <BaseSyncedFromSourcesBadge
                                        integrations={[
                                            {
                                                slug: "tripletex",
                                                integrated: () => !!product?.tripletex_id
                                            },
                                            {
                                                slug: "twenty_four_seven_office",
                                                integrated: () => !!product?.twenty_four_seven_office_id
                                            },
                                            {
                                                slug: "lime",
                                                integrated: () => !!product?.lime_id
                                            },
                                            {
                                                slug: "poweroffice",
                                                integrated: () => !!product?.poweroffice_id
                                            }
                                        ]}
                                        width={24}
                                        height={24}
                                    />
                                </div>
                                {product?.is_service && (
                                    <BaseBadge>
                                        <Forklift size={12} className={"mr-1"} />
                                        <span>{t("isService")}</span>
                                    </BaseBadge>
                                )}
                                {!!product?.parent && (
                                    <BaseBadge
                                        className="mx-2 bg-badge-blue cursor-pointer border-1 border-solid border-transparent hover:border-purple-400"
                                        onClick={() => history.push(`/dashboard/products/${product.parent!.id}/details`)}
                                    >
                                        <ExternalLink size={12} className={"mr-1"} />
                                        <span>{t("isVariant")}</span>
                                    </BaseBadge>
                                )}
                            </div>
                        </div>
                        <ProductStepper informationTab={<CreationEditingLayout product={product} />} product={product}
                                        previewMode={false} />
                    </div>
                </BaseRecordRenderer>
            </Can>
        </>
    );
}
