import React, { useEffect, useMemo } from "react";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Controller, useForm, useWatch } from "react-hook-form";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import { Alert, Collapse } from "@mui/material";
import BaseButton from "@reusables/BaseButton";
import { zodResolver } from "@hookform/resolvers/zod";


import { useGetModuleCustomersQuery } from "@redux/features/customers/customersApi";
import { salesOrdersApi, useGetModuleSaleOrdersFullQuery } from "@redux/features/sales/orders/salesOrdersApi";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { isErrorWithMessage } from "@redux/api/query";
import { toast } from "react-toastify";
import BaseDatepicker from "@reusables/BaseDatepicker";
import TableHeading from "@components/Dashboard/pages/Sales/Picking/components/MutationLayout/parts/TableHeading";
import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import TableBody from "@components/Dashboard/pages/Sales/Picking/components/MutationLayout/parts/TableBody";
import { useHistory } from "react-router-dom";
import { OrderStatus, type SaleOrder } from "@/types/general";
import { formScheme, PickingMutationFormTyping } from "./types";
import ShipmondoIntegration
    from "@components/Dashboard/pages/Sales/Picking/components/MutationLayout/parts/ShipmondoIntegration";
import BaseOrderStatusBadge from "@reusables/BaseOrderStatusBadge";
import { isSystemIntegratedSelector } from "@redux/features/auth/authSlice";
import { isOrderDisabled } from "@components/Dashboard/pages/Sales/Orders/utils";
import PackagesDropdown
    from "@components/Dashboard/pages/Sales/Picking/components/MutationLayout/parts/PackagesDropdown";


export interface MutationLayoutProps {
    title: string;
    onMutation: (data: PickingMutationFormTyping) => void;
}

interface RouterStateType {
    saleOrder: Pick<SaleOrder.Root, "id" | "code" | "customer" | "status"> | undefined;
}

export default function MutationLayout({ onMutation, ...props }: MutationLayoutProps) {
    const { t } = useTranslation("", { keyPrefix: "sales.picking.general" });

    const history = useHistory();
    const historyState = history.location.state as RouterStateType | undefined;
    const historySaleOrder = historyState?.saleOrder;

    const dispatch = useAppDispatch();

    const isShipmondoIntegrated = useAppSelector(isSystemIntegratedSelector("shipmondo"));

    const { control, setValue, handleSubmit, formState, resetField, getValues } = useForm<PickingMutationFormTyping>({
        resolver: zodResolver(formScheme),
        defaultValues: {
            lines: [],
            picking_date: {
                date: dayjs()
            },
            extractedFromTransit: false,
            shipmondo: {
                enabled: isShipmondoIntegrated
            }
        }
    });

    // [CUSTOMERS] Loading customers for the dropdown
    const { data: customersOptions, isLoading: isCustomersOptionsLoading } = useGetModuleCustomersQuery("picking");

    const selectedCustomer = useWatch({
        control,
        name: "customer"
    });

    // [SALES ORDERS] Loading sales orders for the dropdown, if [CUSTOMER] is selected
    // aka filtering sales orders by customer
    const {
        data: salesOrdersOptions,
        isFetching: isSalesOrdersFetching,
        isLoading: isSalesOrdersLoading
    } = useGetModuleSaleOrdersFullQuery(
        {
            filters: {
                customers: selectedCustomer ? [selectedCustomer.id] : undefined
            },
            orderBy: [{
                name: "id",
                type: "desc"
            }],
            reactive: true,
            module: "picking"
        },
        {
            skip: !selectedCustomer
        }
    );

    const selectedSaleOrder = useWatch({
        control,
        name: "sale_order"
    }) as SaleOrder.Root;

    const [isExtendedSaleOrderLoading, setIsExtendedSaleOrderLoading] = React.useState(false);

    const isSaleOrderDisabled = isOrderDisabled(selectedSaleOrder);

    const [wasDestinationCountryAutomaticallySelected, setWasDestinationCountryAutomaticallySelected] = React.useState(true);

    // Loading *extended* sale order (from the selected Root SO) data to obtain products with locations and other crucial data
    useEffect(() => {
        if (selectedSaleOrder && !isOrderDisabled(selectedSaleOrder)) {
            setIsExtendedSaleOrderLoading(true);
            dispatch(salesOrdersApi.endpoints.getModuleSaleOrder.initiate({
                module: "picking",
                id: selectedSaleOrder.id
            }))
                .unwrap()
                .then((extendedSO) => {
                    const mappedLines = extendedSO.lines.flatMap((orderLine) => {
                        if (orderLine.type === "line") {
                            // Handle line type
                            return {
                                type: orderLine.type,
                                data: {
                                    id: orderLine.data.id,
                                    comment: orderLine.data.comment,
                                    product: {
                                        id: orderLine.data.product.id,
                                        comment: orderLine.data.comment,
                                        name: orderLine.data.product.name,
                                        code: orderLine.data.product.code,
                                        weight: orderLine.data.product.weight,
                                        is_service: orderLine.data.product.is_service,
                                        is_serial_numbers: orderLine.data.product.is_serial_numbers,
                                        is_batch_numbers: orderLine.data.product.is_batch_numbers,
                                        locations: orderLine.data.product.locations
                                    },
                                    quantity: orderLine.data.quantity,
                                    already_picked_quantity: orderLine.data.picked_quantity ?? 0,
                                    ...(orderLine.data.product.is_service
                                        ? {
                                            picked_quantity: undefined,
                                            picks: []
                                        }
                                        : {
                                            picks: [
                                                {
                                                    outgoing_location: undefined,
                                                    picked_quantity: undefined
                                                }
                                            ]
                                        }),
                                    serial_numbers: [],
                                    batch_numbers: []
                                }
                            };
                        } else if (orderLine.type === "group") {
                            // Handle group type 
                            return [
                                {
                                    type: orderLine.type,
                                    group_id: orderLine.data.id,
                                    module: orderLine.data.module,
                                    name: orderLine.data.name
                                },
                                ...orderLine.data.lines.map((groupLine) => ({
                                    type: "group",
                                    data: {
                                        id: groupLine.data.id,
                                        comment: groupLine.data.comment,
                                        product: {
                                            id: groupLine.data.product.id,
                                            name: groupLine.data.product.name,
                                            code: groupLine.data.product.code,
                                            weight: groupLine.data.product.weight,
                                            is_service: groupLine.data.product.is_service,
                                            is_serial_numbers: groupLine.data.product.is_serial_numbers,
                                            is_batch_numbers: groupLine.data.product.is_batch_numbers,
                                            locations: groupLine.data.product.locations
                                        },
                                        quantity: groupLine.data.quantity,
                                        already_picked_quantity: groupLine.data.picked_quantity ?? 0,
                                        ...(groupLine.data.product.is_service
                                            ? {
                                                picked_quantity: undefined,
                                                picks: []
                                            }
                                            : {
                                                picks: [
                                                    {
                                                        outgoing_location: undefined,
                                                        picked_quantity: undefined
                                                    }
                                                ]
                                            }),
                                        serial_numbers: [],
                                        batch_numbers: []
                                    }
                                }))
                            ];
                        }
                        return [];
                    });

                    setValue("lines", mappedLines.flat() as PickingMutationFormTyping["lines"]);

                    if (extendedSO.delivery_address?.country || (extendedSO.is_billing_for_delivery && extendedSO.billing_address.country)) {
                        const country = extendedSO.is_billing_for_delivery ? extendedSO.billing_address.country : extendedSO.delivery_address?.country;

                        setValue("order_destination_country", country);
                        setWasDestinationCountryAutomaticallySelected(true);
                    } else {
                        console.warn("Unable to determine the destination country of the order");
                        setWasDestinationCountryAutomaticallySelected(false);
                    }
                })
                .catch((e) => {
                    if (isErrorWithMessage(e)) {
                        toast.error(e);
                    } else {
                        toast.error(t("responses.unableToLoadExtendedSO"));
                    }
                })
                .finally(() => void setIsExtendedSaleOrderLoading(false));
        } else {
            resetField("lines");
            resetField("shipmondo");
        }
    }, [selectedSaleOrder]);

    useEffect(() => {
        if (!getValues("extractedFromTransit")) {
            resetField("sale_order");
            resetField("shipmondo");
            resetField("order_destination_country");
        } else {
            setValue("extractedFromTransit", false);
        }
    }, [selectedCustomer]);

    // Setting data from the history sale order state (used for quick picking)
    useEffect(() => {
        if (historySaleOrder) {
            setValue(
                "customer",
                {
                    id: historySaleOrder.customer.id,
                    code: historySaleOrder.customer.code,
                    name: historySaleOrder.customer.name
                },
                { shouldValidate: false }
            );

            setValue(
                "sale_order",
                {
                    id: historySaleOrder.id,
                    code: historySaleOrder.code,
                    status: historySaleOrder.status
                },
                { shouldValidate: false }
            );

            setValue("extractedFromTransit", true, { shouldValidate: false });
        }
    }, [historySaleOrder]);

    // Just a workaround to operate with custom errors like "serial_numbers" and "batch_numbers" added via superRefine in ZOD
    const serialAndBatchNumberErrors = useMemo(() => {
        const linesErrors = formState.errors.lines as {
            serial_numbers?: { message: string };
            batch_numbers?: { message: string };
        };


        if (linesErrors) {
            return {
                ...(!!linesErrors.serial_numbers && { serial_numbers: linesErrors.serial_numbers.message }),
                ...(!!linesErrors.batch_numbers && { batch_numbers: linesErrors.batch_numbers.message })
            };
        }
    }, [formState]);

    const onSubmit = handleSubmit((data) => {
        onMutation?.({
            ...data,
            lines: data.lines.filter((line) => !!line.data)
        });
    }, console.error);


    return (
        <>
            <form onSubmit={onSubmit}>
                <div className={"space-y-[40px]"}>
                    <div className="levitation-extended space-y-[40px]">
                        <div className="flex items-center">
                            <h6 className="text-accent grow">{props.title}</h6>
                        </div>

                        {/* Picking header */}
                        <BaseInputsGrid>
                            {/* Supplier */}
                            <Controller
                                name="customer"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <BaseDropdown
                                        {...field}
                                        {...fieldState}
                                        label={t("fields.customer.label")}
                                        placeholder={t("fields.customer.placeholder")}
                                        options={customersOptions}
                                        getter={{
                                            label: (opt) => opt.name,
                                            key: (opt) => opt.id,
                                            caption: (opt) => opt.code ?? opt.id
                                        }}
                                        isLoading={isCustomersOptionsLoading}
                                        autocomplete

                                    />
                                )}
                            />

                            {/* Purchase order */}
                            <Controller
                                name="sale_order"
                                control={control}
                                render={({ field, fieldState }) => (
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    <BaseDropdown
                                        {...field}
                                        {...fieldState}
                                        label={t("fields.saleOrder.label")}
                                        placeholder={t("fields.saleOrder.placeholder")}
                                        options={salesOrdersOptions?.payload ?? []}
                                        getter={{
                                            label: (opt) => opt.code ?? `${opt.id}`,
                                            key: (opt) => opt.id,
                                            renderOption: (opt) => (
                                                <div className="flex justify-between">
                                                    <div>{opt.code}</div>
                                                    <BaseOrderStatusBadge status={opt.status.name} />
                                                </div>
                                            )
                                        }}
                                        isLoading={isSalesOrdersLoading || isSalesOrdersFetching}
                                        autocomplete
                                    />
                                )}
                            />

                            {/* Picking date*/}
                            <Controller
                                name="picking_date.date"
                                control={control}
                                render={({ field, fieldState }) => <BaseDatepicker {...field} {...fieldState}
                                                                                   label={t("fields.pickingDate.label")}
                                                                                   placeholder={t("fields.pickingDate.placeholder")} />}
                            />
                        </BaseInputsGrid>

                        <div className={"space-y-[10px]"}>
                            {/* Error for the general "pick more than one" error */}
                            {
                                <Collapse in={!!formState.errors.lines?.root}>
                                    <Alert severity="error">{formState.errors?.lines?.root?.message}</Alert>
                                </Collapse>
                            }

                            {/* Error for bad SERIALS filling */}
                            {
                                <Collapse in={!!serialAndBatchNumberErrors?.serial_numbers}>
                                    <Alert severity="error">{serialAndBatchNumberErrors?.serial_numbers}</Alert>
                                </Collapse>
                            }

                            {/* Error for bad BATCHES filling */}
                            {
                                <Collapse in={!!serialAndBatchNumberErrors?.batch_numbers}>
                                    <Alert severity="error">{serialAndBatchNumberErrors?.batch_numbers}</Alert>
                                </Collapse>
                            }
                            {/* Error for bad PICKS total quantity */}
                            {/* {formState.errors.lines && (
                                <Collapse in={!!formState.errors.lines}>
                                    {Object.entries(formState.errors.lines).map(([lineIndex, lineErrors]) => (
                                        <div key={lineIndex}>
                                            {lineErrors?.picks?.map((pickError, pickIndex) => (
                                            <Alert key={pickIndex} severity="error">
                                                {pickError?.picked_quantity?.message || 'An error occurred'}
                                            </Alert>
                                            ))}
                                        </div>
                                    ))}
                                </Collapse>
                            )} */}
                        </div>

                        {/* Product table heading*/}
                        {/*Product table body*/}
                        <BaseLoadingBlocker active={isExtendedSaleOrderLoading}>
                            <div className="border border-solid border-gray-300 rounded-[8px] p-[16px]">
                                <TableHeading control={control} onLinesUpdate={(lines) => setValue("lines", lines)} />
                                {selectedSaleOrder ? (
                                    <>
                                        {isSaleOrderDisabled ? (
                                            <div
                                                className="flex items-center justify-center text-xl font-thin text-notFound-blocks uppercase"
                                                style={{ height: 300 }}
                                            >
                                                {selectedSaleOrder.status.name === OrderStatus.Base.cancelled && (
                                                    <span>{t("fields.saleOrder.status.cancelled")}</span>
                                                )}
                                                {selectedSaleOrder.status.name === OrderStatus.Base.completed && (
                                                    <span>{t("fields.saleOrder.status.completed")}</span>
                                                )}
                                            </div>
                                        ) : (
                                            <TableBody control={control} />
                                        )}
                                    </>
                                ) : (
                                    <TableBody control={control} />
                                )}
                            </div>
                        </BaseLoadingBlocker>

                        <BaseInputsGrid>
                            <PackagesDropdown control={control} setValue={setValue} />
                        </BaseInputsGrid>
                    </div>

                    {isShipmondoIntegrated && (
                        <div className={"levitation-extended"}>
                            {
                                <ShipmondoIntegration
                                    control={control}
                                    resetField={resetField}
                                    setValue={setValue}
                                    wasDestinationCountryAutomaticallySelected={wasDestinationCountryAutomaticallySelected}
                                />
                            }
                        </div>
                    )}

                    <div className="flex justify-center">
                        <BaseButton text={t("buttons.save")} size="md" buttonWidth="200px"
                                    disabled={isSaleOrderDisabled}
                        />
                    </div>
                </div>
            </form>

        </>
    );
}
