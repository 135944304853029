import React from "react";
import BaseModal from "@reusables/Modals/BaseModal";
import { useGetModuleLocationsQuery } from "@redux/features/locations/locationsApi";
import { z } from "zod";
import { Control, Controller, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import BaseButton from "@reusables/BaseButton";
import { ReactComponent as CustomerIcon } from "@assets/icons/sidebar/ic_person.svg";
import { ReactComponent as AllProductsIcon } from "@assets/icons/sidebar/ic_small_bag.svg";
import { ReactComponent as SuppliersIcon } from "@assets/icons/sidebar/ic_package_delivery.svg";
import { ReactComponent as OrdersIcon } from "@assets/images/icons/dashboard/orders.svg";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { Alert, Collapse } from "@mui/material";
import { useImportTripletexDataMutation } from "@redux/features/integrations/integrationsApi";
import SelectableImportCategory from "./SelectableImportCategory";
import { refinements } from "@/helpers/refinements";
import { Tripletex } from "@/types/general";
import { selectOperation } from "@redux/features/dfs/dfsSlice";
import { isDataTransferStateActive } from "@/redux/features/dfs/types";
import { performImport } from "@redux/features/dfs/thunks";

interface TripletexImportModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const formSchema = z.object({
    shouldImportCustomers: z.boolean(),
    shouldImportSuppliers: z.boolean(),
    shouldImportProducts: z.boolean(),
    shouldImportOrders: z.boolean(),
    location: z.object({
        store: z.object({
            id: z.number(),
            name: z.string()
        }),
        section: z.object({
            id: z.number(),
            name: z.string()
        }).nullish()
    }).nullish()
}).superRefine((data, ctx) => {
    if (data.shouldImportProducts && !data.location) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            params: refinements.required.message.params,
            path: ["location"]
        });
    }
});

export type TripletexImportFormTyping = z.infer<typeof formSchema>;

export default function TripletexImportModal({ isOpen, onClose }: TripletexImportModalProps) {
    const { t } = useTranslation("", { keyPrefix: "imports.modules.tripletex.importModal" });

    const dispatch = useAppDispatch();

    const [importTripletex, { isLoading: isImportLoading }] = useImportTripletexDataMutation();

    const isImportInProgress = useAppSelector(state => isDataTransferStateActive(selectOperation("import")(state)));

    const { data: locationsOptions, isLoading: isLocationsOptionsLoading } = useGetModuleLocationsQuery("integrations");

    const form = useForm<TripletexImportFormTyping>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            shouldImportCustomers: false,
            shouldImportSuppliers: false,
            shouldImportProducts: false,
            shouldImportOrders: false
        }
    });

    const onSubmit = form.handleSubmit((data) => {
        dispatch(
            performImport("tripletex", () => {
                const entities: Tripletex.Components.AvailableEntities[] = [];

                if (data.shouldImportCustomers) {
                    entities.push("customer");
                }

                if (data.shouldImportSuppliers) {
                    entities.push("supplier");
                }

                if (data.shouldImportProducts) {
                    entities.push("product");
                }

                if (data.shouldImportOrders) {
                    entities.push("sale_order");
                }

                return importTripletex({
                    entities: entities,
                    ...(data.shouldImportProducts && data.location && {
                        default_product_location: {
                            store_id: data.location.store.id,
                            section_id: data.location.section?.id
                        }
                    })

                }).unwrap();
            })
        );

        form.reset();
        onClose();
    }, console.error);

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={onClose}
            width={800}
            padding="45px"
            useCloseIcon
            isLoading={isImportLoading}
        >
            <form onSubmit={onSubmit}>
                <div className="text-xl font-semibold text-center text-accent mb-4">
                    {t("heading")}
                </div>

                <Collapse in={isImportInProgress}>
                    <Alert severity={"warning"} className={"mb-4"}>
                        {
                            t("importIsAlreadyInProgressAlert")
                        }
                    </Alert>
                </Collapse>

                {/* Rules */}
                <div className={"space-y-2 mb-4"}>
                    <div className="font-semibold text-inputs-label-dim">
                        {t("rules.label")}:
                    </div>
                    <ul className={"list-disc ml-4 text-accent"}>
                        <li>{t("rules.bankAccount")};</li>
                        <li>{t("rules.sync")};</li>
                    </ul>
                </div>

                {/* What to import */}
                <div className={"space-y-2 mb-4"}>
                    <div className="font-semibold text-inputs-label-dim">
                        {t("importSelection.label")}
                    </div>
                    <div className={"space-y-2 text-accent"}>
                        <Controller
                            name={"shouldImportCustomers"}
                            control={form.control}
                            render={({ field }) => (
                                <SelectableImportCategory
                                    isSelected={field.value}
                                    onChange={field.onChange}
                                    text={t("importSelection.customers")}
                                    icon={<CustomerIcon width={22} height={22} />}
                                />
                            )}
                        />

                        <Controller
                            name={"shouldImportSuppliers"}
                            control={form.control}
                            render={({ field }) => (
                                <SelectableImportCategory
                                    isSelected={field.value}
                                    onChange={field.onChange}
                                    text={t("importSelection.suppliers")}
                                    icon={<AllProductsIcon width={22} height={22} />}
                                />
                            )}
                        />

                        <Controller
                            name={"shouldImportProducts"}
                            control={form.control}
                            render={({ field }) => (
                                <SelectableImportCategory
                                    isSelected={field.value}
                                    onChange={field.onChange}
                                    text={t("importSelection.products")}
                                    icon={<SuppliersIcon width={24} height={24} />}
                                >
                                    <div>
                                        <Controller
                                            name={"location"}
                                            control={form.control}
                                            render={({ field, fieldState }) => (
                                                <BaseDropdown
                                                    {...field}
                                                    error={fieldState.error}

                                                    label={t("locationDropdown.label") + " *"}
                                                    placeholder={t("locationDropdown.placeholder")}
                                                    options={locationsOptions}
                                                    getter={{
                                                        label: (opt) => `${opt.store.name}${opt.section ? ` - ${opt.section.name}` : ""}`,
                                                        key: (opt) => (opt.section ? `${opt.store.id}_${opt.section.id}` : `${opt.store.id}`),
                                                        renderOption: (opt, icon) => (
                                                            <div>
                                                                <div className="grow">
                                                                    <span
                                                                        className="bold-highlight">{opt.store.name}</span>
                                                                    {opt.section ? <> - {opt.section.name}</> : null}
                                                                </div>
                                                                {icon}
                                                            </div>
                                                        )
                                                    }}

                                                    isLoading={isLocationsOptionsLoading}

                                                    autocomplete
                                                    virtualize
                                                />
                                            )}
                                        />
                                    </div>
                                </SelectableImportCategory>
                            )}
                        />

                        <Controller
                            name={"shouldImportOrders"}
                            control={form.control}
                            render={({ field }) => (
                                <SelectableImportCategory
                                    isSelected={field.value}
                                    onChange={field.onChange}
                                    text={t("importSelection.orders")}
                                    icon={<OrdersIcon width={24} height={24} />}
                                />
                            )}
                        />
                    </div>
                </div>

                <div className={"mt-8 grid grid-cols-2 gap-2"}>
                    <BaseButton
                        buttonWidth="100%"
                        text={t("buttons.cancel")}
                        size="md"
                        type="button"
                        primaryOutlined
                        onClick={onClose}
                    />
                    <ImportButtonDisabler control={form.control}>
                        {(allowImport) => (
                            <BaseButton
                                buttonWidth="100%"
                                text={t("buttons.import")}
                                size="md"
                                type="submit"
                                disabled={!allowImport}
                            />
                        )}
                    </ImportButtonDisabler>
                </div>
            </form>
        </BaseModal>
    );
}

function ImportButtonDisabler(
    {
        control,
        children
    }: {
        control: Control<TripletexImportFormTyping>,
        children: (allowImport: boolean) => JSX.Element
    }
) {
    const shouldImportCustomers = useWatch({ control, name: "shouldImportCustomers" });
    const shouldImportSuppliers = useWatch({ control, name: "shouldImportSuppliers" });
    const shouldImportProducts = useWatch({ control, name: "shouldImportProducts" });
    const shouldImportOrders = useWatch({ control, name: "shouldImportOrders" });


    const allowImport = shouldImportCustomers || shouldImportSuppliers || shouldImportProducts || shouldImportOrders;

    return children(allowImport);
}