import React, { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { calculateOrderPrice, formatPriceWithCurrency } from "@helpers/utils";
import { isGroup, isLine, LineSchema, PurchaseOrdersFormTyping } from "../../types";

export default function SummaryBlock() {
    const { t } = useTranslation("", { keyPrefix: "sales.orders.general.orders" });
    const { control } = useFormContext<PurchaseOrdersFormTyping>();

    // Watch the entire "lines" array.
    const lines = useWatch({ control, name: "lines" });

    const currency = useWatch({ control, name: "currency" });

    // Flatten the lines array: if an item is a group, push its contained lines.
    const flatLines = useMemo(() => {
        return (lines || []).reduce((acc, item) => {
            if (isLine(item)) {
                acc.push(item);
            } else if (isGroup(item)) {
                acc.push(...item.lines);
            }
            return acc;
        }, [] as LineSchema[]);
    }, [lines]);

    const linesSummary = useMemo(() => {
        let subtotal = 0;
        let taxes = 0;

        flatLines.forEach((line) => {
            const quantity = line.quantity;
            const unitPrice = line.unit_price;
            const discount = line.discount ?? 0;
            const taxRate = line.tax?.rate ?? 0;

            const linePrice = calculateOrderPrice(quantity, unitPrice.converted, discount);
            subtotal += linePrice;
            taxes += linePrice * (taxRate / 100);
        });

        return {
            subtotal,
            taxes,
            totalWithTaxes: subtotal + taxes
        };
    }, [flatLines]);

    return (
        <div className="flex justify-end mt-[48px] font-thin">
            <div className="w-1/2 grid grid-cols-2 gap-y-[16px]">
                <div className="text-right">{t("summary.subtotal")}</div>
                <div className="text-right pr-[16px]">
                    {formatPriceWithCurrency(linesSummary.subtotal, currency)}
                </div>
                <div className="text-right">{t("summary.taxes")}</div>
                <div className="text-right pr-[16px]">
                    {formatPriceWithCurrency(linesSummary.taxes, currency)}
                </div>
                <div className="text-right font-semibold text-accent">{t("summary.total")}</div>
                <div className="text-right pr-[16px] font-semibold text-accent">
                    {formatPriceWithCurrency(linesSummary.totalWithTaxes, currency)}
                </div>
            </div>
        </div>
    );
}
