import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { FileUp, XCircle } from "lucide-react";
import { useTranslation } from "react-i18next";
import BaseModal from "@reusables/Modals/BaseModal";
import { ReactComponent as SuccessSVG } from "@assets/icons/ic_success.svg";
import { BaseCircularProgressWithContent } from "@reusables/BaseCircularProgressWithContent";
import { resetOperation, selectOperation } from "@redux/features/dfs/dfsSlice";
import { isDataTransferStateActive, isDataTransferStateFinished, selectProgressMean } from "@redux/features/dfs/types";

const operation = "import";

export default function ImportIndicator() {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const state = useAppSelector(selectOperation(operation));

    const [isResultModalOpen, setIsResultModalOpen] = useState(false);

    return (
        <>
            {
                isDataTransferStateActive(state) && (
                    <BaseCircularProgressWithContent
                        size={26}
                        {
                            ...state.progress && {
                                // Adding the percentage value to the progress bar, if progress was specified
                                value: selectProgressMean(state) * 100,
                                variant: "indeterminate"
                            }
                        }

                        tooltip={t(`imports.modules.${state.module}.states.inProgress`)}
                    >
                        <FileUp size={12} />
                    </BaseCircularProgressWithContent>
                )
            }
            {
                isDataTransferStateFinished(state) && (
                    <>
                        <BaseCircularProgressWithContent
                            size={26}
                            variant="determinate"
                            value={100}

                            sx={{
                                color: state.has_errors ? "#E25C5C" : "#A6DD4C"
                            }}

                            onClick={() => setIsResultModalOpen(true)}
                        >
                            <FileUp size={12} />
                        </BaseCircularProgressWithContent>

                        {
                            state.has_errors ? (
                                <BaseModal
                                    isOpen={isResultModalOpen}
                                    onClose={() => {
                                        dispatch(resetOperation({ operation }));
                                        setIsResultModalOpen(false);
                                    }}
                                    padding="40px"
                                    position="center"
                                >
                                    <div className="flex items-center gap-4">
                                        <XCircle width={40} height={40} fill="#FF0000" color="#FFF" />
                                        <div className="flex flex-col items-start space-y-4">
                                            <div className="text-accent font-bold text-xl text-center">
                                                {t(`imports.modules.${state.module}.states.error.title`)}
                                            </div>
                                            <div
                                                className="text-accent text-lg">{t(`imports.modules.${state.module}.states.error.message`)}</div>
                                        </div>

                                    </div>
                                </BaseModal>
                            ) : (
                                <BaseModal
                                    isOpen={isResultModalOpen}
                                    onClose={() => {
                                        dispatch(resetOperation({ operation }));
                                        setIsResultModalOpen(false);
                                    }}
                                    padding="40px"
                                    position="center"
                                >
                                    <div className="flex items-center gap-4">
                                        <SuccessSVG width={40} height={40} />
                                        <div
                                            className="text-accent font-bold text-xl text-center">{t(`imports.modules.${state.module}.states.imported`)}</div>
                                    </div>
                                </BaseModal>
                            )
                        }
                    </>
                )
            }
        </>
    );
}

