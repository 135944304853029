import { DFS } from "@/types/general";

type DataTransferOperations = DFS.Components.DataTransferOperations;

enum DataTransferProgressStatus {
    IDLE = "idle",
    ACTIVE = "active",
    FINISHED = "finished",
}

interface DataTransferStateBase {
    operation: DataTransferOperations;
    status: DataTransferProgressStatus;
    module?: string;
}

interface DataTransferStateIdle extends DataTransferStateBase {
    status: DataTransferProgressStatus.IDLE;
}

interface DataTransferStateActive extends DataTransferStateBase {
    status: DataTransferProgressStatus.ACTIVE;
    progress?: { total: number; current: number }[];
}

interface DataTransferStateFinished extends DataTransferStateBase {
    status: DataTransferProgressStatus.FINISHED;
    has_errors: boolean;
    data?: any;
}

type DataTransferState = DataTransferStateIdle | DataTransferStateActive | DataTransferStateFinished;

function isDataTransferStateActive(state?: DataTransferState): state is DataTransferStateActive {
    return state?.status === DataTransferProgressStatus.ACTIVE;
}

function isDataTransferStateFinished(state?: DataTransferState): state is DataTransferStateFinished {
    return state?.status === DataTransferProgressStatus.FINISHED;
}

/**
 * A utility selector to get the mean progress of all active imports
 * @param state active import object
 */
const selectProgressMean = (state: DataTransferStateActive) => {
    const { progress } = state;

    if (!progress?.length) return 0;

    const totalProgress = progress.reduce((sum, p) => sum + p.current, 0);
    const totalTotal = progress.reduce((sum, p) => sum + p.total, 0);
    return totalTotal === 0 ? 0 : totalProgress / totalTotal;
};

export { isDataTransferStateActive, isDataTransferStateFinished, selectProgressMean, DataTransferProgressStatus };
export type {
    DataTransferState,
    DataTransferStateIdle,
    DataTransferStateActive,
    DataTransferStateFinished,
    DataTransferOperations
};