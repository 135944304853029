import React from "react";
import { LineSchema } from "../../types";
import { AlertOctagon } from "lucide-react";
import BaseTooltip from "@reusables/BaseTooltip";
import { useTranslation } from "react-i18next";

export default function QuantityCell({ line }: { line: LineSchema }) {
    const { t } = useTranslation("", { keyPrefix: "sales.orders.general.orders.quantityCell" });

    return (
        <div className="flex flex-col space-y-2">
            <div className="flex items-center space-x-2">
                <div>{line.quantity}</div>
                {
                    (line.product.in_stock ?? 0) < line.quantity && (
                        <BaseTooltip title={`${t("lowStockWarning", { inStock: line.product.in_stock })}`}>
                            <AlertOctagon size={14} color="orange" className="mb-1" />
                        </BaseTooltip>
                    )
                }
            </div>
        </div>
    );
}
