import { PurchaseOrder, Supplier, Tax } from "@/types/general";

export interface Line {
    id?: number;
    discount?: number;
    unit_price?: number;
    quantity: number;
    product: {
        id: number;
        name: string;
        code: string;

        supplier?: Supplier.Slim;
    };

    tax?: Tax.Slim;
}

export interface GroupLine {
    name: string;
    comment?: string;
    module: "bom" | "collection" | "custom";
    module_entity_id: number;
    lines: Line[];
}
export type SOOrder = Line | GroupLine;

export function isGroupOrder(item: SOOrder): item is GroupLine {
    return (item as GroupLine).module !== undefined;
}

export interface FlattenedLine {
    data: PurchaseOrder.Components.LineData;
}

export function flattenLines(lines: PurchaseOrder.Components.Lines): FlattenedLine[] {
    return lines.flatMap(line => {
        if (line.type === "line") {
            return [{ data: line.data }];
        } else if (line.type === "group") {
            return line.data?.lines;
        }
        return [];
    });
}