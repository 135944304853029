import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BaseButton from "@reusables/BaseButton";
import BaseInput from "@reusables/BaseInput";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import BaseModal from "@reusables/Modals/BaseModal";
import BaseDropdown, { adaptInfiniteLoader } from "@reusables/dropdowns/BaseDropdown";
import BaseInfiniteLoader from "@reusables/dropdowns/BaseInfiniteLoader";
import i18n from "@/i18n";
import { ReactComponent as TrashSVG } from "@assets/icons/ic_trash.svg";
import { isErrorWithMessage } from "@redux/api/query";
import { productsApi } from "@redux/features/products/productsApi";
import { useAppDispatch } from "@redux/hooks";
import { Product } from "@/types/general";
import { zodResolver } from "@hookform/resolvers/zod";
import _ from "lodash";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { z } from "zod";
import { BOMFormComponentTyping } from "@components/Dashboard/pages/BOM/components/MutationLayout";

const formScheme = z.object({
    components: z.array(z.object({
        selectedComponent: z.object({
            id: z.number(),
            name: z.string(),
            code: z.string(),
            prices: z.object({
                purchase_price: z.number()
            }),
            in_stock: z.number().nullish().optional(),
            is_component: z.boolean().nullish(),
        }),
        quantity: z.coerce.number().min(1),
    }))
}).superRefine((data, ctx) => {
    const ids = data.components.map(c => c.selectedComponent.id);
    if (ids.length !== _.uniq(ids).length) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            params: {
                i18n: "bom.mutation.modals.errors.duplicateComponents"
            },
            path: ["components"]
        });
    }
});

type FormTyping = z.infer<typeof formScheme>;

interface MutationLayoutProperties {
    isOpen: boolean;
    onSubmit: (model: FormTyping) => void;
    onClose: () => void;
    component?: BOMFormComponentTyping;
    alreadySelectedCompIds: number[];
}

export default function ComponentMutationModal(props: MutationLayoutProperties) {
    const { t } = useTranslation("", { keyPrefix: "bom.mutation.modals" });

    const dispatch = useAppDispatch();

    const { control, handleSubmit, reset } = useForm<FormTyping>({
        resolver: zodResolver(formScheme),
        defaultValues: {
            components: [{}]
        }
    });

    const { fields: components, append, remove } = useFieldArray({
        control,
        name: "components"
    });

    useEffect(() => {
        reset();
        if (props.component) {
            reset({
                components: [
                    {
                        selectedComponent: {
                            id: props.component.id,
                            name: props.component.name,
                            code: props.component.code,
                            prices: {
                                purchase_price: props.component.prices.purchase_price
                            },
                            in_stock: props.component.in_stock
                        },
                        quantity: props.component.quantity
                    }
                ]
            });
        } else {
            reset({
                components: [{}]
            });
        }
    }, [props.isOpen, reset, props.component]);

    const onSubmit = handleSubmit((data) => {
        props.onSubmit(data);
    });

    const [isProductLazyLoadingFetching, setIsProductLazyLoadingFetching] = useState(false);
    const [productLazyLoadingPayloadWithoutAlreadyAddedDuplicates, setProductLazyLoadingPayloadWithoutAlreadyAddedDuplicates] = useState<Product.Root[]>([]);

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={928}
            padding="56px"
        >
            <form className="space-y-[40px]" onSubmit={onSubmit}>
                <div className="flex justify-between items-center text-xl font-semibold text-center text-accent">
                    <div>{props.component ? t("editComponent") : t("selectComponent")}</div>
                    {
                        !props.component && (
                            <BaseMaterialButton
                                type="button"
                                onClick={() => append({} as any)}
                            >{t("addNewComponent")}</BaseMaterialButton>
                        )
                    }
                </div>

                {components?.map((component, index) =>
                    <div key={index}>
                        <BaseInputsGrid cols={2}>
                            <Controller
                                // disabled={!!props.component}
                                control={control}
                                name={`components.${index}.selectedComponent`}
                                render={({ field, fieldState }) => (
                                    <BaseInfiniteLoader
                                        fetch={(search, page, limit) => {
                                            setIsProductLazyLoadingFetching(true);
                                            dispatch(productsApi.endpoints.getProductsFull.initiate(
                                                    {
                                                        filters: {
                                                            search,
                                                            components: 1
                                                        },
                                                        pagination: {
                                                            page,
                                                            limit
                                                        }
                                                    },
                                                    {
                                                        subscribe: false
                                                    }
                                                )
                                            ).unwrap().then(result => {
                                                const productsToFilter = result.payload;

                                                // Checking if productsOptions is truthy to proceed, otherwise return an empty array
                                                if (!productsToFilter) return [];

                                                // Transforming the list of already selected product IDs into an object for O(1) access
                                                const selectedProdIdsMap = _.keyBy(props.alreadySelectedCompIds);

                                                // Filtering out products that are already selected
                                                setProductLazyLoadingPayloadWithoutAlreadyAddedDuplicates(productsToFilter.filter(
                                                    (product) => !selectedProdIdsMap[product.id]
                                                ));
                                            })
                                                .catch(e => {
                                                    if (isErrorWithMessage(e)) {
                                                        toast.error(e.message);
                                                    } else {
                                                        toast.error(i18n.t("general.responses.somethingWentWrong"));
                                                    }
                                                })
                                                .finally(() => {
                                                    setIsProductLazyLoadingFetching(false);
                                                });
                                        }}
                                        limit={100}
                                        result={productLazyLoadingPayloadWithoutAlreadyAddedDuplicates}
                                        isLoading={isProductLazyLoadingFetching}
                                    >
                                        {
                                            (infinity) => (
                                                <>
                                                    <BaseDropdown
                                                        {...field}
                                                        error={fieldState.error?.message}

                                                        {...adaptInfiniteLoader(infinity)}

                                                        label={t("component.label")}
                                                        placeholder={t("component.placeholder")}
                                                        getter={{
                                                            key: (opt) => opt.id,
                                                            label: (opt) => opt.name,
                                                            caption: (opt) => opt.code
                                                        }}
                                                    />
                                                </>
                                            )
                                        }
                                    </BaseInfiniteLoader>
                                )}
                            />

                            <Controller
                                control={control}
                                name={`components.${index}.quantity`}
                                render={({ field, fieldState }) => (
                                    <div className="relative">
                                        <BaseInput
                                            {...field}
                                            error={fieldState.error}
                                            label={t("quantity")}
                                            type="number"
                                        />
                                        <div
                                            className="absolute right-0 top-0 text-tables-secondaryIcon hover:text-accent opa cursor-pointer">
                                            {!props.component && index !== 0 && (
                                                <TrashSVG
                                                    onClick={() => remove(index)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                            />
                        </BaseInputsGrid>
                    </div>
                )}

                <BaseInputsGrid cols={2}>
                    <BaseButton
                        text={t("buttons.cancel")}
                        size="md"
                        buttonWidth="100%"
                        type="button"
                        primaryOutlined
                        onClick={props.onClose}
                    />

                    <BaseButton
                        text={t("buttons.save")}
                        size="md"
                        buttonWidth="100%"
                        type="submit"
                    />
                </BaseInputsGrid>
            </form>
        </BaseModal>
    );
}