import React from "react";
import { calculateOrderPrice, normalizePrice } from "@helpers/utils";
import { LineSchema } from "../../types";

export default function TotalCell({ line }: { line: LineSchema }) {
    return <>
        {
            normalizePrice(calculateOrderPrice(line.quantity ?? 0, line.unit_price ?? 0, line.discount ?? 0))
        }
    </>;
}