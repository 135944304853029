import React from "react";
import { useTranslation } from "react-i18next";
import { ArrayElementType, calculateOrderPrice, cn, formatPriceWithCurrency, normalizePrice } from "@helpers/utils";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    useReactTable
} from "@tanstack/react-table";
import { Currency, PurchaseOrder } from "@/types/general";
import { ExternalLink } from "lucide-react";
import { ReactComponent as CollectionsIcon } from "@assets/icons/sidebar/ic_folders.svg";
import BaseBadge from "@reusables/BaseBadge";
import BaseTableV2 from "@/components/reusables/BaseTableV2";
import { useAbility } from "@/casl.config";
import { Link } from "react-router-dom";

type DetailsLinesRendererProps = {
    currency: Currency.Root;
    lines: PurchaseOrder.Components.Lines;
}

function isGroup(line: ArrayElementType<PurchaseOrder.Components.Lines>): line is PurchaseOrder.Components.Group {
    return line.type === "group";
}

function isLine(line: ArrayElementType<PurchaseOrder.Components.Lines>): line is PurchaseOrder.Components.Line {
    return line.type === "line";
}

export default function DetailsLinesRenderer({ lines, currency }: DetailsLinesRendererProps) {
    const { t } = useTranslation("", { keyPrefix: "sales.orders.general" });

    const columnHelper = createColumnHelper<ArrayElementType<PurchaseOrder.Components.Lines>>();

    const ability = useAbility();

    const table = useReactTable({
        data: lines,
        columns: [
            columnHelper.accessor(row => isGroup(row) ? row.data.name : row.data.product.code, {
                id: "product_code",
                cell: info => {
                    const row = info.row.original;
                    if (isLine(row)) {
                        return <div className={"underline"}>
                            <Link
                                to={`/dashboard/products/${row.data.product.id}/details`}>{row.data.product.code}</Link>
                        </div>;

                    } else {
                        return null;
                    }
                },
                maxSize: 150
            }),

            columnHelper.accessor((row) => isLine(row) ? row.data.product.name : "", {
                id: "product_name",
                cell: info => {
                    const row = info.row.original;

                    if (isLine(row)) return row.data.product.name;

                    return (
                        <div className={"flex flex-col pl-3 space-y-[8px]"} style={{ borderLeft: "3px solid #B1B2E1" }}>
                            <div className="flex items-center space-x-2">
                                <div className="font-semibold">{row.data.name}</div>
                                {
                                    (row.data.module === "collection" || row.data.module === "bom") &&
                                    <BaseBadge
                                        className="px-2 bg-[#CDCFFD] rounded-xl cursor-pointer hover:opacity-70"
                                        onClick={() => {
                                            if (row.data.module === "collection") {
                                                window.open(`/dashboard/inventory/collections/${row.data.module_entity_id}/edit`);
                                            } else {
                                                window.open(`/dashboard/bill-of-materials/${row.data.module_entity_id}/details`);
                                            }
                                        }}
                                    >
                                        <div className="flex items-center">
                                            <CollectionsIcon className="w-[12px] mr-1 bg-[#CDCFFD] text-accent" />
                                            <span>{t(`orders.groups.types.${row.data.module}`)}</span>
                                            <ExternalLink size={10} className="ml-2 text-sm text-accent" />
                                        </div>
                                    </BaseBadge>
                                }
                            </div>
                            {
                                !!row.data.comment &&
                                <div className={"text-[#5A5587] text-sm font-thin"}>{row.data.comment}</div>
                            }
                        </div>
                    );
                },
                meta: {
                    forGroup: true
                }
            }),
            columnHelper.accessor(row => isLine(row) ? row.data.quantity : 0, {
                id: "quantity",
                cell: info => {
                    const row = info.row.original;

                    if (!isLine(row)) return "";

                    return row.data.quantity;
                },

                maxSize: 120
            }),
            columnHelper.accessor(row => isLine(row) ? normalizePrice(row.data.unit_price) : "", {
                id: "unit_price",
                cell: info => {
                    const row = info.row.original;

                    if (!isLine(row)) return "";

                    return (
                        <div className="flex items-center">
                            {row.data.unit_price
                                ? formatPriceWithCurrency(row.data.unit_price, currency)
                                : "-"}
                        </div>
                    );
                },
                maxSize: 120
            }),
            columnHelper.accessor(row => isLine(row) ? row.data.discount : 0, {
                id: "discount",
                cell: info => {
                    const row = info.row.original;
                    if (!isLine(row)) return "";

                    return <div className="flex">{row.data.discount ? `${row.data.discount}%` : "-"}</div>;
                },
                maxSize: 120
            }),
            columnHelper.accessor(row => isLine(row) ? row.data.tax?.rate : 0, {
                id: "tax",
                cell: info => {
                    const row = info.row.original;
                    if (!isLine(row)) return "";

                    return <div className="flex">{row.data.tax?.rate ? `${row.data.tax.rate}%` : "-"}</div>;
                },
                maxSize: 120
            }),
            columnHelper.accessor(
                row => isLine(row) && normalizePrice(calculateOrderPrice(row.data.quantity ?? 0, row.data.unit_price ?? 0, row.data.discount ?? 0)),
                {
                    id: "total",
                    cell: info => {
                        const row = info.row.original;

                        if (!isLine(row)) return "";

                        // If row.data exists or row is a group header (without data), show TotalCell
                        return formatPriceWithCurrency(calculateOrderPrice(row.data.quantity ?? 0, row.data.unit_price ?? 0, row.data.discount ?? 0), currency);

                    },
                    maxSize: 150
                })
        ],
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getRowCanExpand: (row) => isGroup(row.original),

        initialState: {
            columnVisibility: {
                "unit_price": ability.can("price.view", "sale_order"),
                "total": ability.can("price.view", "sale_order")
            }
        }
    });

    return (
        <BaseTableV2.Body className="space-y-2">
            {table.getRowModel().rows.map((row, index) => {
                const isRowGroup = row.original && isGroup(row.original);
                const cells = row.getVisibleCells().filter(cell => !isRowGroup || (cell.column.columnDef.meta as any)?.forGroup);

                return (
                    <>
                        <div className={cn(isRowGroup && "border-2 border-solid border-[#ECEDFE] rounded-md")}>
                            <BaseTableV2.Row className={cn(isRowGroup && "min-h-[40px] bg-[#ECEDFE]")}>
                                {cells.map(cell => (
                                    <BaseTableV2.Cell
                                        key={cell.id}
                                        {...cell.column.columnDef}
                                        className={
                                            cn(
                                                isRowGroup && row.getCanExpand() ? "self-center border-2 border-[#ECEDFE]" : ""
                                            )
                                        }
                                    >
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )}
                                    </BaseTableV2.Cell>
                                ))}
                            </BaseTableV2.Row>
                            {row.getCanExpand() && isGroup(row.original) && (
                                <>
                                    {
                                        row.original.data.lines.length ?
                                            <DetailsLinesRenderer
                                                currency={currency}
                                                lines={row.original.data.lines}
                                            />
                                            :
                                            <div className="flex items-center justify-center h-[70px]">
                                                <div
                                                    className="text-center text-sm text-[#5A5587] opacity-50">{t("emptyGroup")}</div>
                                            </div>
                                    }
                                </>
                            )}
                        </div>
                    </>
                );
            })}
        </BaseTableV2.Body>
    );
}