import React from "react";
import BaseTable from "@reusables/BaseTable";
import { normalizePrice } from "@helpers/utils";
import { FormTyping } from "../modals/AddCollectionModal";
import { useTranslation } from "react-i18next";

interface CollectionSelectorProps {
    products?: FormTyping["collection"]["products"];
}
export default function CollectionSelector({ products }: CollectionSelectorProps) {
    const { t } = useTranslation("", {
        keyPrefix: "sales.orders.general.orders.modals.addCollection.selector"
    });

    return (
        <div>
            <BaseTable
                data={products ?? []}
                columns={[
                    {
                        header: t("headers.0"),
                        getter: row => row.code,
                    },
                    {
                        header: t("headers.1"),
                        getter: row => row.name,
                    },
                    {
                        header: t("headers.2"),
                        getter: row => row.quantity,
                    },
                    {
                        header: t("headers.3"),
                        getter: row => normalizePrice(row.prices.purchase_price),
                    },
                    {
                        header: t("headers.4"),
                        getter: row => normalizePrice(row.prices.purchase_price * row.quantity),
                    }
                ]}
                size="medium"
                hideTableWhenNothingFound
            />
        </div>
    )
}
