import React, { useEffect, useMemo } from "react";
import BaseCheckbox from "@reusables/BaseCheckboxLegacy";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import { useTranslation } from "react-i18next";
import { calculateOrderPrice, formatPriceWithCurrency, smartParseFloat } from "@helpers/utils";
import { createPortal } from "react-dom";
import BaseInput from "@reusables/BaseInput";
import { ScanBarcode } from "lucide-react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { ViewLayoutProps } from "./types";
import { Can, useAbility } from "@/casl.config";
import { PurchaseOrder } from "@/types/general";
import BaseCustomFieldsSection from "@/components/reusables/BaseCustomFieldsSection";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    useReactTable
} from "@tanstack/react-table";
import BaseTableV2 from "@/components/reusables/BaseTableV2";
import DetailsLinesRenderer
    from "@components/Dashboard/pages/PurchasingPage/PurchasesPage/components/ViewLayout/DetailsLinesRenderer";


export interface TransformedLine {
    type: "line" | "group";
    id?: number;
    name?: string;
    module: "bom" | "collection";
    key?: string;
    data: PurchaseOrder.Components.LineData | undefined; // Always a single LineData or null for group headers
}

export default function OrderDetails({ purchaseOrder }: ViewLayoutProps) {
    const { t } = useTranslation("", { keyPrefix: "purchaseOrder.general" });

    const ability = useAbility();

    // Calculating orders summary
    const linesSummary = useMemo(() => {
        let subtotal = 0;
        let taxes = 0;

        purchaseOrder.lines.forEach(line => {
            const linesToRead = line.type === "group" ? line.data.lines.map(groupLine => groupLine.data) : [line.data];

            linesToRead.forEach(line => {
                const linePrice = calculateOrderPrice(line.quantity, line.unit_price, line.discount ?? 0);
                subtotal += linePrice;
                taxes += linePrice * ((line.tax?.rate ?? 0) / 100);
            });
        });

        const shipping = smartParseFloat(purchaseOrder.shipping_cost) ?? 0;
        const totalWithTaxes = subtotal + taxes;

        return {
            subtotal,
            taxes,
            totalWithTaxes,
            shipping
        };
    }, [purchaseOrder.lines]);

    const blocks = useMemo<Record<"accent" | "references" | "address" | "shipping" | "currencies", InfoItem[]>>(() => ({
        accent: [
            {
                title: t("dropdowns.supplier.label"),
                data: () => purchaseOrder?.supplier.name ?? ""
            },
            {
                title: t("accentFields.status.label"),
                data: () => {
                    switch (purchaseOrder?.receive_state) {
                        case 1:
                            return <span>{t("accentFields.status.variants.notReceived")}</span>;
                        case 2:
                            return <span>{t("accentFields.status.variants.inProgress")}</span>;
                        case 3:
                            return <span>{t("accentFields.status.variants.received")}</span>;
                        default:
                            return <></>;
                    }
                }
            },
            {
                title: t("accentFields.orderDate.label"),
                data: () => purchaseOrder?.purchase_date.format("DD.MM.YYYY") ?? "-"
            },
            {
                title: t("accentFields.receivedAt.label"),
                data: () => purchaseOrder?.receive_date?.format("DD.MM.YYYY") ?? "-"
            }
        ],
        references: [
            {
                title: t("dropdowns.ourReference.label"),
                data: () => purchaseOrder?.our_reference?.name ?? "-"
            },
            {
                title: t("dropdowns.theirReference.label"),
                data: () => purchaseOrder?.their_reference?.name ?? "-"
            },
            {
                title: t("dropdowns.paymentTerms.label"),
                data: () => purchaseOrder?.payment_terms?.name ?? "-"
            },
            {
                title: t("dropdowns.deliveryTerms.label"),
                data: () => purchaseOrder?.delivery_terms?.name ?? "-"
            },
            {
                title: t("dropdowns.documentLanguage.label"),
                data: () => purchaseOrder?.language?.name ?? "-"
            }
        ],
        currencies: [
            {
                title: t("dropdowns.currency.label"),
                data: () => purchaseOrder?.currency ? purchaseOrder?.currency?.name + " (" + purchaseOrder?.currency?.symbol + ")" : "-"
            },
            {
                title: t("fields.exchangeRate.label"),
                data: () => purchaseOrder?.exchange_rate?.toPrecision(7) ?? "-"
            }
        ],
        address: [
            {
                title: t("fields.billingAddress.label"),
                data: () => (<>
                    <div
                        className="mb-[24px]">{_.join([purchaseOrder.billing_address?.street, purchaseOrder.billing_address?.city, purchaseOrder.billing_address?.country?.name].filter(x => !_.isEmpty(x)), ", ")}</div>
                    <BaseCheckbox
                        label={`${t("fields.billingAddress.checkboxes.useForDelivery")}`}
                        checked={purchaseOrder.is_billing_for_delivery}
                        disabled
                    />
                </>)
            }
        ],
        shipping: [
            {
                title: t("fields.shippingCost.label"),
                data: () => <div>
                    <div className="mb-[24px]">{purchaseOrder.shipping_cost?.toString() ?? "-"}</div>
                    <BaseCheckbox
                        label={`${t("fields.shippingCost.checkboxes.splitByProducts")}`}
                        checked={purchaseOrder.is_split_by_products}
                        disabled
                    />
                </div>
            }
        ]
    }), [purchaseOrder, t]);


    const columnHelper = createColumnHelper<PurchaseOrder.Components.Lines>();

    const table = useReactTable({
        data: [],
        columns: [
            columnHelper.display({
                header: t("orders.columns.0") + "",
                id: "product_code",
                maxSize: 150
            }),

            columnHelper.display({
                header: t("orders.columns.1") + "",
                id: "product_name"
            }),
            columnHelper.display({
                header: t("orders.columns.2") + "",
                id: "quantity",
                maxSize: 120
            }),
            columnHelper.display({
                header: t("orders.columns.3") + "",
                id: "unit_price",
                maxSize: 120
            }),
            columnHelper.display({
                header: t("orders.columns.4") + "",
                id: "discount",
                maxSize: 120
            }),
            columnHelper.display({
                header: t("orders.columns.5") + "",
                id: "tax",
                maxSize: 120
            }),
            columnHelper.display({
                id: "total",
                header: t("orders.columns.6") + "",
                maxSize: 150
            })
        ],
        initialState: {
            columnVisibility: {
                "unit_price": ability.can("price.view", "sale_order"),
                "total": ability.can("price.view", "sale_order")
            }
        },
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel()
    });

    const [referenceNumberEl, setReferenceNumberEl] = React.useState<HTMLDivElement>();

    useEffect(() => {
        setReferenceNumberEl(document.getElementById("reference_number") as HTMLDivElement);
    }, []);

    return (
        <div className="space-y-[40px]">
            {
                purchaseOrder.reference_number && referenceNumberEl && createPortal(
                    <div>
                        <BaseInput
                            value={purchaseOrder.reference_number.code}
                            disabled
                            icon={{
                                right: {
                                    el: purchaseOrder.reference_number.id ?
                                        <Link to={`/dashboard/sales/orders/${purchaseOrder.reference_number.id}/details`}>
                                            <ScanBarcode color={"#a0a0a0"} />
                                        </Link>
                                        : <ScanBarcode color={"#a0a0a0"} />,
                                    offset: "12px"
                                }
                            }}
                        />
                    </div>,
                    referenceNumberEl
                )
            }
            <div className="p-[16px] rounded-[8px] bg-informationAccentBlock text-accent">
                <BaseInputsGrid cols={4}>
                    {
                        blocks.accent.map((item, index) => (
                            <div key={index}>
                                <div className="mb-[16px]">{item.title}</div>
                                <div className="space-x-[24px] font-semibold">{item.data()}</div>
                            </div>
                        ))
                    }
                </BaseInputsGrid>
            </div>
            <BaseInputsGrid title={t("categories.references")}>
                {
                    blocks.references.map((item, index) => (
                        <div key={index}>
                            <div className="mb-[16px] font-semibold">{item.title}</div>
                            <div className="space-x-[24px] text-accent">{item.data()}</div>
                        </div>
                    ))
                }
            </BaseInputsGrid>
            <BaseInputsGrid>
                {
                    blocks.currencies.map((item, index) => (
                        <div key={index}>
                            <div className="mb-[16px] font-semibold">{item.title}</div>
                            <div className="space-x-[24px] text-accent">{item.data()}</div>
                        </div>
                    ))
                }
            </BaseInputsGrid>
            <BaseInputsGrid title="Address">
                {
                    blocks.address.map((item, index) => (
                        <div key={index}>
                            <div className="mb-[16px] font-semibold">{item.title}</div>
                            <div className="text-accent">{item.data()}</div>
                        </div>
                    ))
                }
                {
                    !purchaseOrder?.is_billing_for_delivery &&
                    <div>
                        <div className="mb-[16px] font-semibold">{t("dropdowns.deliveryAddress.label")}</div>
                        <div className="space-x-[24px] text-accent">
                            {purchaseOrder.delivery_address?.street}, {purchaseOrder.delivery_address?.city}, {purchaseOrder.delivery_address?.country?.name}
                        </div>
                    </div>
                }
                {
                    blocks.shipping.map((item, index) => (
                        <div key={index}>
                            <div className="mb-[16px] font-semibold">{item.title}</div>
                            <div className="text-accent">{item.data()}</div>
                        </div>
                    ))
                }
            </BaseInputsGrid>

            <BaseCustomFieldsSection.Previewer source={purchaseOrder} />

            <div className="border border-solid border-gray-300 rounded-[8px] p-[16px]">
                {
                    purchaseOrder?.lines.length
                        ?
                        <>
                            <div className="flex mb-[46px] items-center space-x-[8px]">
                                <div className="text-xl font-thin text-gray-600 grow">{t("orders.heading")}</div>
                            </div>

                            <BaseTableV2>
                                {
                                    table.getHeaderGroups().map(headerGroup => (
                                        <BaseTableV2.Header key={headerGroup.id} className="mb-3">
                                            <BaseTableV2.Row>
                                                {headerGroup.headers.map(header => (
                                                    <BaseTableV2.Cell
                                                        key={header.id}
                                                        {...header.column.columnDef}
                                                    >
                                                        {header.isPlaceholder
                                                            ? null
                                                            : flexRender(header.column.columnDef.header, header.getContext())}
                                                    </BaseTableV2.Cell>
                                                ))}
                                            </BaseTableV2.Row>
                                        </BaseTableV2.Header>
                                    ))}
                                <DetailsLinesRenderer
                                    currency={purchaseOrder.currency}
                                    lines={purchaseOrder.lines}
                                />
                            </BaseTableV2>
                            <Can I="order_price.view" a="purchase_order">
                                <div className="flex justify-end mt-[48px] font-thin">
                                    <div className="w-1/2 grid grid-cols-2 gap-y-[16px]">
                                        <div className="text-right">
                                            {t("orders.summary.subtotal")}
                                        </div>
                                        <div className="text-right pr-[16px]">
                                            {formatPriceWithCurrency(linesSummary.subtotal, purchaseOrder.currency)}
                                        </div>
                                        <div className="text-right">
                                            {t("orders.summary.taxes")}
                                        </div>
                                        <div className="text-right pr-[16px]">
                                            {formatPriceWithCurrency(linesSummary.taxes, purchaseOrder.currency)}
                                        </div>
                                        {
                                            !purchaseOrder.is_split_by_products &&
                                            <>
                                                <div className="text-right">
                                                    {t("orders.summary.shipping")}
                                                </div>
                                                <div className="text-right pr-[16px]">
                                                    {formatPriceWithCurrency(linesSummary.shipping, purchaseOrder.currency)}
                                                </div>
                                            </>
                                        }
                                        <div className="text-right font-semibold text-accent">
                                            {t("orders.summary.total")}
                                        </div>
                                        <div className="text-right pr-[16px] font-semibold text-accent">
                                            {formatPriceWithCurrency(linesSummary.totalWithTaxes, purchaseOrder.currency)}
                                        </div>
                                    </div>
                                </div>
                            </Can>
                        </>
                        :
                        <>
                            <div className="text-xl text-center text-gray-300 font-thin">
                                {t("orders.noProducts")}
                            </div>
                        </>
                }
            </div>
        </div>
    );
}

type InfoItem = {
    title: string;
    data: () => JSX.Element | string;
}
