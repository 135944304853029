import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import { FormProvider, useFormContext, useWatch } from "react-hook-form";
import { GroupSchema, isGroup, isLine, LineGroupUnion, LineSchema, SaleOrdersFormTyping } from "../../types";
import AddProductModal from "./modals/AddProductModal";
import LowStockWarning
    from "@components/Dashboard/pages/Sales/Orders/components/MutationLayout/parts/ProductSection/LowStockWarning";
import { Customer } from "@/types/general";
import AddCollectionModal from "./modals/AddCollectionModal";
import { ArrayElementType, normalizePrice } from "@helpers/utils";
import { Alert, Collapse } from "@mui/material";
import BaseTableV2 from "@reusables/BaseTableV2";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    useReactTable
} from "@tanstack/react-table";
import { DragDropContext, OnDragEndResponder, OnDragUpdateResponder } from "@hello-pangea/dnd";
import LinesRenderer
    from "@components/Dashboard/pages/Sales/Orders/components/MutationLayout/parts/ProductSection/table/LinesRenderer";
import AddGroupModal from "./modals/AddGroupModal";
import BaseMaterialIconButton from "@reusables/BaseMaterialIconButton";
import { FolderPlus } from "lucide-react";
import SummaryBlock from "./SummaryBlock";
import { handleDragEnd } from "@components/Dashboard/pages/Sales/Orders/components/MutationLayout/logic";


export default function ProductSection() {
    const { t } = useTranslation("", { keyPrefix: "sales.orders.general.orders" });

    const form = useFormContext<SaleOrdersFormTyping>();

    const { control, getValues, setValue, formState } = form;

    const [isAddProductModalOpen, setIsAddProductModalOpen] = React.useState(false);
    const [isAddCollectionModalOpen, setIsAddCollectionModalOpen] = React.useState(false);
    const [isAddGroupModalOpen, setIsAddGroupModalOpen] = React.useState(false);

    const [selectedLine, setSelectedLine] = React.useState<LineSchema>();
    const [selectedLinePath, setSelectedLinePath] = React.useState<{ accessor: string, index: number }>();

    const [selectedGroup, setSelectedGroup] = React.useState<GroupSchema>();
    const [selectedGroupPath, setSelectedGroupPath] = React.useState<{ accessor: string, index: number }>();

    const columnHelper = createColumnHelper<ArrayElementType<typeof lines>>();

    const table = useReactTable({
        data: [],
        columns: [
            columnHelper.display({
                id: "grip",
                header: () => null,
                maxSize: 20
            }),
            columnHelper.display({
                header: t("columns.0") + "",
                id: "product_code",
                maxSize: 150
            }),

            columnHelper.display({
                header: t("columns.1") + "",
                id: "product_name"
            }),
            columnHelper.display({
                header: t("columns.2") + "",
                id: "quantity",
                maxSize: 120
            }),
            columnHelper.display({
                header: t("columns.3") + "",
                id: "unit_price",
                maxSize: 120
            }),
            columnHelper.display({
                header: t("columns.4") + "",
                id: "discount",
                maxSize: 120
            }),
            columnHelper.display({
                header: t("columns.5") + "",
                id: "tax",
                maxSize: 120
            }),
            columnHelper.display({
                id: "total",
                header: t("columns.6") + "",
                maxSize: 150
            }),
            columnHelper.display(
                {
                    id: "actions",
                    header: () => <div className="text-right">{t("columns.7")}</div>,
                    maxSize: 100
                }
            )
        ],
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel()
    });

    const [combineDraggableId, setCombineDraggableId] = React.useState<string>();

    const onDragUpdate: OnDragUpdateResponder = (result) => {
        setCombineDraggableId(result.combine?.draggableId);
        console.log("Dragging over ", result);
    };

    const lines = useWatch({
        name: "lines",
        control
    });

    const onDragEnd: OnDragEndResponder = (result) => {
        handleDragEnd(result, lines, (lines) => {
            setValue("lines", lines);
            setCombineDraggableId(undefined);
        });
    };

    const append = (data: LineGroupUnion) => {
        setValue("lines", [...lines, data]);
    };

    const update = (path: { accessor: string, index: number }, data: LineGroupUnion) => {
        setValue(path.accessor + "." + path.index as any, data);
    };

    const onLineEditModalOpen = useCallback((index: number, accessor: string, line: LineGroupUnion) => {
        if (isLine(line)) {
            setIsAddProductModalOpen(true);
            setSelectedLine(line);
            setSelectedLinePath({ accessor, index });
        } else if (isGroup(line)) {
            setIsAddGroupModalOpen(true);
            setSelectedGroup(line);
            setSelectedGroupPath({ accessor, index });
        }
    }, []);

    return (
        <>
            <Collapse in={!!formState.errors.lines?.message}>
                <Alert severity={"error"}>{formState.errors.lines?.message}</Alert>
            </Collapse>

            <div className="border border-solid border-gray-300 rounded-[8px] p-[16px]">
                {lines?.length ? (
                    <>
                        <div className="mb-[26px] space-y-[16px]">
                            <div className="flex items-center space-x-[8px]">
                                <div className="text-xl font-thin text-gray-600 grow">
                                    {t("heading")}
                                </div>
                                {getValues("is_editable") === true && (
                                    <>
                                        <BaseMaterialIconButton
                                            icon={<FolderPlus size={16} />}
                                            onClick={() => {
                                                setSelectedGroup(undefined);
                                                setSelectedGroupPath(undefined);
                                                setIsAddGroupModalOpen(true);
                                            }}
                                        />
                                        <BaseMaterialButton
                                            fontWeight={500}
                                            onClick={() => {
                                                setIsAddProductModalOpen(true);
                                                setSelectedLine(undefined);
                                                setSelectedLinePath(undefined);
                                            }}
                                        >
                                            {t("buttons.addProduct")}
                                        </BaseMaterialButton>
                                        <BaseMaterialButton
                                            fontWeight={500}
                                            onClick={() => setIsAddCollectionModalOpen(true)}
                                        >
                                            {t("buttons.addCollection")}
                                        </BaseMaterialButton>
                                    </>
                                )}
                            </div>
                            {getValues("is_editable") === true && <LowStockWarning />}
                        </div>
                        <FormProvider {...form}>
                            <DragDropContext
                                onDragUpdate={onDragUpdate}
                                onDragEnd={onDragEnd}
                            >
                                <BaseTableV2>
                                    {table.getHeaderGroups().map(headerGroup => (
                                        <BaseTableV2.Header key={headerGroup.id} className="mb-3">
                                            <BaseTableV2.Row>
                                                {headerGroup.headers.map(header => (
                                                    <BaseTableV2.Cell
                                                        key={header.id}
                                                        {...header.column.columnDef}
                                                    >
                                                        {header.isPlaceholder
                                                            ? null
                                                            : flexRender(header.column.columnDef.header, header.getContext())}
                                                    </BaseTableV2.Cell>
                                                ))}
                                            </BaseTableV2.Row>
                                        </BaseTableV2.Header>
                                    ))}
                                    <LinesRenderer
                                        accessor="lines"
                                        lines={lines}
                                        onLineEditModalOpen={onLineEditModalOpen}
                                        combineDraggableId={combineDraggableId}
                                    />
                                </BaseTableV2>
                            </DragDropContext>
                            <SummaryBlock />
                        </FormProvider>
                    </>
                ) : (
                    <>
                        <div className="text-xl text-center text-gray-300 font-thin">
                            {t("noProducts")}
                        </div>
                        <div className="grid grid-cols-2 gap-[40px] mt-[24px]">
                            <BaseMaterialButton
                                size="large"
                                fontWeight={500}
                                onClick={() => setIsAddProductModalOpen(true)}
                            >
                                {t("buttons.addProduct")}
                            </BaseMaterialButton>
                            <BaseMaterialButton
                                size="large"
                                fontWeight={500}
                                onClick={() => setIsAddCollectionModalOpen(true)}
                            >
                                {"Add collection"}
                            </BaseMaterialButton>
                        </div>
                    </>
                )}

                <AddProductModal
                    isOpen={isAddProductModalOpen}
                    onClose={() => {
                        setIsAddProductModalOpen(false);
                    }}
                    onLineMutation={(product) => {
                        if (product.should_use_bom) {
                            if (product.selected_bom) {
                                // const bomKey = `${product.selected_bom.id}-${uuidv4()}`;

                                append({
                                    name: product.selected_bom.name,
                                    module: "bom",
                                    module_entity_id: product.selected_bom.id,
                                    lines: product.selected_bom.components.map((component) => ({
                                            product: {
                                                ...component.product,
                                                has_bom: false
                                            },
                                            quantity: component.quantity * (product.quantity ?? 1),
                                            unit_price: normalizePrice(component.product.prices.selling_price as number),
                                            discount: product.discount,
                                            tax: product.tax
                                        })
                                    )
                                });
                            }
                        } else {
                            if (!selectedLine) {
                                append({
                                    ...product,
                                    quantity: product.quantity as number,
                                    unit_price: normalizePrice(product.unit_price as number)
                                });
                            } else {
                                if (selectedLinePath !== undefined) {
                                    update(selectedLinePath, {
                                        ...product,
                                        quantity: product.quantity as number,
                                        unit_price: normalizePrice(product.unit_price as number)
                                    });
                                }
                            }
                        }

                        if (selectedLine) {
                            setSelectedLine(undefined);
                        }

                        console.log("POST Product append", getValues());
                    }}
                    customer={getValues("customer") as Customer.Root}
                    selectedLine={selectedLine}
                />

                <AddCollectionModal
                    isOpen={isAddCollectionModalOpen}
                    onClose={() => setIsAddCollectionModalOpen(false)}
                    onLineAddition={(collection) => {
                        // const collectionKey = `${collection.collection.id}-${uuidv4()}`;

                        append({
                            name: collection.collection.name,
                            module: "collection",
                            module_entity_id: collection.collection.id,
                            lines: collection.collection.products.map((prod) => ({
                                product: prod,
                                quantity: prod.quantity * collection.quantity,
                                unit_price: normalizePrice(prod.prices.selling_price),
                                tax: prod.tax
                            }))
                        });

                        console.log("POST Collection append", getValues());
                    }}
                />

                <AddGroupModal
                    isOpen={isAddGroupModalOpen}
                    onClose={() => {
                        setIsAddGroupModalOpen(false);
                    }}
                    group={selectedGroup}
                    onMutation={(data) => {
                        if (selectedGroup && selectedGroupPath !== undefined) {
                            update(selectedGroupPath, {
                                ...selectedGroup,
                                name: data.name,
                                comment: data.comment
                            });
                        } else {
                            append({
                                name: data.name,
                                comment: data.comment,
                                module: "custom",
                                lines: []
                            });
                        }

                        setSelectedGroup(undefined);
                        setSelectedGroupPath(undefined);
                        setIsAddGroupModalOpen(false);
                    }}
                />
            </div>
        </>
    );
}