import React from "react";

import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import { useGetSaleOrderQuery, useUpdateSaleOrderMutation } from "@redux/features/sales/orders/salesOrdersApi";
import MutationLayout from "../components/MutationLayout";
import { Can } from "@/casl.config";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";
import { toastError } from "@helpers/utils";
import BaseSyncedFromSourcesBadge from "@reusables/BaseSyncedFromSourcesBadge";
import { isLine } from "@components/Dashboard/pages/Sales/Orders/components/MutationLayout/types";
import { lineSchemaToMutationLine } from "@components/Dashboard/pages/Sales/Orders/CreationPage";
import { toast } from "react-toastify";

export default function SaleOrderEditingPage() {
    const { t } = useTranslation("", { keyPrefix: "sales.orders.mutations.editing" });

    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const [updateSaleOrder, { isLoading: isUpdateLoading }] = useUpdateSaleOrderMutation();
    const { data: saleOrder, isLoading: isSaleOrderLoading } = useGetSaleOrderQuery(parseInt(id));

    return (
        <BaseRecordRenderer
            record={saleOrder?.is_editable}
            loading={isSaleOrderLoading}
            notFoundText={t("notFoundText")}
        >
            <Can not I="edit" a="sale_order">
                <NoPermissionBanner />
            </Can>

            <Can I="edit" a="sale_order">
                <BaseLoadingBlocker active={isUpdateLoading}>
                    <div className="space-y-[40px]">
                        <div className="flex flex-row items-center grow space-x-4">
                            <h6 className="text-accent mb-0">{t("heading")} #{saleOrder?.code ?? saleOrder?.id}</h6>
                            <BaseSyncedFromSourcesBadge
                                integrations={[
                                    {
                                        slug: "tripletex",
                                        integrated: () => !!saleOrder?.tripletex_id
                                    },
                                    {
                                        slug: "twenty_four_seven_office",
                                        integrated: () => !!saleOrder?.twenty_four_seven_office_id
                                    },
                                    {
                                        slug: "lime",
                                        integrated: () => !!saleOrder?.lime_id
                                    },
                                    {
                                        slug: "poweroffice",
                                        integrated: () => !!saleOrder?.poweroffice_id
                                    }
                                ]}
                                width={24}
                                height={24}
                            />
                        </div>
                        <MutationLayout
                            saleOrder={saleOrder}
                            onSubmit={data => {
                                const mutationData = {
                                    customer: data.customer.id,
                                    order_date: data.order_date.format("YYYY-MM-DD"),
                                    preferred_delivery_date: data.preferred_delivery_date?.format("YYYY-MM-DD"),
                                    our_reference: data.our_reference?.id,
                                    their_reference: data.their_reference?.id,
                                    payment_terms: data.payment_terms?.id,
                                    delivery_terms: data.delivery_terms?.id,
                                    language: data.language?.id,

                                    ...(!data.is_billing_for_delivery && !!data.delivery_address && {
                                        delivery_address: data.delivery_address.id
                                    }),

                                    is_billing_for_delivery: data.is_billing_for_delivery,

                                    lines: data.lines.map(line => {
                                        if (isLine(line)) {
                                            return lineSchemaToMutationLine(line);
                                        } else {
                                            return {
                                                type: "group",
                                                data: {
                                                    id: line.id,
                                                    name: line.name,
                                                    module: line.module,
                                                    module_entity_id: line.module_entity_id,
                                                    comment: line.comment,
                                                    lines: line.lines.map(lineSchemaToMutationLine)
                                                }
                                            };
                                        }
                                    }),

                                    deleted_lines: data.deleted_lines
                                };

                                updateSaleOrder({
                                    id: saleOrder?.id as number,
                                    ...mutationData
                                })
                                    .unwrap()
                                    .then(() => {
                                        toast.success(t("responses.success"));
                                        history.push(`/dashboard/sales/orders/${saleOrder?.id}/details`);
                                    })
                                    .catch(e => {
                                        toastError(e);
                                    });
                            }}
                        />
                    </div>
                </BaseLoadingBlocker>
            </Can>
        </BaseRecordRenderer>
    );
}