import { CheckIcon } from "lucide-react";
import React from "react";
import { components } from "react-select";

export const CustomOption = (props: any) => {
    const { isSelected, children } = props;
  
    return (
        <components.Option {...props}>
            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                {/* Original label text from the parent */}
                {children}
        
                {/* Show check icon only if the item is selected */}
                {isSelected && (
                    <CheckIcon
                        fontSize="small"
                        style={{ marginLeft: "auto", color: "#7556FA" }}
                        className="selected-svg"
                    />
                )}
            </div>
        </components.Option>
    );
};