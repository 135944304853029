import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import ViewLayout from "../components/ViewLayout";
import ProductStepper from "../components/Tabs/ProductStepper";
import { useGetProductQuery } from "@redux/features/products/productsApi";
import { Can, useAbility } from "@/casl.config";
import { BaseTinyActionIcon, TinyActionIconType } from "@reusables/BaseTinyActionIcon";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import BaseBadge from "@reusables/BaseBadge";
import { ExternalLink, Forklift } from "lucide-react";
import DeletionModal from "@components/Dashboard/pages/Products/components/modals/DeletionModal";
import { useAppDispatch } from "@redux/hooks";
import { handleProductCopy } from "@redux/features/products/thunks";
import BarcodeLabelModal from "@components/Dashboard/pages/Products/components/modals/BarcodeLabelModal";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";
import BasePermissionBlocker from "@reusables/BasePermissionBlocker";
import BaseSyncedFromSourcesBadge from "@reusables/BaseSyncedFromSourcesBadge";

export default function ProductViewPage() {
    const { t } = useTranslation("", { keyPrefix: "products.viewEditProduct" });
    const history = useHistory();

    const dispatch = useAppDispatch();

    const { id } = useParams<{ id: string }>();

    const ability = useAbility();

    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState<boolean>(false);
    // const [isDeletionLoading, setIsDeletionLoading] = useState<boolean>(false);
    const [isBarcodeLabelModalOpen, setIsBarcodeLabelModalOpen] = useState(false);

    const { data: product, isLoading: productLoading } = useGetProductQuery(parseInt(id));

    return (
        <>
            <Can not I="view" a="product">
                <div className="levitation-extended">
                    <NoPermissionBanner />
                </div>
            </Can>

            <Can I="view" a="product">
                <BaseRecordRenderer loading={productLoading} record={product}>
                    <div className="space-y-[40px]">
                        <div className="flex mb-[40px]">
                            <div className={"flex flex-row items-center grow space-x-[8px]"}>
                                <div className="flex space-x-2 items-center">
                                    <div
                                        className="flex items-center text-2xl font-bold text-accent">{product?.name}</div>
                                    <BaseSyncedFromSourcesBadge
                                        integrations={[
                                            {
                                                slug: "tripletex",
                                                integrated: () => !!product?.tripletex_id
                                            },
                                            {
                                                slug: "twenty_four_seven_office",
                                                integrated: () => !!product?.twenty_four_seven_office_id
                                            },
                                            {
                                                slug: "lime",
                                                integrated: () => !!product?.lime_id
                                            },
                                            {
                                                slug: "poweroffice",
                                                integrated: () => !!product?.poweroffice_id
                                            }
                                        ]}
                                        width={24}
                                        height={24}
                                    />
                                </div>
                                {product?.is_service && (
                                    <BaseBadge>
                                        <Forklift size={12} className={"mr-1"} />
                                        <span>{t("isService")}</span>
                                    </BaseBadge>
                                )}
                                {!!product?.parent && (
                                    <BaseBadge
                                        className="mx-2 bg-badge-blue cursor-pointer border-1 border-solid border-transparent hover:border-purple-400"
                                        onClick={() => history.push(`/dashboard/products/${product.parent!.id}/details`)}
                                    >
                                        <ExternalLink size={12} className={"mr-1"} />
                                        <span>{t("isVariant")}</span>
                                    </BaseBadge>
                                )}
                            </div>
                            <div className="space-x-[8px] flex items-center h-full">
                                <BasePermissionBlocker action="create" module="product">
                                    <BaseTinyActionIcon type={TinyActionIconType.Duplicate} onClick={() => {
                                        if (product?.id) {
                                            dispatch(handleProductCopy(product.id, history));
                                        }
                                    }} />
                                </BasePermissionBlocker>

                                <BasePermissionBlocker action="edit" module="product">
                                    <BaseTinyActionIcon type={TinyActionIconType.Edit}
                                                        onClick={() => ability.can("edit", "product") ? history.push(`/dashboard/products/${id}/edit`) : null} />
                                </BasePermissionBlocker>

                                <BasePermissionBlocker action="delete" module="product">
                                    <BaseTinyActionIcon type={TinyActionIconType.Delete}
                                                        onClick={() => ability.can("delete", "product") ? setIsDeletionModalOpen(true) : null} />
                                </BasePermissionBlocker>

                                <BaseTinyActionIcon type={TinyActionIconType.Barcode}
                                                    onClick={() => setIsBarcodeLabelModalOpen(true)} />
                            </div>
                        </div>
                        <ProductStepper informationTab={<ViewLayout product={product} />} product={product}
                                        previewMode={true} />
                    </div>
                </BaseRecordRenderer>
            </Can>

            {/* DELETION MODAL */}
            <DeletionModal
                isOpen={isDeletionModalOpen}
                onClose={() => setIsDeletionModalOpen(false)}
                entity={product}
                onDeletion={() => {
                    history.push("/dashboard/products");
                }}
            />

            {/* BARCODE LABEL MODAL */}
            <BarcodeLabelModal
                isOpen={isBarcodeLabelModalOpen}
                onClose={() => setIsBarcodeLabelModalOpen(false)}
                product={product}
            />
        </>
    );
}
