import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    merge_hovered_report_code: undefined as string | undefined
};

export const stockCountSlice = createSlice({
    name: "stock_count",
    initialState: initialState,
    reducers: {
        setMergeHoveredReportCode: (state, action: PayloadAction<string | undefined>) => {
            state.merge_hovered_report_code = action.payload;
        }
    }
});

export const {
    setMergeHoveredReportCode
} = stockCountSlice.actions;

export default stockCountSlice.reducer;