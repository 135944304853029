import React from "react";
import { Product } from "@/types/general";
import SerialNumbersTab from "./components/SerialNumbersTab";
import BatchNumbersTab from "./components/BatchNumbersTab";
import { Can, useAbility } from "@/casl.config";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";
import { Divider } from "@mui/material";

interface NumbersTabProperties {
    product: Product.Extended;
}

export default function NumbersTab({ product }: NumbersTabProperties) {
    const ability = useAbility();

    const cannotViewSerial = ability.cannot("serial_number.view", "product");
    const cannotViewBatch = ability.cannot("batch_number.view", "product");
    const canViewSerial = ability.can("serial_number.view", "product");
    const canViewBatch = ability.can("batch_number.view", "product");

    return (
        <>
            {cannotViewSerial && cannotViewBatch && <NoPermissionBanner />}

            {(canViewSerial || canViewBatch) && (
                <div className={"space-y-[32px]"}>
                    <Can I="serial_number.view" a="product">
                        <SerialNumbersTab product={product} />
                    </Can>
                    <Can I="batch_number.view" a="product">
                        <>
                            <Divider/>
                            <BatchNumbersTab product={product} />
                        </>
                    </Can>
                </div>
            )}
        </>
    );
}
