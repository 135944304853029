import React, { useEffect, useRef, useState } from "react";
import { useGetFileInfoMutation } from "@redux/features/importApi";
import { isErrorWithMessage } from "@redux/api/query";
import { z } from "zod";
import { Controller, FormProvider, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { ReactComponent as NoteSVG } from "@assets/icons/ic_remark.svg";

import BaseModal from "@reusables/Modals/BaseModal";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseButton from "@reusables/BaseButton";
import OptionItem from "./parts/OptionItem";
import { ChevronDown, ChevronUp, Info, ScanEye } from "lucide-react";
import BaseCheckbox from "@reusables/BaseCheckbox";
import { useAppSelector } from "@redux/hooks";
import { toast } from "react-toastify";
import { Alert, Collapse } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { ImportOption } from "./optionsConfig";
import BaseInput from "../BaseInput";
import _ from "lodash";
import BaseBadge from "../BaseBadge";
import { selectOperation } from "@redux/features/dfs/dfsSlice";
import { isDataTransferStateActive } from "@/redux/features/dfs/types";

type ImportModalProperties = {
    isOpen?: boolean;
    onClose?: () => void;
    onImport: (formData: FormData) => void;
    optionsList: ImportOption[];
    tips?: (string | JSX.Element)[];
    isLoading?: boolean;
};

const importScheme = z.object({
    file: z.instanceof(File).nullish(),
    hash: z.string(),
    rows: z.number(),
    options: z.array(
        z.object({
            key: z.string(),
            title: z.string(),
            required: z.boolean(),
            is_code: z.boolean().nullish(),
            selectedHeader: z.object({
                index: z.number(),
                value: z.string()
            }).optional()
        }).refine((data) => {
            // Check if the selected header is required
            return !data.required || (data.required && data.selectedHeader !== undefined && data.selectedHeader !== null && typeof data.selectedHeader === "object");
        }, {
            params: {
                i18n: "imports.importModal.validation.requiredField"
            },
            path: ["selectedHeader"]
        })
    ),
    headers: z.array(z.object({
        index: z.number(),
        value: z.string()
    })).optional(),
    ignore_existing: z.boolean().optional(),
    report_email: z.string().email()
});

export type ImportFormTyping = z.infer<typeof importScheme>;

export default function ImportModal(props: ImportModalProperties) {
    const { t } = useTranslation("", { keyPrefix: "imports.importModal" });

    const data = useAppSelector(state => state.importInfo.importData);
    const file = useAppSelector(state => state.importInfo.importFile);

    const userEmail = useAppSelector(state => state.auth.user?.email);

    const isImportInProgress = useAppSelector(state => isDataTransferStateActive(selectOperation("import")(state)));

    // Workaround to not flash the "import is in progress" banner before modal is closed
    const [isAllowedImportProgressIndicator, setIsAllowedImportProgressIndicator] = useState(true);

    const [getFileInfo, { isLoading: isLoadingInfo }] = useGetFileInfoMutation();

    const [showOptionalFields, setShowOptionalFields] = useState<boolean>(false);

    const form = useForm<ImportFormTyping>({
        resolver: zodResolver(importScheme),
        defaultValues: {
            ignore_existing: false,
            report_email: userEmail
        }
    });

    const { fields } = useFieldArray({
        control: form.control,
        name: "options"
    });

    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (props.isOpen) {
            setIsAllowedImportProgressIndicator(true);
        }

        form.reset({
            options: props.optionsList,
            file: file,
            hash: data?.payload.hash,
            rows: data?.payload.rows,
            ignore_existing: false,
            report_email: userEmail
        });

        form.setValue("headers", data?.payload.headers?.filter(x => !_.isEmpty(x)).map((header, index) =>
            ({
                index,
                value: header
            })
        ));
    }, [props.isOpen, file, data]);

    const onSubmit = form.handleSubmit((data) => {
        if (!data || !form.getValues("file")) return;

        const formData = new FormData();
        formData.append("file_hash", form.getValues("hash"));
        formData.append("file", form.getValues("file") as File);
        formData.append("ignore_existing", data.ignore_existing ? "0" : "1");
        formData.append("report_email", data.report_email);

        data.options?.forEach((opt) => {
            if (!opt.selectedHeader) return;
            formData.append(`columns[${opt.key}]`, opt.selectedHeader.index.toString());
        });

        setIsAllowedImportProgressIndicator(false);
        props.onImport(formData);

        props.onClose?.();
    }, console.error);

    // Fetch file info when a file is changed
    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement> & { target: { files: File[] } }) => {
        const file = event.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append("file", file);

        getFileInfo(formData).unwrap()
            .then((data) => {
                form.reset({
                    file: file,
                    options: props.optionsList,
                    hash: data.payload.hash,
                    rows: data.payload.rows,
                    headers: data.payload.headers.map((header, index) => ({
                        index,
                        value: header ?? `Empty #${index}`
                    })),
                    ignore_existing: false,
                    report_email: `${userEmail}`
                });
                setShowOptionalFields(false);
            })
            .catch((e) => {
                if (isErrorWithMessage(e)) {
                    toast.error(e.message);
                } else {
                    console.error(e);
                }
            });

        // Clear the input after processing to allow re-uploads
        event.target.value = "";
    };

    // Open file input dialog
    const handleChangeFileClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleToggleOptionalFields = () => {
        setShowOptionalFields(!showOptionalFields);
    };

    const areRequiredFieldsPresent = props.optionsList.some(opt => opt.required);

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={() => {
                setShowOptionalFields(false);
                props.onClose?.();
            }}
            width={1144}
            padding="56px"
            isLoading={props.isLoading}
            useCloseIcon
        >
            <FormProvider {...form}>
                <form className="space-y-[32px]" onSubmit={onSubmit}>
                    <div className="text-lg font-semibold text-center text-accent">
                        {t("heading")}
                    </div>
                    {isImportInProgress && isAllowedImportProgressIndicator && (
                        <Collapse in={isImportInProgress}>
                            <Alert severity="warning">
                                {t("importIsAlreadyInProgressAlert")}
                            </Alert>
                        </Collapse>
                    )}
                    <div className="space-y-2">
                        <div className="flex justify-between">
                            <div className="flex items-center">
                                <NoteSVG className="h-[40px] w-[40px] mr-2 text-[#A6DD4C]" />
                                <span className="text-[21px] font-semibold">{form.getValues("file")?.name}</span>
                            </div>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                            />
                            <BaseButton
                                text={t("buttons.changeFile")}
                                onClick={handleChangeFileClick}
                                loading={isLoadingInfo}
                                size="sm"
                                buttonWidth="120px"
                                type="button"
                                noPadding
                                style={{
                                    backgroundColor: "#C8E98E",
                                    color: "#3C3769",
                                    fontSize: "14px"
                                }}
                            />
                        </div>
                        <div className="w-full h-[1px] bg-[#A6DD4C]"></div>
                    </div>
                    <div className="flex justify-between items-start">
                        <div className="text-lg font-semibold text-accent">{t("subheading")}</div>
                    </div>
                    {props.tips && (
                        <Alert severity={"info"} icon={<Info size={32} />}>
                            <ul className={"space-y-1 ml-4 list-disc marker:text-secondary-gray"}>
                                {[
                                    t("tips.boolean"),
                                    <Trans
                                        key={"codeColumn"}
                                        t={t}
                                        i18nKey={"tips.codeColumns"}
                                        components={{
                                            b: <b className={"font-semibold"} />,
                                            badge: <BaseBadge
                                                className={"bg-white border text-[10px] px-[6px] -mt-[4px]"} />,
                                            icon: <ScanEye size={12} />
                                        }}
                                    />,
                                    ...props.tips
                                ].map((tip, index) => (
                                    <li key={index} className="text-secondary-gray">{tip};</li>
                                ))}
                            </ul>
                        </Alert>
                    )}
                    <BaseInputsGrid cols={2} gap={{ x: 32, y: 8 }}>
                        {fields.map((field, index) => (
                            <div key={field.id}
                                 className={`${(areRequiredFieldsPresent && !field.required && !showOptionalFields) ? "hidden" : ""}`}>
                                <OptionItem
                                    field={field}
                                    index={index}
                                />
                            </div>
                        ))}
                    </BaseInputsGrid>
                    <div className="flex justify-between items-center">
                        <Controller
                            control={form.control}
                            name="ignore_existing"
                            render={({ field }) => (
                                <BaseCheckbox
                                    {...field}
                                    label={t(`updateExisting`)}
                                />
                            )}
                        />
                        {
                            areRequiredFieldsPresent &&
                            <div
                                className="flex items-center text-secondary-gray hover:text-accent cursor-pointer"
                                onClick={handleToggleOptionalFields}
                            >
                            <span
                                className="text-sm underline text-secondary-gray hover:text-accent hover:no-underline"
                            >
                                {showOptionalFields ? t("optionalFields.hide") : t("optionalFields.show")}
                            </span>
                                {showOptionalFields ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                            </div>
                        }
                    </div>
                    <div>
                        <Controller
                            control={form.control}
                            name="report_email"
                            render={({ field, fieldState }) => (
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    label={t("report_email.label") + " *"}
                                    placeholder={t("report_email.placeholder")}
                                />
                            )}
                        />
                        <div className={"text-secondary-gray text-xs mt-2"}>
                            {t("report_email.tip")}
                        </div>
                    </div>
                    <BaseButton
                        buttonWidth="100%"
                        text={`${t("buttons.import")} (${form.getValues("rows")})`}
                        loading={isImportInProgress}
                        size="md"
                        type="submit"
                    />
                </form>
            </FormProvider>
        </BaseModal>
    );
}