import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DataTransferOperations, DataTransferProgressStatus, DataTransferState } from "./types";
import { RootState } from "@redux/hooks";

const initialState = {
    operations: {} as Partial<Record<DataTransferOperations, DataTransferState>>
};

export const dfsSlice = createSlice({
    name: "dfs",
    initialState: initialState,
    reducers: {
        startOperation: (state, action: PayloadAction<{ operation: DataTransferOperations, module: string }>) => {
            const operation = action.payload.operation;

            state.operations[operation] = {
                operation,
                status: DataTransferProgressStatus.ACTIVE,
                module: action.payload.module
            };
        },

        updateOperationProgress: (state, action: PayloadAction<{
            operation: DataTransferOperations,
            current: number[],
            total: number[]
        }>) => {
            const operationState = state.operations[action.payload.operation];

            if (operationState?.status === DataTransferProgressStatus.ACTIVE) {
                const { current, total } = action.payload;

                state.operations[action.payload.operation] = {
                    ...operationState,
                    progress: current.map((c, i) => ({
                        current: c,
                        total: total[i]
                    }))
                };
            }
        },

        finishOperation: (state, action: PayloadAction<{
            operation: DataTransferOperations,
            has_errors?: boolean,
            data?: any
        }>) => {
            const operation = action.payload.operation;
            const operationState = state.operations[operation];

            if (operationState) {
                state.operations[action.payload.operation] = {
                    operation: operation,
                    status: DataTransferProgressStatus.FINISHED,
                    module: operationState.module,
                    has_errors: action.payload.has_errors ?? false,
                    data: action.payload.data
                };
            } else {
                console.warn(`Unable to "finish" the operation [${operation}] because it was not started (not registered in the state)`);
            }
        },

        resetOperation: (state, action: PayloadAction<{ operation: DataTransferOperations }>) => {
            delete state.operations[action.payload.operation];
        }
    }
});

export const selectOperation = (operation: DataTransferOperations) => (state: RootState) => state.dfs.operations[operation];

// Action creators are generated for each case reducer function
export const {
    startOperation,
    updateOperationProgress,
    finishOperation,
    resetOperation
} = dfsSlice.actions;

export default dfsSlice.reducer;
