import React, { useEffect } from "react";
import BaseModal from "@reusables/Modals/BaseModal";
import BaseButton from "@reusables/BaseButton";
import { useTranslation } from "react-i18next";
import BaseNotFound from "@reusables/BaseNotFound";
import { z } from "zod";
import { useFieldArray, useForm } from "react-hook-form";
import { SerialGroup } from "./SerialGroup";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import { zodResolver } from "@hookform/resolvers/zod";
import { stopPropagate } from "@helpers/utils";

/**
 * This modal handles the validation of serial numbers.
 * Users can attach these numbers (click the "attach" button) if they meet the following criteria:
 * 1. All input fields are filled.
 * 2. For serial numbers, the number of inputs should not exceed the selected quantity. This applies to every product line.
 *
 * Once serial numbers are attached, users have the option to modify them or use them during the creation of a receipt record.
 * If used in the receipt creation, there might be instances where the number of serials is less than the selected quantity.
 * In such cases:
 * 1. Users can edit and add more serial numbers.
 * 2. An error message will be displayed, prompting the user to match the number of serials with the selected quantity.
 */



const formScheme = z.object({
    receipts: z.array(
        z.object({
            sale_order_line_id: z.number(),

            product: z.object({
                id: z.number(),
                name: z.string(),
                code: z.string()
            }),

            location: z.object({
                store: z.object({
                    id: z.number(),
                    name: z.string()
                }),
                section: z.object({
                    id: z.number().optional(),
                    name: z.string().optional()
                }).optional()
            }).optional(),

            received_quantity: z.coerce.number(),

            // Serial_number cannot be empty, however because of RHF bug
            // we cannot correctly validate the serials, if they are added to the end of the array
            // therefore we add them with a `prepend` method.
            serial_numbers: z.array(
                z.object({
                    serial_number: z.string().nonempty()
                })
            )
        }).refine(
            scheme => scheme.serial_numbers.length <= scheme.received_quantity,
            {
                message: "Serial numbers count cannot be greater than received quantity",
                path: ["serial_numbers"]
            }
        )
    )
});

export type SerialNumbersScheme = z.infer<typeof formScheme>

interface IncomingReceipt {
    order: {
        data: {
            id: number;
            product: {
                id: number;
                name: string;
                code: string;
            }
        }
    };
    location?: {
        store: {
            id: number;
            name: string;
        }
        section?: {
            id?: number;
            name: string;
        }
    };
    received_quantity?: number | null;
    serial_numbers?: {
        serial_number: string;
    }[];
}

interface SerialNumbersModalProps {
    isOpen: boolean;
    onClose: () => void;

    receipts: IncomingReceipt[];
    onSave?: (data: SerialNumbersScheme) => void;
    preview?: boolean;
}

export default function SerialNumbersModal(props: SerialNumbersModalProps) {
    const { t } = useTranslation("", { keyPrefix: "receive.modals.serialNumbers" });

    const { control, handleSubmit, setValue, reset } = useForm<SerialNumbersScheme>({
        resolver: zodResolver(formScheme)
    });

    const { fields: receipt } = useFieldArray({
        control,
        name: "receipts"
    });

    const onSubmit = handleSubmit(data => props.onSave?.(data));

    useEffect(() => {
        if (props.isOpen) {
            const formReadyLines = props.receipts
                .filter(x => !!x.location)
                .map(receipt => {
                    const serial_numbers = receipt.serial_numbers?.length ? receipt.serial_numbers : [{ serial_number: "" }];

                    const location = receipt.location as NonNullable<IncomingReceipt["location"]>;

                    return {
                        sale_order_line_id: receipt.order.data.id,
                        product: {
                            id: receipt.order.data.product.id,
                            name: receipt.order.data.product.name,
                            code: receipt.order.data.product.code
                        },
                        location: {
                            store: {
                                id: location.store.id,
                                name: location.store.name
                            },
                            section: {
                                id: location.section?.id,
                                name: location.section?.name
                            }
                        },
                        received_quantity: receipt.received_quantity ?? 0,
                        serial_numbers
                    };
                });

            setValue(
                "receipts",
                formReadyLines
            );
        } else {
            reset();
        }
    }, [props.receipts, props.isOpen, setValue, reset]);

    return <BaseModal
        isOpen={props.isOpen}
        onClose={props.onClose}
        width={900}
        padding="56px"
        position={"center"}
        useCloseIcon={props.preview}
    >
        <form onSubmit={stopPropagate(onSubmit)} className="space-y-[32px]">
            {
                receipt.length ?
                    receipt.map((line, lineIndex) => (
                        <SerialGroup
                            key={line.id}
                            index={lineIndex}
                            control={control}
                            preview={props.preview}
                        />
                    ))
                    :
                    <BaseNotFound text={t("noProductsPicked")} height={250} />
            }
            {
                !props.preview &&
                <BaseInputsGrid cols={2}>
                    {/* "Cancel" button */}
                    <BaseButton text={t("buttons.cancel")}
                                size="md"
                                buttonWidth={"100%"}
                                primaryOutlined
                                type={"button"}
                                onClick={props.onClose}
                    />

                    {/* "Attach" button */}
                    <BaseButton text={t("buttons.attach")}
                                size="md"
                                buttonWidth={"100%"}
                    />
                </BaseInputsGrid>
            }
        </form>
    </BaseModal>;
}