import React from "react";
import { LineSchema, PurchaseOrdersFormTyping } from "../../types";
import { useFormContext, useWatch } from "react-hook-form";
import { formatPriceWithCurrency } from "@helpers/utils";

export default function PriceCell({ unit_price }: { unit_price: LineSchema["unit_price"] }) {
    const { control } = useFormContext<PurchaseOrdersFormTyping>();

    const currency = useWatch({ control, name: "currency" });

    return <div>{formatPriceWithCurrency(unit_price?.converted, currency)}</div>;
}