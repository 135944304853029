import BaseImportModal from "@/components/reusables/BaseImportModal";
import React from "react";
import { useTranslation } from "react-i18next";
import { useStartImportMutation } from "@redux/features/importApi";
import { toastError } from "@helpers/utils";
import { useGetImportableColumnsQuery } from "@redux/features/dfs/dfsApi";

interface ProductsImportModalProperties {
    isOpen?: boolean;
    onClose?: () => void;
}

export default function ProductsImportModal(props: ProductsImportModalProperties) {
    const { t } = useTranslation("", { keyPrefix: "imports.modules.product.importModal" });

    const [importProducts, { isLoading: isImportLoading }] = useStartImportMutation();

    const { data: columns = [], isLoading: isColumnsLoading } = useGetImportableColumnsQuery({ module: "product" });

    const tips = [
        t("tips.location")
    ];

    return (
        <>
            <BaseImportModal
                optionsList={columns}
                tips={tips}
                isOpen={props.isOpen}
                onClose={props.onClose}
                isLoading={isImportLoading || isColumnsLoading}
                onImport={(formData) => {
                    importProducts({ module: "product", formData }).unwrap()
                        .then(() => {
                            props.onClose?.();
                        })
                        .catch(e => {
                            console.error(e);
                            toastError(e);
                        });
                }}
            />
        </>
    );
}