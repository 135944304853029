import { useAppDispatch } from "@redux/hooks";
import BaseExportModal from "@reusables/Modals/BaseExportModal";
import React from "react";
import { useExportStockCountsMutation } from "@redux/features/stockCount/stockCountApi";
import { adaptToExportRequest, BaseExportModalPropsProvider } from "@reusables/Modals/BaseExportModal/utils";
import { useGetExportableColumnsQuery } from "@redux/features/dfs/dfsApi";
import { performExport } from "@redux/features/dfs/thunks";

type StockCountExportModalProps = {
    records: number[];
} & BaseExportModalPropsProvider;

export default function StockCountExportExportModal(props: StockCountExportModalProps) {
    const dispatch = useAppDispatch();

    const [exportQuery] = useExportStockCountsMutation();

    const {
        data: columns = [],
        isLoading: isColumnsLoading
    } = useGetExportableColumnsQuery({ module: "stock_count" });

    return (
        <BaseExportModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            onSubmit={(data) => {
                dispatch(
                    performExport("stock_count", () => {
                        return exportQuery(adaptToExportRequest(props, data, {
                            filters: {
                                ...props.filters,
                                ids: props.records
                            }
                        })).unwrap();
                    })
                );
            }}
        >
            <BaseExportModal.Email />
            <BaseExportModal.AmountSelector records={{ selected: props.records.length }} />
            <BaseExportModal.ColumnsSelector columns={columns} isLoading={isColumnsLoading} />
            <BaseExportModal.ConcurrencyWarning />
            <BaseExportModal.Confirm />
        </BaseExportModal>
    );
}
