import React, { forwardRef } from "react";
import { DivBasedComponentProps } from "@reusables/BaseTableV2/types";
import { cn } from "@/helpers/utils";

const Row = forwardRef<HTMLDivElement, DivBasedComponentProps>(function Row({ children, ...props }, ref) {
    return (
        <div {...props} className={cn("flex w-full", props.className)} ref={ref}>
            {children}
        </div>
    );
});

export default Row;