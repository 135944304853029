import React from "react";
import { ReactComponent as IntegratedPuzzle } from "@assets/icons/ic_integrated.svg";
import BaseTooltip from "@reusables/BaseTooltip";
import { Integration } from "@/types/general";
import { useGetIntegrationsQuery } from "@redux/features/integrations/integrationsApi";
import _ from "lodash";

interface BaseSyncedFromSourcesBadgeProps {
    integrations: {
        slug: Integration.Components.Slug;
        integrated: () => boolean;
    }[];
    width?: number;
    height?: number;
}

export default function BaseSyncedFromSourcesBadge({ integrations, ...props }: BaseSyncedFromSourcesBadgeProps) {
    /**
     * Loading available integrations list
     */
    const { data: availableIntegrationsList } = useGetIntegrationsQuery();

    /**
     * Transforming integrations array into map with slug as key
     */
    const availableIntegrationsMap = _.keyBy(availableIntegrationsList?.integrations ?? [], "slug");

    /**
     *  To always show up-to-date integration names in the list,
     *  filtering out unintegrated systems and
     *  matching slugs with available integrations and getting their names, otherwise using slug as name
     */
    const integratedSystems = integrations
        .filter(integration => integration.integrated())
        .map(integration => {
            const foundBySlug = availableIntegrationsMap[integration.slug];
            const imageUrl = availableIntegrationsMap[integration.slug]?.imageUrl;
            return {
                name: foundBySlug?.name ?? integration.slug,
                icon: imageUrl ?? null
            }
        });

    if (!integratedSystems.length)
        return null;

    return <BaseTooltip
        title={
            <ul>
                {
                    integratedSystems.map((system, index) => <li key={index}
                                                                className={"flex flex-row items-center space-x-1"}>
                        {system.icon && 
                            <img 
                                src={system.icon} 
                                alt={system.name} 
                                className="w-4 h-4 p-[2px] bg-white rounded-sm object-contain"
                            />
                        }
                        <span>{system.name}</span>
                    </li>)
                }
            </ul>
        }
        placement={"right"}
    >
        <IntegratedPuzzle className={"text-[#FFC107]"} width={props.width ?? 18} height={props.height ?? 18} />
    </BaseTooltip>;
}