import React, { forwardRef } from "react";
import { DivBasedComponentProps } from "@reusables/BaseTableV2/types";
import { cn } from "@helpers/utils";

const Header = forwardRef<HTMLDivElement, DivBasedComponentProps>(function({ children, ...props }, ref) {
    return (
        <div
            {...props}
            className={cn(
                "flex bg-[#F9F9F9] text-[#686868] font-thin",
                props.className
            )}
            ref={ref}
        >
            {children}
        </div>
    );
});

export default Header;