import React, { useEffect, useState } from "react";
import BaseDropdown, { adaptInfiniteLoader } from "@reusables/dropdowns/BaseDropdown";
import BaseInput from "@reusables/BaseInput";
import BaseButton from "@reusables/BaseButton";
import BaseModal from "@reusables/Modals/BaseModal";
import { productsApi } from "@redux/features/products/productsApi";

import { ReactComponent as PercentageIcon } from "@assets/icons/ic_percentage.svg";
import { z } from "zod";
import { Controller, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import { cn, normalizePrice } from "@helpers/utils";
import { useTranslation } from "react-i18next";
import BaseSwitch from "@reusables/BaseSwitch";
import { Alert, Collapse, Fade, Stack } from "@mui/material";
import BOMSelector
    from "@components/Dashboard/pages/PurchasingPage/PurchasesPage/components/MutationLayout/parts/ProductSection/components/BOMSelector";
import { HelpCircle } from "lucide-react";
import BaseTooltip from "@reusables/BaseTooltip";
import { isErrorWithMessage } from "@redux/api/query";
import { toast } from "react-toastify";
import i18n from "i18next";
import BaseInfiniteLoader from "@reusables/dropdowns/BaseInfiniteLoader";
import { Product } from "@/types/general";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { LineSchema } from "../../../types";
import { useGetModuleTaxesQuery } from "@redux/features/taxes/taxesApi";
import { useGetSupplierQuery } from "@redux/features/suppliers/suppliersApi";
import { supplierSchema } from "../../../types";

const formScheme = z
    .object({
        id: z.number().optional(),
        add_more: z.boolean(),
        product: z.object({
            id: z.number(),
            name: z.string(),
            code: z.string(),
            prices: z.object({
                purchase_price: z.number()
            }).optional(),
            has_bom: z.boolean().optional(),
            supplier: supplierSchema.nullish()
        }),
        quantity: z.coerce.number().positive(),
        unit_price: z.object({
            converted: z.coerce.number().positive(),
            original: z.number()
        }),
        discount: z.coerce.number().min(0).max(100).optional(),
        should_use_bom: z.boolean(),
        tax: z.object({
            id: z.number(),
            name: z.string().nullish(),
            rate: z.number()
        }).nullish(),
        selected_bom: z.object({
            id: z.number(),
            name: z.string(),
            components: z.array(
                z.object({
                    product: z.object({
                        id: z.number(),
                        name: z.string(),
                        code: z.string(),
                        prices: z.object({
                            purchase_price: z.number()
                        }),
                        supplier: supplierSchema.nullish()
                    }),
                    quantity: z.coerce.number()
                })
            )
        })
            .optional()
    })
    .superRefine((scheme, ctx) => {
        if (scheme.should_use_bom && !scheme.selected_bom) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                params: {
                    i18n: "purchaseOrder.general.modals.addProduct.bom.validation.noBOMSelected"
                },
                path: ["selected_bom"]
            });
        }
        return scheme;
    });

export type AddProductFormSchemeTyping = z.infer<typeof formScheme>;

export interface AddProductModalProps {
    isOpen: boolean;
    onClose: () => void;

    onLineMutation: (newOrder: AddProductFormSchemeTyping) => void;
    selectedLine?: LineSchema;
    additionalData: { supplier_id: number, currency: string, exchange_rate: number };
}

export default function AddProductModal({
                                            isOpen,
                                            onClose,
                                            onLineMutation,
                                            selectedLine,
                                            additionalData
                                        }: AddProductModalProps): JSX.Element {
    const { t } = useTranslation("", { keyPrefix: "purchaseOrder.general.modals.addProduct" });

    const {
        data: supplier,
        isLoading: isSupplierLoading
    } = useGetSupplierQuery(additionalData?.supplier_id, { skip: !additionalData?.supplier_id });

    const dispatch = useAppDispatch();
    const baseCurrency = useAppSelector(state => state.auth.user?.company.currency.symbol);

    const { data: taxesOptions, isLoading: isTaxesOptionsLoading } =
        useGetModuleTaxesQuery("purchase-order");

    const [productsOptions, setProductsOptions] = useState<Product.Slim[]>([]);

    const { control, reset, ...form } = useForm<AddProductFormSchemeTyping>({
        resolver: zodResolver(formScheme),
        defaultValues: {
            should_use_bom: false,
            selected_bom: undefined,
            add_more: false
        }
    });

    // This state is used to store the precise converted value of the product
    const [preciseConverted, setPreciseConverted] = useState<number | null>(null);

    const selectedProduct = useWatch({
        control,
        name: "product"
    });

    const hasBOM = selectedProduct?.has_bom ?? false;

    const shouldUseBOM = useWatch({
        control,
        name: "should_use_bom"
    });

    useEffect(() => {
        if (isOpen && supplier && !isSupplierLoading) {
            form.setValue("tax", supplier.tax_rate);
        }
    }, [supplier, isOpen]);

    useEffect(() => {
        reset();
        if (selectedLine) {
            form.setValue("id", selectedLine.id);
            form.setValue("product", selectedLine.product);
            form.setValue("quantity", selectedLine.quantity);
            form.setValue("unit_price.original", selectedLine.unit_price.original);
            form.setValue("unit_price.converted", normalizePrice(selectedLine.unit_price.converted));
            form.setValue("discount", selectedLine.discount);
            form.setValue("tax", selectedLine.tax);
        }
    }, [selectedLine]);

    useEffect(() => {
        if (selectedProduct && !selectedLine && selectedProduct.prices?.purchase_price) {
            const rate = additionalData?.exchange_rate ?? 1;
            // When a product is selected, calculate the precise converted price and store it separately.
            const preciseValue = selectedProduct.prices.purchase_price * rate;
            setPreciseConverted(preciseValue);

            form.setValue("unit_price.original", selectedProduct.prices.purchase_price);
            form.setValue("unit_price.converted", parseFloat(preciseValue.toFixed(2)));
        }
        form.resetField("selected_bom");
        form.resetField("should_use_bom");
    }, [selectedProduct]);

    const onSubmit = form.handleSubmit((data) => {
        const userEnteredConverted = parseFloat(data.unit_price.converted.toString());
        const rate = additionalData?.exchange_rate ?? 1;

        // If the user did not modify the value (i.e. it matches the formatted precise value),
        // then use the stored precise value for accurate reverse calculation.
        const finalConverted =
            preciseConverted && (userEnteredConverted === parseFloat(preciseConverted.toFixed(2)))
                ? preciseConverted
                : userEnteredConverted;

        onLineMutation({
            ...data,
            unit_price: {
                converted: finalConverted,
                original: normalizePrice(finalConverted / rate)
            }
        });

        if (!data.add_more) {
            onClose();
        }
    }, console.error);

    useEffect(() => {
        if (form.formState.isValid && !form.formState.isValidating) {
            reset({
                add_more: form.getValues("add_more"),
                should_use_bom: false
            });
        }
    }, [form.formState.submitCount, reset]);

    function handleClose() {
        onClose();
    }

    const [isProductLazyLoadingFetching, setIsProductLazyLoadingFetching] = useState(false);
    return (
        <>
            <BaseModal isOpen={isOpen} onClose={handleClose} width={900} padding="56px" useCloseIcon>
                <form className="space-y-[32px]" onSubmit={onSubmit}>
                    <div className="modal-title">{selectedLine ? selectedLine?.product.name : t("heading")}</div>
                    <BaseInputsGrid cols={2}>
                        <BaseInfiniteLoader
                            fetch={(search, page, limit) => {
                                setIsProductLazyLoadingFetching(true);
                                dispatch(productsApi.endpoints.getModuleProductsFull.initiate(
                                        {
                                            filters: {
                                                search
                                            },
                                            pagination: {
                                                page,
                                                limit
                                            },
                                            module: "purchase-order"
                                        },
                                        {
                                            subscribe: false
                                        }
                                    )
                                ).unwrap()
                                    .then(res => {
                                        console.log("RTKQ Result", search, res);
                                        setProductsOptions(res?.payload ?? []);
                                    })
                                    .catch(e => {
                                        if (isErrorWithMessage(e)) {
                                            toast.error(e.message);
                                        } else {
                                            toast.error(i18n.t("general.responses.somethingWentWrong"));
                                        }

                                        console.error("An error occurred in the products lazy loader", e);
                                    })
                                    .finally(() => {
                                        setIsProductLazyLoadingFetching(false);
                                    });
                            }}
                            limit={100}
                            result={productsOptions}
                            isLoading={isProductLazyLoadingFetching}
                            skip={!!selectedLine}
                        >
                            {
                                (infinity) => (
                                    <Controller
                                        name="product"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <BaseDropdown
                                                    {...field}
                                                    {...fieldState}

                                                    onChange={(v) => {
                                                        console.log("onChange", v);
                                                        field.onChange(v);
                                                    }}

                                                    {...adaptInfiniteLoader(infinity)}

                                                    label={t(`fields.product.label`)}
                                                    placeholder={t(`fields.product.placeholder`)}
                                                    getter={{
                                                        label: (opt) => opt.name,
                                                        key: (opt) => opt.id,
                                                        caption: (opt) => opt.code
                                                    }}

                                                    disabled={!!selectedLine}

                                                    virtualize
                                                    autocomplete
                                                />
                                            </>
                                        )
                                        }
                                    />
                                )}
                        </BaseInfiniteLoader>

                        <Controller
                            name="quantity"
                            control={control}
                            render={({ field, fieldState }) => (
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    label={t(`fields.quantity.label`)}
                                    placeholder={t(`fields.quantity.placeholder`)}
                                    type={"number"}
                                />
                            )}
                        />

                        <Controller
                            name="discount"
                            control={control}
                            render={({ field, fieldState }) => (
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    label={t(`fields.discount.label`)}
                                    placeholder={t(`fields.discount.placeholder`)}
                                    type="number"
                                    icon={{ right: { el: <PercentageIcon />, offset: "38px" } }}
                                />
                            )}
                        />

                        <Controller
                            name="tax"
                            control={control}
                            render={({ field, fieldState }) => (
                                <BaseDropdown
                                    {...field}
                                    error={fieldState.error}

                                    label={t(`fields.tax.label`)}
                                    placeholder={t(`fields.tax.placeholder`)}
                                    options={taxesOptions}
                                    getter={{
                                        key: (opt) => opt.id,
                                        label: (opt) => `${opt.rate}%`,
                                        caption: (opt) => opt.name ?? ""
                                    }}
                                    isLoading={isTaxesOptionsLoading}
                                />

                            )}
                        />

                        <Fade in={!shouldUseBOM}>
                            <div>
                                <Controller
                                    name="unit_price.converted"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <BaseInput
                                            {...field}
                                            error={fieldState.error}
                                            label={t(`fields.unitPrice.label`)}
                                            placeholder={t(`fields.unitPrice.placeholder`)}
                                            type="number"
                                            step="any"
                                            icon={{
                                                right:
                                                    {
                                                        el:
                                                            <span
                                                                className="w-max h-max text-purple-400 font-thin">{additionalData?.currency ?? baseCurrency}</span>,
                                                        offset: "38px"
                                                    }
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </Fade>
                    </BaseInputsGrid>

                    {!selectedLine && (
                        <>
                            <Collapse in={!!form.formState.errors.selected_bom}>
                                <Alert severity={"error"}>{t("bom.validation.noBOMSelected")}</Alert>
                            </Collapse>

                            <Stack direction={"row"}>
                                <div
                                    className={cn(
                                        `grow border-[1px] border-solid rounded-lg bg-white transition-all overflow-hidden`,
                                        form.formState.errors.selected_bom ? `border-inputs-border-error` : `border-inputs-border-default`,
                                        hasBOM ? "hover:border-inputs-border-focused" : "pointer-events-none opacity-30"
                                    )}
                                >
                                    <Controller
                                        control={control}
                                        name="should_use_bom"
                                        render={({ field }) => (
                                            <div
                                                className={cn(`flex flex-row items-center justify-between p-3`, hasBOM && "cursor-pointer transition-all hover:bg-purple-100 text-accent")}
                                                onClick={() => {
                                                    if (hasBOM) field.onChange(!field.value);
                                                }}
                                            >
                                                <Stack direction={"row"} alignItems={"center"} spacing={1}
                                                       className={"pointer-events-auto"}>
                                                    <span>{t("bom.label")}</span>
                                                    <BaseTooltip title={t("bom.whatIsBOM") + ""} placement={"top"}>
                                                        <HelpCircle size={16} color={"#686868"} />
                                                    </BaseTooltip>
                                                </Stack>
                                                <BaseSwitch
                                                    checked={field.value}
                                                    className={cn(
                                                        "focus:outline-offset-1 focus:color-primary-500 focus:shadow-outline-primary-500 focus:border-primary-500 focus:ring-primary-500 focus:ring-offset-primary-500"
                                                    )}
                                                />
                                            </div>
                                        )}
                                    />
                                    {!!selectedProduct && (
                                        <Collapse in={shouldUseBOM}>
                                            <BOMSelector active={shouldUseBOM} product_id={selectedProduct.id}
                                                         control={control} />
                                        </Collapse>
                                    )}
                                </div>
                            </Stack>
                        </>
                    )}
                    <div className="flex flex-column items-end">
                        <Controller
                            name={"add_more"}
                            control={control}
                            render={({ field }) => (
                                <div
                                    className="inline-flex items-center space-x-2 mb-2 user-select-none text-accent"
                                    onClick={() => field.onChange(!field.value)}
                                >
                                    <BaseSwitch checked={field.value ?? false} />
                                    <span>{t("addMore")}</span>
                                </div>
                            )}
                        />
                        <BaseButton
                            text={selectedLine ? t(`buttons.save`) : t(`buttons.add`)}
                            size="md"
                            className="w-full"
                        />
                    </div>
                </form>
            </BaseModal>
        </>
    );
}
