import { z } from "zod";
import dayjs, { Dayjs } from "dayjs";
import { refinements } from "@helpers/refinements";
import i18n from "@/i18n";
import { OrderStatus, Picking, SaleOrder } from "@/types/general";

const availableServiceScheme = z.object({
    id: z.number(),
    name: z.string(),
    code: z.string()
});

const packageCustom = z.object({
    id: z.string(),
    width: z.number(),
    length: z.number(),
    height: z.number()
});

const packageReference = z.object({
    id: z.number(),
    name: z.string(),
    code: z.string(),
    width: z.number(),
    length: z.number(),
    height: z.number()
});

export type PackageCustomTyping = z.infer<typeof packageCustom>;
export type PackageReferenceTyping = z.infer<typeof packageReference>;

export const packageConfig = z.union([
    packageCustom,
    packageReference
]);

export type PackageConfig = z.infer<typeof packageConfig>;

const formScheme = z.object({
    extractedFromTransit: z.boolean(),

    customer: z.object({
        id: z.number(),
        code: z.string().nullish(),
        name: z.string()
    }),

    sale_order: z.object({
        id: z.number(),
        code: z.string().nullish(),
        status: z.object({
            name: z.nativeEnum(OrderStatus.Base),
            meta: z.object({
                cancel_reason: z.string().nullish()
            }).nullish()
        })
    }).readonly(),

    order_destination_country: z.object({
        id: z.number(),
        name: z.string(),
        code: z.string()
    }).nullish(),

    picking_date: z.object({
        date: z.instanceof(dayjs as unknown as typeof Dayjs).refine(refinements.validDate.refine, refinements.validDate.message)
    }),

    lines: z
        .array(
            z
                .object({
                    type: z.string(),
                    module: z.custom<SaleOrder.Components.Group["data"]["module"]>(),
                    name: z.string().nullish(),
                    data: z.object({
                        id: z.number().nullish(),
                        comment: z.string().nullish(),
                        product: z.custom <SaleOrder.Components.LineData["product"]>(),
                        already_picked_quantity: z.coerce.number(),
                        picked_quantity: z.coerce.number().min(0).optional(), // used for services, since they don't require locations
                        quantity: z.coerce.number(),
                        picks: z.array(
                            z
                                .object({
                                    outgoing_location: z
                                        .object({
                                            location_id: z.number(),
                                            sub_location_id: z.number().nullable().optional(),
                                            location_name: z.string(),
                                            section_name: z.string().nullable().optional(),
                                            in_stock: z.number()
                                        })
                                        .optional(),
                                    picked_quantity: z.coerce.number().positive().optional()
                                })
                                .refine(
                                    (scheme) => {
                                        return (scheme.picked_quantity ?? 0) <= (scheme.outgoing_location?.in_stock ?? 0);
                                    },
                                    {
                                        message: "Picked quantity cannot be greater than the in-stock limit",
                                        path: ["picked_quantity"]
                                    }
                                )
                                .superRefine((scheme, ctx) => {
                                    if (scheme.outgoing_location && !scheme.picked_quantity) {
                                        ctx.addIssue({
                                            code: z.ZodIssueCode.custom,
                                            message: "Picked quantity is required when a location is selected",
                                            path: ["picked_quantity"]
                                        });
                                    } else if (scheme.picked_quantity && !scheme.outgoing_location) {
                                        ctx.addIssue({
                                            code: z.ZodIssueCode.custom,
                                            message: "Outgoing location is required when a quantity is picked",
                                            path: ["outgoing_location"]
                                        });
                                    }
                                })
                        ),
                        serial_numbers: z.array(
                            z.object({
                                serial_number: z.string()
                            })
                        ),
                        batch_numbers: z.array(
                            z.object({
                                batch_number: z.string(),
                                expiration_date: z
                                    .instanceof(dayjs as unknown as typeof Dayjs)
                                    .refine((value) => value.isValid(), {
                                        message: "Invalid date"
                                    })
                                    .optional()
                            })
                        )
                    }).nullish()
                })
                .superRefine((scheme, ctx) => {
                    if (scheme.data?.product.is_service && (scheme.data?.picked_quantity ?? 0) > scheme.data.quantity) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: "Picked quantity cannot be greater than the product's quantity",
                            path: ["picked_quantity"]
                        });
                    }
                })
        )
        .refine(
            (scheme) => {
                return scheme.some((line) => {
                    return (
                        line.data?.picked_quantity ||
                        line.data?.picks.some((pick) => pick.picked_quantity && pick.outgoing_location)
                    );
                });
            },
            {
                message: "You should pick at least one product"
            }
        )
        .superRefine((lines, ctx) => {
            lines.forEach((line, index) => {
                if (!line.data) return;

                const totalPicked = line.data?.picks.reduce((total, pick) => total + (pick.picked_quantity || 0), 0);
                if (totalPicked > line.data?.quantity - line.data?.already_picked_quantity) {
                    line.data?.picks.forEach((pick, pickIndex) => {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: "Total picked quantity cannot exceed the product's remaining quantity",
                            path: [index, "picks", pickIndex, "picked_quantity"]
                        });
                    });
                }
            });
        })
        .superRefine((scheme, ctx) => {
            // Filter lines that either have serial numbers or batch numbers
            const filteredLines = scheme.filter(({ data }) => {
                if (!data) return false;

                const { is_serial_numbers, is_batch_numbers } = data?.product ?? {};
                return (
                    (is_serial_numbers || is_batch_numbers) &&
                    data?.picks.some(({ outgoing_location, picked_quantity }) => outgoing_location && picked_quantity)
                );
            });

            // Check if there are any lines with serial number errors
            const hasSerialError = filteredLines.some(({ data }) => {
                if (!data?.product.is_serial_numbers) return false;

                const totalPickedQuantity = data.picks.reduce((acc, { picked_quantity }) => acc + (picked_quantity ?? 0), 0);
                return data.serial_numbers.length !== Math.ceil(totalPickedQuantity);
            });

            // Check if there are any lines with batch number errors
            const hasBatchError = filteredLines.some(({ data }) => {
                if (!data?.product.is_batch_numbers) return false;

                const totalPickedQuantity = data.picks.reduce((acc, { picked_quantity }) => acc + (picked_quantity ?? 0), 0);
                return data.batch_numbers.length === 0 || data.batch_numbers.length > Math.ceil(totalPickedQuantity);
            });

            // Add an issue if serial number errors are found
            if (hasSerialError) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "Serial numbers are filled incorrectly!",
                    path: ["serial_numbers"]
                });
            }

            // Add an issue if batch number errors are found
            if (hasBatchError) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "Batch numbers are filled incorrectly!",
                    path: ["batch_numbers"]
                });
            }
        }),

    packages: z.array(
        z.object({
            package: packageConfig,
            weight: z.coerce.number().nullish()
        })
    ).optional(),

    shipmondo: z.object({
        enabled: z.boolean(),
        country: z.object({
            id: z.number(),
            name: z.string(),
            code: z.string()
        }).optional(),
        carrier: z.object({
            id: z.number(),
            name: z.string(),
            code: z.string()
        }).optional(),
        product: z.object({
            id: z.number(),
            name: z.string(),
            code: z.string(),
            available_services: z.array(availableServiceScheme),
            required_services: z.array(availableServiceScheme)
        }).optional(),
        services: z.array(availableServiceScheme).nullish()
    }).superRefine((scheme, ctx) => {
        if (scheme.enabled && !scheme.product) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: i18n.t("general.validation.global.required"),
                path: ["product"]
            });
        }
        if (scheme.enabled && scheme.product?.required_services?.length && (!scheme.services || scheme.services.length === 0)) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: i18n.t("general.validation.global.required"),
                path: ["service"]
            });
        }
    })
}).superRefine((scheme, ctx) => {
    if (scheme.shipmondo?.enabled && (!scheme.packages || scheme.packages.length === 0)) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18n.t("sales.picking.general.fields.packaging.validation.shipmondoRequirement"),
            path: ["packages"]
        });
    }

    if (scheme.shipmondo?.enabled) {
        if (!scheme.order_destination_country) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: i18n.t("general.validation.global.required"),
                path: ["order_destination_country"]
            });
        }

        const indexesOfPackagesWithoutWeight = scheme.packages?.reduce(
            (acc, curr, index) => {
                if (!curr.weight) {
                    acc.push(index);
                }

                return acc;
            },
            [] as number[]
        );

        if (indexesOfPackagesWithoutWeight?.length) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: i18n.t("sales.picking.general.fields.packaging.noWeightWarning"),
                path: ["packages"]
            });
        }
    }
});

export const isPackageTemporary = (packageObj: z.infer<typeof packageConfig>): packageObj is PackageCustomTyping => {
    return !("name" in packageObj) &&
        ("width" in packageObj && "length" in packageObj && "height" in packageObj);
};

export const getPackageName = (packageObj: z.infer<typeof packageConfig>): string => {
    if (isPackageTemporary(packageObj)) {
        return packageObj.width + "x" + packageObj.height + "x" + packageObj.length;
    } else {
        return packageObj.name;
    }
};

export { formScheme };
export type PickingMutationFormTyping = z.infer<typeof formScheme>;
