import { z } from "zod";

export const quickPurchaseOrderScheme = z.object({
    selected_bom: z.object({
        id: z.number(),
        name: z.string(),
        quantity: z.coerce.number(),
        components: z.array(
            z.object({
                product: z.object({
                    id: z.number(),
                    name: z.string(),
                    code: z.string(),
                    unit_price: z.number(),
                    in_stock: z.number()
                }),
                quantity: z.coerce.number(),
                selected: z.boolean()
            })
        )
    }),
});

export type QuickPurchaseOrderSchemeTyping = z.infer<typeof quickPurchaseOrderScheme>;